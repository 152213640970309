import * as CONST from '~/store/session/constants';

import initialState from './sessionInitialState';

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SESSION_KEEP_ALIVE:
      return Object.assign({}, state, {
        lastRequestDate: new Date(),
        status: null,
      });
    case CONST.SESSION_SET_STATUS:
      return Object.assign({}, state, { status: action.payload });
    case CONST.SESSION_START:
      return Object.assign({}, state, {
        lastRequestDate: new Date(),
        status: null,
      });
    case CONST.SESSION_STOP:
      return Object.assign({}, state, {
        lastRequestDate: null,
        status: null,
      });
    default:
      return state;
  }
};

export default sessionReducer;
