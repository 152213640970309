import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';
import { getIn } from 'formik';

import { Help } from '~/common/components/Help';

const Div = ({ children }) => <div>{children}</div>;
Div.propTypes = { children: PropTypes.any.isRequired };

const Span = ({ children }) => <span>{children}</span>;
Span.propTypes = { children: PropTypes.any.isRequired };

class FormGroup extends Component {
  static getDerivedStateFromProps(props, state) {
    const id = props.id || state.id || shortid.generate();
    return state.id === id ? null : { id };
  }

  static meta = ({ touched, error, form, field }) => (form && field
    ? {
      touched: getIn(form.touched, field.name),
      error: getIn(form.errors, field.name),
    }
    : { touched, error });

  state = { id: undefined };

  render() {
    const {
      inline,
      label,
      help,
      note,
      touched,
      error,
      suppressError,
      suppressLabel,
      component: Field,
      ...other
    } = this.props;
    const meta = FormGroup.meta(this.props);
    const { id } = this.state;
    const ariaID = `${id}-alert`;
    const hasError = meta.touched && meta.error;
    const classes = classNames('form-group', { 'has-error': hasError });
    const Wrap = inline ? Span : Div;
    // NOTE: help can be a node or a component that will be created with no properties.
    const HelpComponent = help instanceof Function && help;

    return (
      <div className={classes}>
        {!suppressLabel && (
          <Wrap>
            <label htmlFor={id} className="control-label">
              {label}
            </label>
            {HelpComponent && <HelpComponent />}
            {!HelpComponent && help && <Help>{help}</Help>}
            {hasError
              && !suppressError && (
                <span
                  id={ariaID}
                  className="help-block pull-right"
                  role="alert"
                  aria-live="polite"
                  aria-label="error"
                  aria-describedby={id}>
                  {meta.error}
                  <i className="fa fa-fw fa-exclamation-circle" aria-hidden="true" />
                </span>
            )}
          </Wrap>
        )}
        {Field && <Field {...other} id={id} aria-controls={hasError && !suppressError && ariaID} />}
        {!Field && other.children}
        {note && <div className="help-block text-muted small">{note}</div>}
      </div>
    );
  }
}

FormGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  help: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  note: PropTypes.node,
  touched: PropTypes.bool,
  error: PropTypes.node,
  component: PropTypes.func,
  children: PropTypes.any,
  suppressError: PropTypes.bool,
  suppressLabel: PropTypes.bool,
  inline: PropTypes.bool,
};

FormGroup.defaultProps = {
  id: undefined,
  label: '',
  help: '',
  note: '',
  touched: false,
  error: '',
  suppressError: false,
  suppressLabel: false,
  component: undefined,
  children: undefined,
  inline: false,
};

export default FormGroup;
