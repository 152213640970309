import moment from 'moment';
import qs from 'querystring';
import { apiProvider } from '@ent/browser';

// prettier-ignore
import {
  ACCOUNT_HISTORY_SET,
  ACCOUNT_HISTORY_LOADING_STATE,
  DATE_FORMAT,
  TIMEFRAME,
  TRANSACTION_TYPE,
} from '../constants';
import { selectAccountHistoryForSuffixId } from '../selectors';

const CACHE_TIMEOUT = 2 * 60 * 1000;

// prettier-ignore
const isEmpty = v => v !== undefined && v !== null
  && (typeof v !== 'string' || (typeof v === 'string' && v.length > 0));

// prettier-ignore
const mapToObject = map => map.reduce(
  (acc, [k, v]) => ({ ...acc, [k]: v }),
  {}
);

// prettier-ignore
const removeEmptyParameters = obj => mapToObject(Object.entries(obj)
  .filter(([, v]) => isEmpty(v)));

const parseSearchParams = searchParams => {
  if (!searchParams) return {};
  const {
    timeframe,
    startDate,
    endDate,
    transactionType,
    searchTerm,
    minAmount,
    maxAmount,
    startCheck,
    endCheck,
  } = searchParams;
  return removeEmptyParameters({
    startDate: startDate ? moment(startDate).format(DATE_FORMAT) : (TIMEFRAME[timeframe] || [])[0],
    endDate: endDate ? moment(endDate).format(DATE_FORMAT) : (TIMEFRAME[timeframe] || [])[1],
    tranType: TRANSACTION_TYPE[transactionType],
    startCheck,
    endCheck,
    description: searchTerm,
    minAmount: minAmount && parseInt(minAmount * 100, 10),
    maxAmount: maxAmount && parseInt(maxAmount * 100, 10),
  });
};

const setTransactions = (dispatch, transactions, secureSuffixId, key, timestamp, status) => {
  dispatch({
    type: ACCOUNT_HISTORY_SET,
    payload: { transactions, secureSuffixId, key, timestamp, status },
  });
};

const postTransactions = async (dispatch, secureSuffixId, queryParams, key, timestamp, status) => {
  try {
    const { transactions } = await apiProvider({
      url: `/Banking/api/history/${secureSuffixId}?${qs.encode(queryParams)}`,
      method: 'GET',
    });
    setTransactions(dispatch, transactions, secureSuffixId, key, timestamp, status);
  } catch (e) {
    setTransactions(dispatch, [], secureSuffixId, key, timestamp, ACCOUNT_HISTORY_LOADING_STATE.ERROR);
  }
};

export default (secureSuffixId, searchParams) => async (dispatch, getState) => {
  try {
    const current = selectAccountHistoryForSuffixId(secureSuffixId)(getState());
    const { key: cacheKey, timestamp: cacheTimestamp = 0 } = current || {};
    const timestamp = new Date().valueOf();
    const sanitizedParams = parseSearchParams(searchParams);
    const key = JSON.stringify(sanitizedParams);

    if (key === cacheKey && timestamp - cacheTimestamp < CACHE_TIMEOUT) {
      return;
    }

    setTransactions(dispatch, [], secureSuffixId, key, timestamp, ACCOUNT_HISTORY_LOADING_STATE.PENDING);

    await postTransactions(
      dispatch,
      secureSuffixId,
      sanitizedParams,
      key,
      timestamp,
      ACCOUNT_HISTORY_LOADING_STATE.COMPLETE
    );
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
  }
};
