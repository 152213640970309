import React from 'react';
import PropTypes from 'prop-types';

import { FieldFormGroup } from '../FieldFormGroup';
import RoutingNumber from './RoutingNumber';

const normalizeDigits = value => (value || '').replace(/\D/g, '');

/* NOTE: The RoutingNumberField will look up the routing number and display the institution name in a note under The
input. In addition to the `{field.name}` formik value it will also place `{field.name}:name` in the formik.  This can be
used to validate the routing number using Yup.

<RoutingNumberField name="routingNumber" />

const validationSchema = Yup.string({
  routingNumber: Yup.string()
    .required('Required')
    .matches(/^\d{9}$/, 'Invalid')
    .test('invalid', 'Invalid', function invalid() {
      return !!this.parent['routingNumber:name'];
    }),
})
*/

const RoutingNumberField = ({ name, ...other }) => <FieldFormGroup name={name} component={RoutingNumber} {...other} />;

RoutingNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
};

RoutingNumberField.defaultProps = { normalize: normalizeDigits };

export default RoutingNumberField;
