import React, { Component } from 'react';
import PropTypes from 'prop-types';

import decorator from './decorators';

export default () => BaseComponent => {
  class WithWorkflow extends Component {
    complete = workflow => {
      const { current, dequeue, nextUrl } = this.props;

      if (current && current.id === workflow) {
        dequeue();
        return nextUrl;
      }

      return undefined;
    };

    render() {
      const { current, dequeue, nextUrl, ...other } = this.props; // eslint-disable-line no-unused-vars

      return <BaseComponent currentWorkflow={current} completeWorkflow={this.complete} {...other} />;
    }
  }

  WithWorkflow.propTypes = {
    // react-redux
    current: PropTypes.object,
    dequeue: PropTypes.func.isRequired,
    nextUrl: PropTypes.string,
  };

  WithWorkflow.defaultProps = {
    current: undefined,
    nextUrl: undefined,
  };

  WithWorkflow.displayName = `WithWorkflow(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return decorator(WithWorkflow);
};
