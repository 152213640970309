const transferInitialState = {
  config: undefined,
  selected: undefined,
  partialTransfer: undefined,
  retailTransfers: [],
  processedTransfers: [],
  hostTransfers: [],
  internalAccounts: [],
  externalAccounts: [],
};

export default transferInitialState;
