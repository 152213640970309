import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const NoteLink = ({ note, onClick, ...props }) => (
  <div className="clearfix">
    <button type="button" className="NoteLink btn btn-link pull-right" onClick={onClick} {...props}>
      {note}
    </button>
  </div>
);

NoteLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  note: PropTypes.string.isRequired,
};

NoteLink.defaultProps = {};

export default NoteLink;
