import { createSelector } from 'reselect';

import selectBootstrapSize from './selectBootstrapSize';

const selectIsBootstrapSize = (...sizes) => createSelector(
  selectBootstrapSize,
  state => sizes, // eslint-disable-line no-unused-vars
  (current, targetSizes) => targetSizes.some(s => s === current)
);

export default selectIsBootstrapSize;
