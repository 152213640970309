import { createSelector } from 'reselect';

// https://smartystreets.com/docs/cloud/us-street-api#footnotes
const FOOTNOTE = {
  'A#': 'Corrected Zip', // Corrected ZIP Code
  'B#': 'Corrected City/State', // Fixed city/state spelling
  'C#': 'Corrected City/State/Zip', // Invalid city/state/ZIP
  'H#': 'Missing Apt/Ste', // Missing secondary number
  'J#': 'Corrected Street', // Dual address
  'K#': 'Corrected Street', // Cardinal rule match
  'L#': 'Corrected Street', // Changed address component
  'M#': 'Corrected Street', // Fixed street spelling
  'N#': 'Corrected Street', // Fixed abbreviations
  'P#': 'Corrected Street', // Better address exists
  'U#': 'Corrected City', // Unofficial post office name
  'V#': 'Corrected City/State', // Unverifiable city/state
};

const SORT = {
  'Corrected Street': 0,
  'Missing Apt/Ste': 1,
  'Corrected City': 2,
  'Corrected City/State': 3,
  'Corrected City/State/Zip': 4,
  'Corrected Zip': 5,
};

const dedupe = (a, o) => (a.indexOf(o) < 0 ? [...a, o] : a);

const simplify = arr => arr
  .filter(o => !!o)
  .reduce(dedupe, [])
  .sort((a, b) => SORT[a] - SORT[b]);

const selectAddressIsInvalid = result => (result ? '' : 'Invalid address');

const selectResult = result => result || {};

const selectAnalysis = createSelector(
  selectResult,
  result => result.analysis || {}
);

const selectFootnotes = createSelector(
  selectAnalysis,
  analysis => analysis.footnotes || '',
);

const selectFootnoteIssues = createSelector(
  selectFootnotes,
  footnotes => Object.keys(FOOTNOTE).map(k => (footnotes.indexOf(k) < 0 ? '' : FOOTNOTE[k]))
);

const selectComponents = createSelector(
  selectResult,
  result => result.components || {},
);

const selectCityName = createSelector(
  selectComponents,
  components => components.city_name || ''
);

const selectDefaultCityName = createSelector(
  selectComponents,
  selectCityName,
  (components, cityName) => components.default_city_name || cityName || ''
);

const selectCityNameIssue = createSelector(
  selectCityName,
  selectDefaultCityName,
  (city, defaultCity) => (city === defaultCity ? '' : 'Corrected City')
);

const selectAddressIssues = createSelector(
  selectAddressIsInvalid,
  selectFootnoteIssues,
  selectCityNameIssue,
  (address, footnotes, city) => simplify([address, ...footnotes, city])
);

export default selectAddressIssues;
