import React from 'react';
import PropTypes from 'prop-types';

import Transaction from './Transaction';

const Transactions = ({ transactions }) => {
  if (transactions) {
    let message;

    if (transactions instanceof Array) {
      if (transactions.length > 0) {
        return <div>{transactions.map(Transaction)}</div>;
      }

      message = 'No transactions to display';
    }

    return (
      <div className="Transaction col-xs-12 small text-muted">
        <i>{message || transactions}</i>
      </div>
    );
  }

  return null;
};

Transactions.propTypes = { transactions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]) };

Transactions.defaultProps = { transactions: undefined };

export default Transactions;
