export default {
  word: /[a-z]([^\s-&]*)/gi,
  capitalizeFirstWord: true,
  capitalizeLastWord: true,
  shortWords: /^(a|an|and|at|but|by|for|in|nor|of|on|or|so|the|to|up|yet)$/,
  replacements: {
    ii: 'II',
    iii: 'III',
    iv: 'IV',
    llc: 'LLC',
    usa: 'USA',
    us: 'US',
    ytd: 'YTD',
    itunes: 'iTunes',
    iphone: 'iPhone',

    // states
    al: 'AL',
    ak: 'AK',
    az: 'AZ',
    ar: 'AR',
    ca: 'CA',
    co: 'CO',
    ct: 'CT',
    de: 'DE',
    fl: 'FL',
    ga: 'GA',
    hi: 'HI',
    id: 'ID',
    il: 'IL',
    in: 'IN',
    ia: 'IA',
    ks: 'KS',
    ky: 'KY',
    la: 'LA',
    me: 'ME',
    md: 'MD',
    ma: 'MA',
    mi: 'MI',
    mn: 'MN',
    ms: 'MS',
    mo: 'MO',
    mt: 'MT',
    ne: 'NE',
    nv: 'NV',
    nh: 'NH',
    nj: 'NJ',
    nm: 'NM',
    ny: 'NY',
    nc: 'NC',
    nd: 'ND',
    oh: 'OH',
    ok: 'OK',
    or: 'OR',
    pa: 'PA',
    ri: 'RI',
    sc: 'SC',
    sd: 'SD',
    tn: 'TN',
    tx: 'TX',
    ut: 'UT',
    vt: 'VT',
    va: 'VA',
    wa: 'WA',
    wv: 'WV',
    wi: 'WI',
    wy: 'WY',

    // Ent-specific
    cu: 'CU',
    esa: 'ESA',
    fcu: 'FCU',
    heloc: 'HELOC',
    hsa: 'HSA',
    loc: 'LOC',
    rloc: 'RLOC',
    valueplus: 'ValuePLUS',
  },
  particles: {
    test: /^(mc|o')/,
    replacements: {
      mc: 'Mc',
      "o'": "O'",
    },
  },
};
