import { log } from '~/common/log';
import { selectUser } from '~/store/user';

import { selectTestVariant } from '../selectors';
import { ACTIONS } from '../constants';

const setSubVariant = (name, subVariant) => (dispatch, getState) => {
  const state = getState();
  const user = selectUser(state);
  const { accountNumber, userId } = user || {};
  const variant = selectTestVariant(state);

  log({
    category: 7, // audit
    message: 'ABTEST START',
    details: JSON.stringify({
      name,
      variant,
      subVariant,
      account: accountNumber,
      userId,
    }),
  });

  dispatch({
    type: ACTIONS.SET_VARIANT,
    payload: {
      name,
      variant,
    },
  });
};

export default setSubVariant;
