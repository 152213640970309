import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ent/fields';

import { addressShape } from '~/common';

import Address from './Address';

const AddressPanel = ({
  type,
  title,
  address,
  body,
  buttonText,
  buttonAutoId,
  buttonType,
  buttonKind,
  clickHandler,
}) => (
  <div className="col-xs-12 col-lg-6">
    <div className={`panel panel-${type}`}>
      <div className="panel-heading">{title}</div>
      <div className="panel-body">{address ? <Address {...address} /> : body}</div>
      <div className="panel-footer">
        <Button
          type={buttonType || 'button'}
          kind={buttonKind || 'primary'}
          onClick={clickHandler}
          data-autoid={buttonAutoId}>
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
);

AddressPanel.propTypes = {
  type: PropTypes.oneOf(['default', 'primary', 'success', 'info', 'warning', 'danger']),
  title: PropTypes.node.isRequired,
  address: PropTypes.shape(addressShape),
  body: PropTypes.node,
  buttonText: PropTypes.node.isRequired,
  buttonType: PropTypes.string,
  buttonKind: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
};

AddressPanel.defaultProps = {
  type: 'default',
  address: null,
  body: null,
  buttonType: null,
  buttonKind: null,
};

export default AddressPanel;
