import shortid from 'shortid';

import { Format, Parse } from '~/common/data-tada';

import { COLUMN_TYPE } from './constants';

const lower = s => (s === null || s === undefined ? '' : `${s}`.toLowerCase());
const contains = (a, b) => lower(a).indexOf(lower(b)) >= 0;

const TextColumn = ({
  id = shortid.generate(),
  type = COLUMN_TYPE.TEXT,
  title = '',
  value, // any => string
  render = d => value(d),
  sort = (a, b) => value(a).localeCompare(value(b)),
  filter = (d, f) => !f || contains(render(d), f),
  classes = '',
  width = 1,
}) => ({ id, type, title, value, render, sort, filter, classes, width });

export const NumberColumn = ({
  id = shortid.generate(),
  type = COLUMN_TYPE.NUMBER,
  title = '',
  value, // any => number,
  formatter = Parse.number({ required: true })
    .then(Format.string.new)
    .catch(Format.string.empty)
    .value.resolved(),
  render = d => formatter(value(d)),
  sort = (a, b) => value(a) - value(b),
  filter = (d, f) => !f || contains(render(d), f),
  classes = 'text-right',
  width = 1,
}) => ({ id, type, title, value, render, sort, filter, classes, width });

const CurrencyColumn = ({
  id = shortid.generate(),
  type = COLUMN_TYPE.CURRENCY,
  title = '',
  value, // any => float
  formatter = Parse.number({ required: true })
    .then(Format.number.usd)
    .catch(Format.string.empty)
    .value.resolved(),
  render = d => formatter(value(d)),
  sort = (a, b) => value(a) - value(b),
  filter = (d, f) => !f || contains(render(d), f),
  classes = 'text-right',
  width = 1,
}) => ({ id, type, title, value, render, sort, filter, classes, width });

const RateColumn = ({
  id = shortid.generate(),
  type = COLUMN_TYPE.RATE,
  title = '',
  value, // any => float
  formatter = Parse.number({ required: true })
    .then(Format.number.percent(3))
    .catch(Format.string.empty)
    .value.resolved(),
  render = d => formatter(value(d)), // any => string/node
  sort = (a, b) => value(a) - value(b),
  filter = (d, f) => !f || contains(render(d), f),
  classes = 'text-right',
  width = 1,
}) => ({ id, type, title, value, render, sort, filter, classes, width });

const DateColumn = ({
  id = shortid.generate(),
  type = COLUMN_TYPE.DATE,
  title = '',
  value, // any => date
  formatter = Parse.date({ required: true })
    .then(Format.date.format('MM/DD/YYYY'))
    .catch(Format.string.empty)
    .value.resolved(),
  render = d => formatter(value(d)), // any => string/node
  sort = (a, b) => value(a) - value(b),
  filter = (d, f) => !f || contains(render(d), f),
  classes = '',
  width = 1,
}) => ({ id, type, title, value, render, sort, filter, classes, width });

export default {
  text: TextColumn,
  number: NumberColumn,
  currency: CurrencyColumn,
  rate: RateColumn,
  date: DateColumn,
};
