import { apiProvider } from '@ent/browser';
import * as CONST from '~/store/cardnav/constants';
import valet from '~/store/cardnav/valets/cardnavValet';

export default ({
  force,
  state,
  reducer = 'cardnav',
  mockProvider = null,
  redirectOn403 = true,
  autoUpdateWorkflows = true,
} = {}) => (dispatch, getState) => new Promise(resolve => {
  const should = valet.shouldGet({ force, state, reducer, getState, autoUpdateWorkflows });
  if (!should) {
    return resolve();
  }
  dispatch({ type: CONST.CARDNAV_GET });
  const request = { url: '/Banking/api/cardnav/list', redirectOn403, autoUpdateWorkflows };
  const success = data => dispatch({ type: CONST.CARDNAV_GET_SUCCESS, payload: data });
  const failure = error => dispatch({ type: CONST.CARDNAV_GET_ERROR, payload: error.message });
  const provider = mockProvider || apiProvider;
  return resolve(provider(request).then(success).catch(failure));
});
