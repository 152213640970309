import React from 'react';
import PropTypes from 'prop-types';

export default ({ visible: defaultVisible = false } = {}) => Component => {
  const WithVisibility = ({ visible, ...props }) => visible && <Component visible={visible} {...props} />;

  WithVisibility.propTypes = { visible: PropTypes.bool };

  WithVisibility.defaultProps = { visible: defaultVisible };

  WithVisibility.displayName = `WithVisibility(${Component.displayName || Component.name || 'Component'})`;

  return WithVisibility;
};
