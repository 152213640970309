import { Format, Parse } from '~/common';

const init = { username: config => Parse.username({ required: true, regex: /^[\w.@-]{4,34}$/i, ...config }) };

export const formatter = {
  user: init
    .username()
    .catch(Format.string.empty)
    .value.resolved(),
  mask: init
    .username()
    .then(Format.string.maskMiddle)
    .catch(Format.string.empty)
    .value.resolved(),
};
