import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { createRedirector } from '~/common/createRedirector';

import decorator from './decorators';

export default () => BaseComponent => {
  class WithRouter extends Component {
    redirect = (url, state) => {
      const { history } = this.props;
      if (url) {
        const redirect = createRedirector(history);
        redirect(url, state);
      }
    };

    render() {
      return <BaseComponent redirect={this.redirect} {...this.props} />;
    }
  }

  WithRouter.propTypes = {
    // react-router
    history: PropTypes.object.isRequired,
  };

  WithRouter.defaultProps = {};

  WithRouter.displayName = `withRouter(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return decorator(WithRouter);
};
