import { Format } from '../data-tada';

// The implementation of AES is a modified version of jsaes (http://point-at-infinity.org/jsaes/)
export default () => {
  const sBox = Format.string.fromHex(
    [
      '637c777bf26b6fc53001672bfed7ab76ca82c97dfa5947f0add4a2af9ca472c0',
      'b7fd9326363ff7cc34a5e5f171d8311504c723c31896059a071280e2eb27b275',
      '09832c1a1b6e5aa0523bd6b329e32f8453d100ed20fcb15b6acbbe394a4c58cf',
      'd0efaafb434d338545f9027f503c9fa851a3408f929d38f5bcb6da2110fff3d2',
      'cd0c13ec5f974417c4a77e3d645d197360814fdc222a908846eeb814de5e0bdb',
      'e0323a0a4906245cc2d3ac629195e479e7c8376d8dd54ea96c56f4ea657aae08',
      'ba78252e1ca6b4c6e8dd741f4bbd8b8a703eb5664803f60e613557b986c11d9e',
      'e1f8981169d98e949b1e87e9ce5528df8ca1890dbfe6426841992d0fb054bb16',
    ].join(''),
  );
  const shiftRowTab = [0, 5, 10, 15, 4, 9, 14, 3, 8, 13, 2, 7, 12, 1, 6, 11];

  let sBoxInv;
  let shiftRowTabInv;
  let xTime;

  /* eslint-disable no-param-reassign, no-bitwise */
  const subBytes = (state, _sBox) => {
    for (let i = 0; i < 16; i++) {
      state[i] = _sBox[state[i]];
    }
  };

  const addRoundKey = (state, rkey) => {
    for (let i = 0; i < 16; i++) {
      state[i] ^= rkey[i];
    }
  };

  const shiftRows = (state, shifttab) => {
    const h = [].concat(state);
    for (let i = 0; i < 16; i++) {
      state[i] = h[shifttab[i]];
    }
  };

  const mixColumns = state => {
    for (let i = 0; i < 16; i += 4) {
      const [s0, s1, s2, s3] = state.slice(i, i + 4);
      const h = s0 ^ s1 ^ s2 ^ s3;
      state[i + 0] ^= h ^ xTime[s0 ^ s1];
      state[i + 1] ^= h ^ xTime[s1 ^ s2];
      state[i + 2] ^= h ^ xTime[s2 ^ s3];
      state[i + 3] ^= h ^ xTime[s3 ^ s0];
    }
  };

  const mixColumnsInv = state => {
    for (let i = 0; i < 16; i += 4) {
      const [s0, s1, s2, s3] = state.slice(i, i + 4);
      const h = s0 ^ s1 ^ s2 ^ s3;
      const xh = xTime[h];
      const h1 = xTime[xTime[xh ^ s0 ^ s2]] ^ h;
      const h2 = xTime[xTime[xh ^ s1 ^ s3]] ^ h;
      state[i + 0] ^= h1 ^ xTime[s0 ^ s1];
      state[i + 1] ^= h2 ^ xTime[s1 ^ s2];
      state[i + 2] ^= h1 ^ xTime[s2 ^ s3];
      state[i + 3] ^= h2 ^ xTime[s3 ^ s0];
    }
  };

  const init = () => {
    sBoxInv = new Array(256);
    for (let i = 0; i < 256; i++) {
      sBoxInv[sBox[i]] = i;
    }

    shiftRowTabInv = new Array(16);
    for (let i = 0; i < 16; i++) {
      shiftRowTabInv[shiftRowTab[i]] = i;
    }

    xTime = new Array(256);
    for (let i = 0; i < 128; i++) {
      xTime[i] = i << 1;
      xTime[128 + i] = (i << 1) ^ 0x1b;
    }
  };

  const done = () => {
    sBoxInv = undefined;
    shiftRowTabInv = undefined;
    xTime = undefined;
  };

  const expandKey = key => {
    const kl = key.length;
    const ks = { 16: 176, 24: 312, 32: 240 }[kl];
    if (!ks) {
      throw Error('AES.expandKey: Only key lengths of 16, 24 or 32 bytes allowed.');
    }
    let Rcon = 1;
    for (let i = kl; i < ks; i += 4) {
      let temp = key.slice(i - 4, i);
      if (i % kl === 0) {
        temp = [sBox[temp[1]] ^ Rcon, sBox[temp[2]], sBox[temp[3]], sBox[temp[0]]];
        // eslint-disable-next-line no-cond-assign
        if ((Rcon <<= 1) >= 256) {
          Rcon ^= 0x11b;
        }
      } else if (kl > 24 && i % kl === 16) {
        temp = [sBox[temp[0]], sBox[temp[1]], sBox[temp[2]], sBox[temp[3]]];
      }
      for (let j = 0; j < 4; j++) {
        // eslint-disable-next-line no-mixed-operators
        key[i + j] = key[i + j - kl] ^ temp[j];
      }
    }
  };
  /* eslint-enable no-param-reassign */

  const perform = fn => (block, _key) => {
    const key = [..._key];
    init();
    expandKey(key);
    fn(block, key);
    done();
  };

  const encrypt = perform((block, key) => {
    const l = key.length;
    addRoundKey(block, key.slice(0, 16));
    let i;
    for (i = 16; i < l - 16; i += 16) {
      subBytes(block, sBox);
      shiftRows(block, shiftRowTab);
      mixColumns(block);
      addRoundKey(block, key.slice(i, i + 16));
    }
    subBytes(block, sBox);
    shiftRows(block, shiftRowTab);
    addRoundKey(block, key.slice(i, l));
  });

  const decrypt = perform((block, key) => {
    const l = key.length;
    addRoundKey(block, key.slice(l - 16, l));
    shiftRows(block, shiftRowTabInv);
    subBytes(block, sBoxInv);
    for (let i = l - 32; i >= 16; i -= 16) {
      addRoundKey(block, key.slice(i, i + 16));
      mixColumnsInv(block);
      shiftRows(block, shiftRowTabInv);
      subBytes(block, sBoxInv);
    }
    addRoundKey(block, key.slice(0, 16));
  });

  return { encrypt, decrypt };
};
