import moment from 'moment';
import { noop } from '@ent/functional';
import { EVENT, apiProvider } from '@ent/browser';

/**
 * @param {int} timeoutDuration - # of milliseconds from last request until timeout - default: 20 minutes
 * @param {func} timeoutHandler - optional callback
 * @param {int} warningDuration - # of milliseconds before timeout for warning - default: 5 minutes
 * @param {func} warningHandler - optional callback
 * @param {int} intervalDuration - # of milliseconds for batch processing of active/passive fetch calls
 * @param {func} logoutHandler - optional callback
 * @param {func} startSession - redux action
 * @param {func} keepAlive - redux action
 * @param {func} setSessionStatus - redux action
 * @param {func} stopSession - redux action
 * @returns {object} - {{start(), continue(), stop(), isRunning()}
 */
const createSessionManager = ({
  timeoutDuration = 20 * 60 * 1000,
  timeoutHandler = noop,
  warningDuration = 5 * 60 * 1000,
  warningHandler = noop,
  intervalDuration = 30 * 1000,
  logoutHandler = noop,
  // redux actions
  startSession,
  keepAlive,
  setSessionStatus,
  stopSession,
} = {}) => {
  let warningId = 0;
  let timeoutId = 0;
  let intervalId = 0;

  let isRunning = false;
  let timeoutTime = null;
  const timeoutDurationInMinutes = timeoutDuration / (60 * 1000);
  let state = null;
  const STATE = {
    ACTIVE: 'active',
    PASSIVE: 'passive',
  };
  const setActive = () => {
    state = STATE.ACTIVE;
  };
  const setPassive = () => {
    state = state || STATE.PASSIVE;
  };
  const EVENTS = [
    { type: EVENT.FETCH_SUCCESS, listener: setActive },
    { type: EVENT.VALET_FETCH_SKIPPED, listener: setPassive },
    { type: EVENT.MONEY_DESKTOP_PING, listener: setPassive },
    { type: EVENT.BILLPAY_PING, listener: setPassive },
  ];
  const interval = () => {
    switch (state) {
      case STATE.ACTIVE:
        touch();
        break;
      case STATE.PASSIVE:
        makeKeepAliveRequest();
        break;
      default:
        break;
    }
    state = null;
  };

  function makeKeepAliveRequest() {
    if (process.env.NODE_ENV !== 'development') {
      const request = {
        url: '/Banking/keepalive.aspx',
        method: 'get',
        data: null,
      };
      apiProvider(request)
        .then(touch)
        .catch(noop);
    }
  }

  function start() {
    if (!isRunning) {
      startSession();
      isRunning = true;
      EVENTS.forEach(({ type, listener }) => window.addEventListener(type, listener));
      intervalId = setInterval(interval, intervalDuration);
    }
    touch();
  }

  function touch() {
    if (warningDuration > 0) {
      clearTimeout(warningId);
      warningId = setTimeout(warning, timeoutDuration - warningDuration);
    }
    clearTimeout(timeoutId);
    timeoutId = setTimeout(timeout, timeoutDuration);
    timeoutTime = moment()
      .add(timeoutDuration, 'ms')
      .format('LTS');
    keepAlive();
  }

  function stop() {
    isRunning = false;
    EVENTS.forEach(({ type, listener }) => window.removeEventListener(type, listener));
    clearTimeout(warningId);
    clearTimeout(timeoutId);
    clearInterval(intervalId);
    stopSession();
    timeoutTime = null;
  }

  function warning() {
    setSessionStatus('warning');
    warningHandler();
  }

  function timeout() {
    if (isRunning) {
      stop();
      setSessionStatus('timeout');
      logoutHandler();
      timeoutHandler();
    }
  }

  function getTimeoutTime() {
    return timeoutTime;
  }

  return {
    start,
    continue: makeKeepAliveRequest,
    stop,
    isRunning: () => isRunning,
    getTimeoutTime,
    timeoutDurationInMinutes,
  };
};

export default createSessionManager;
