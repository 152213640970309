import { USER_ENTITLEMENT } from '~/constants/userEntitlement';
import { USER_TYPE } from '~/constants/userType';
import { USER_ROLE } from '~/constants/userRole';

import selectUserRole from './selectUserRole';
import selectUserEntitlement from './selectUserEntitlement';
import selectUserType from './selectUserType';

const createSelectors = (enumeration, selector) => Object.entries(enumeration).reduce(
  (o, [key, value]) => ({
    ...o,
    [key]: selector(value),
  }),
  {}
);

export const SELECT_USER_ROLE = createSelectors(USER_ROLE, selectUserRole);

export const SELECT_USER_ENTITLEMENT = createSelectors(USER_ENTITLEMENT, selectUserEntitlement);

export const SELECT_USER_TYPE = createSelectors(USER_TYPE, selectUserType);
