const toEnum = (...keys) => keys.reduce((o, k, i) => ({ ...o, [k]: i + 1 }), {});

export default toEnum(
  'ACCESS_DENIED',
  'ACCOUNTS',
  'ACCOUNTS_DETAIL',
  'ACCOUNTS_TRANSACTION_DETAIL',
  'ACCOUNTS_DETAIL_LOAN_PAYOFF',
  'ACCOUNTS_DETAIL_LOAN_PAYOFF_PRINT',
  'ACCOUNTS_DETAIL_PRINT',
  'ACCOUNTS_DETAIL_CHECK_PRINT',
  'ACCOUNTS_DETAIL_TRANSACTION_PRINT',
  'ACCOUNTS_CHECK_PRINT',
  'ACCOUNTS_TRANSACTION_PRINT',
  'ACCOUNT_FAVORITES',
  'ACCOUNT_LOCKED',
  'ADDRESS_PHONE',

  'BILL_PAY',
  'BILL_PAY_BLOCKED',
  'BILL_PAY_ERROR',
  'BILL_PAY_PAYMENT',
  'BILL_PAY_PAYEE_MANAGE',
  'BILL_PAY_PAYEE_DETAILS',
  'BILL_PAY_PAYEE_MANAGE_DETAILS',
  'BILL_PAY_PAYEE_ACTIVITY',
  'BILL_PAY_PAYEE_ADD',
  'BILL_PAY_PAYMENT_HISTORY_UPCOMING',
  'BILL_PAY_PAYMENT_HISTORY_PROCESSED',
  'BILL_PAY_PAYMENT_HISTORY_EBILL',
  'BILL_PAY_EBILL_AUTOPAY',
  'BILL_PAY_EBILL_DETAILS',
  'BILL_PAY_EBILL_ENROLLMENT',

  'BRONCOS_CONVERSION',

  'CALL_CENTER_PASSWORD',
  'CARDS',
  'CARD_ACTIVATE_AUTHENTICATED',
  'CARD_ACTIVATE_UNAUTHENTICATED',
  'CARD_ACTIVATE_UNAUTHENTICATED_SUCCESS',
  'CARD_ALERTS',
  'CARD_FREEZE',
  'CARD_RESTRICTION',
  'CARD_SPENDING_LIMIT',
  'CARD_TRAVEL_NOTE_EDIT',
  'CARD_UNFREEZE',
  'CERTIFICATE_MATURITY',
  'CERTIFICATE_DIVIDEND',
  'CHANGE_PIN',
  'CONTACT',
  'CONTACT_INFO',
  'CREDIT_CARD_DETAIL',
  'DESKTOP_DEPOSIT_CHECKS',
  'DISCLOSURE_ACCOUNT_AGREEMENT',
  'DOWNLOADS',
  'ENT_PROTECT_INFORMATION',
  'EMAIL',
  'ENTPROTECT_ENROLL',
  'ENTPROTECT_ENROLL_CONFIRMATION',
  'ENTPROTECT_PRODUCT_PAGE',
  'ENT_PROTECT_ENROLL_CONTACTINFO',
  'FINANCES',
  'FINANCES_ABOUT',
  'FINANCES_CONFIGURE',
  'FOR_YOU',
  'FORGOT_PASSWORD',
  'FORGOT_PASSWORD_COMPLETE',
  'HOME',
  'LIFE_HAPPENS',
  'LOCATIONS',
  'LOAN_APPLICATION_CONTINUE',
  'LOAN_APPLICATION_NEW',
  'LOAN_APPLICATION_SELECT',
  'LOAN_APPLICATION_CONTACTINFO',
  'LOAN_PAY_UNAUTHENTICATED',
  'LOAN_PAY_UNAUTHENTICATED_VERIFY',
  'LOAN_PAY_UNAUTHENTICATED_PAYMENT',
  'LOAN_PRE_QUAL',
  'LOAN_SKIP_PAY',
  'LOGIN',
  'LOGIN_PASSWORD',
  'LOGIN_SUCCESS',
  'MEMBER_AMBASSADOR',
  'MFA_CALL',
  'MFA_CHALLENGE',
  'MFA_EMAIL',
  'MFA_FAIL',
  'MFA_PUSH',
  'MFA_SMS',
  'MFA_SUCCESS',
  'MIGRATION_START',
  'MORTGAGE_ESCROW',
  'NOT_FOUND',
  'OPTIN_ADDRESS_PHONE',
  'OPTIN_PHONE',
  'ORDER_CHECKS',
  'OVERDRAFT',
  'PASSWORD',
  'PROFILE',
  'PROFILE_SECURITY_PROFILE',
  'PROMOTIONS',
  'QUICK_BALANCE',
  'REACTIVATE_CARD',
  'REGISTRATION',
  'REGISTRATION_COMPLETE',
  'SERVICES',
  'SETUP_ADDRESS_PHONE',
  'SETUP_CONTACT_INFO',
  'SETUP_EMAIL',
  'SETUP_PASSWORD',
  'SETUP_SECURITY_PROFILE',
  'STATEMENT_PREFERENCES',
  'STATEMENTS',
  'STOP_PAYMENT',
  'TAX_PAYMENTS',
  'TEXT_BANKING',
  'TEXT_BANKING_ENROLL',
  'TEXT_BANKING_UNENROLL',

  'TRANSFER_LOAN',
  'TRANSFER_LOAN_ACCOUNT',
  'TRANSFER_LOAN_ACCOUNT_EXTERNAL_EDIT',
  'TRANSFER_LOAN_ACCOUNT_EXTERNAL_VERIFY',
  'TRANSFER_LOAN_ACCOUNT_INTERNAL_EDIT',
  'TRANSFER_LOAN_EDIT',

  'TRANSFER_SHARE',
  'TRANSFER_SHARE_ACCOUNT',
  'TRANSFER_SHARE_ACCOUNT_EXTERNAL_EDIT',
  'TRANSFER_SHARE_ACCOUNT_EXTERNAL_VERIFY',
  'TRANSFER_SHARE_ACCOUNT_INTERNAL_EDIT',
  'TRANSFER_SHARE_EDIT',

  'USER_ACTIVITY',
  'USER_EDIT',
  'USERNAME',
  'USERS_LIST'
);
