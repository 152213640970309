import { Parse } from '@ent/data-tada';

const { defaults } = Parse.password;
defaults.minLength = 8;
defaults.maxLength = 50;
defaults.minLower = 0;
defaults.maxLower = Number.MAX_VALUE;
defaults.minUpper = 0;
defaults.maxUpper = Number.MAX_VALUE;
defaults.minDigit = 1;
defaults.maxDigit = Number.MAX_VALUE;
defaults.minSymbol = 0;
defaults.maxSymbol = Number.MAX_VALUE;
defaults.minNonPrint = 0;
defaults.maxNonPrint = 0;
defaults.minAsciiExtended = 0;
defaults.maxAsciiExtended = 0;
defaults.minUnicode = 0;
defaults.maxUnicode = 0;
defaults.regex = /^(?=.*\d)(?=.*[a-zA-Z])[\w'~!@#$%^&*()_+\-{}|;:?,./"]{8,50}$/;

export default Parse.password;
