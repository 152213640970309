import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';

import { addressShape, log } from '~/common';

import AddressPanel from './AddressPanel';

class AddressDialog extends Component {
  logHandler = (action, fn) => (...args) => {
    log({
      category: 7, // Audit
      message: `SmartyStreets: Verify: ${action}`,
      context: 'true',
    });
    fn(...args);
  };

  render() {
    const { issues, suggested, original, suggestedHandler, originalHandler, cancelHandler } = this.props;

    const valid = suggested ? 'Valid' : 'Invalid';
    const doCancel = this.logHandler(`${valid}: Cancel`, cancelHandler);
    const doSuggested = this.logHandler('Valid: Select: Fixed', suggestedHandler);
    const doOriginal = this.logHandler(`${valid}: Select: Original`, originalHandler);

    return (
      <Modal show onHide={doCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Address Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body data-autoid="address-validation">
          Issues with address:
          <ul>{issues.map(issue => <li key={issue}>{issue}</li>)}</ul>
          <p>Which address would you like to use?</p>
          <div className="row">
            {suggested && (
              <AddressPanel
                type="primary"
                title="Suggested Address:"
                address={suggested}
                buttonText="Accept"
                buttonAutoId="suggested-address"
                buttonType="submit"
                clickHandler={doSuggested} />
            )}
            {!suggested && (
              <AddressPanel
                type="primary"
                title="Re-enter address:"
                body="Go back and edit the address manually."
                buttonText="Cancel"
                buttonAutoId="reenter-address"
                buttonType="submit"
                clickHandler={doCancel} />
            )}
            <AddressPanel
              type="default"
              title="Address as entered:"
              address={original}
              buttonText="Accept"
              buttonAutoId="accept-entered"
              buttonKind="default"
              clickHandler={doOriginal} />
            {suggested && (
              <AddressPanel
                type="default"
                title="Re-enter address:"
                body="Go back and edit the address manually."
                buttonText="Cancel"
                buttonAutoId="reenter-address"
                buttonKind="default"
                clickHandler={doCancel} />
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

AddressDialog.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.string).isRequired,
  suggested: PropTypes.shape(addressShape),
  original: PropTypes.shape(addressShape).isRequired,
  suggestedHandler: PropTypes.func.isRequired,
  originalHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

AddressDialog.defaultProps = { suggested: null };

export default AddressDialog;
