import React, { Component } from 'react';
import PropTypes from 'prop-types';

import columnShape from './columnShape';
import DataCell from './DataCell';

class DataRow extends Component {
  // eslint-disable-next-line no-unused-vars
  click = event => {
    const { clickHandler, data } = this.props;
    if (clickHandler) {
      clickHandler(data);
    }
  };

  // eslint-disable-next-line no-unused-vars
  keypress = event => {
    const { clickHandler, data } = this.props;
    if (clickHandler) {
      switch (event.nativeEvent.keyCode) {
        case 13: // Enter
        case 32: // Space
          clickHandler(data);
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { data, dataAutoId, columns, selected, clickHandler } = this.props;
    const classes = selected ? 'active' : null;

    let other;
    if (clickHandler) {
      other = {
        role: 'button',
        tabIndex: '0',
        onClick: this.click,
        onKeyPress: this.keypress,
      };
    }

    return (
      <tr className={classes} data-autoid={`table-entry-${dataAutoId}`} {...other}>
        {columns.map(c => <DataCell key={`${data.id}-${c.id}`} data={data} column={c} />)}
      </tr>
    );
  }
}

DataRow.propTypes = {
  data: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(columnShape).isRequired,
  selected: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func,
};

DataRow.defaultProps = { clickHandler: null };

export default DataRow;
