import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { device } from '@ent/browser';
import 'font-awesome/css/font-awesome.css';

import { Ad, MessageQueue, MessageQueueProvider, ProgressProvider, SmartyStreetsProvider } from '~/common/components';
import { Storage } from '~/common/Storage';
import { SessionTimeout } from '~/components/SessionTimeout';
import { SideNav } from '~/components/SideNav';
import { EmptyContentMenu } from '~/components/ContentMenu';
import { Parse, Format } from '~/common';

import WrapperContent from './WrapperContent';
import logoImage from './entLogo.png';
import logoImageDarkMode from './entLogo-whiteText.png';
import Footer from './Footer';
import './styles.scss';

const init = { string: config => Parse.string({ required: true, ...config }) };

export const formatter = {
  account: init
    .string()
    .then(Format.string.right(3))
    .then(Format.string.padStart(1, '*'))
    .catch(Format.string.empty)
    .value.resolved(),
  switch: a => `Switch Accounts ${formatter.account(a)}`,
};

class App extends Component {
  componentDidMount() {
    const { getEnv } = this.props;
    getEnv();
    if (window.dataLayer && window.dataLayer.push) {
      window.dataLayer.push({ event: 'optimize.activate' });
    }
  }

  onMenuClick = () => {
    const { toggleSideNav } = this.props;
    toggleSideNav();
  };

  onUpClick = () => {
    const { hierarchy, redirect } = this.props;
    if (hierarchy.length > 1) {
      const page = hierarchy[hierarchy.length - 2];
      if (page && page.path) {
        redirect(page.path, page.state);
      }
    }
  };

  onLogOutClick = () => {
    const { storagePersist } = this.props;
    storagePersist({ enabled: false });
    Storage.session.clear();
    document.location.assign('/Banking/Logoff.aspx');
  };

  render() {
    const {
      authenticated,
      background,
      bsSize,
      className,
      children,
      contentMenu: ContentMenu,
      hierarchy,
      location,
      showSideNav,
      showUpButton,
      user,
      www,
      xl,
      hideFooter,
      showFooter,
      smartyStreetsKey,
      page,
      migrationStart,
    } = this.props;
    const { isAndroid: android, isEntMobile: entMobile, isIos: ios, isIE: ie } = device;
    const { title } = page || {};

    const upPage = () => hierarchy[hierarchy.length - 2] || {};

    const classes = classNames({
      main: true,
      background,
      www,
      [bsSize]: true,
      authenticated,
      android,
      entMobile,
      ios,
      ie,
      unauthenticated: !authenticated,
      sidenav: showSideNav,
      [className]: !!className,
    });

    const menuButton = (
      <button type="button" className="top-nav left" data-autoid="side-menu-toggle" onClick={this.onMenuClick}>
        <i title="Menu" className="fa fa-fw fa-navicon" />
        <span>Menu</span>
      </button>
    );

    const upButton = (
      <button type="button" className="top-nav left" data-autoid="BackCaret" onClick={this.onUpClick}>
        <i title="Back" className="fa fa-fw fa-lg fa-caret-left" />
        <span>{upPage().title}</span>
      </button>
    );
    const entLogo = (
      <a className="ent-logo" href="/Banking/Home.aspx">
        {www && <div className="icon logo" title="Ent Credit Union" />}
        {!www && (
          <>
            <img alt="Home" title="Home" src={logoImage} className="show-light" />
            <img alt="Home" title="Home" src={logoImageDarkMode} className="show-dark" />
          </>
        )}
      </a>
    );

    return (
      <ProgressProvider>
        <Helmet>{title && <title>{`${title} | Ent Online Banking`}</title>}</Helmet>
        <a href="#main-content" className="skipToContent">
          Skip to main content
        </a>
        <MessageQueueProvider>
          <SmartyStreetsProvider smartyStreetsKey={smartyStreetsKey}>
            <Ad id="ad-header" />
            <div className={classes}>
              <SessionTimeout authenticated={authenticated} logoutHandler={this.onLogOutClick} />
              <div className="wrapper-page">
                <div className="dock-top">
                  {xl && showSideNav && !migrationStart && <div className="header-text-desktop">{entLogo}</div>}
                  {!xl && authenticated && !showSideNav && !migrationStart && menuButton}
                  {hierarchy.length > 1 && showUpButton && upButton}
                  {((!xl || !showSideNav) || migrationStart) && <div className="header-text">{entLogo}</div>}
                  <div className="right">
                    {authenticated && !migrationStart && (
                      <button type="button" className="top-nav" onClick={this.onLogOutClick}>
                        {xl && <span>Log Out</span>}
                        <i title="Log Out" className="fa fa-fw fa-lg fa-power-off" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="wrapper-container">
                  {authenticated && !migrationStart
                    && <SideNav user={user} collapsed={!showSideNav} currentPath={location.pathname} />}
                  <div className="wrapper-container-vertical">
                    <ContentMenu />
                    <WrapperContent>
                      <Ad id="ad-body" />
                      <MessageQueue />
                      <div className="content-area clearfix" id="main-content" role="main">
                        {children}
                      </div>
                      <Ad id="ad-footer" />
                      {((!authenticated && !hideFooter) || showFooter) && <Footer />}
                    </WrapperContent>
                  </div>
                </div>
              </div>
            </div>
          </SmartyStreetsProvider>
        </MessageQueueProvider>
      </ProgressProvider>
    );
  }
}

App.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  contentMenu: PropTypes.any,
  background: PropTypes.bool,
  www: PropTypes.bool,
  hideFooter: PropTypes.bool,
  showFooter: PropTypes.bool,
  showUpButton: PropTypes.bool,
  // react-redux
  authenticated: PropTypes.bool.isRequired,
  showSideNav: PropTypes.bool.isRequired,
  bsSize: PropTypes.string.isRequired,
  xl: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  hierarchy: PropTypes.array.isRequired,
  page: PropTypes.object,
  storagePersist: PropTypes.func.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
  smartyStreetsKey: PropTypes.string.isRequired,
  getEnv: PropTypes.func.isRequired,
  migrationStart: PropTypes.bool.isRequired,
  // withRouter
  location: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
};

App.defaultProps = {
  className: undefined,
  contentMenu: EmptyContentMenu,
  showUpButton: true,
  background: false,
  www: false,
  hideFooter: false,
  showFooter: false,
  page: {},
};

export default App;
