import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TopNav = ({ children }) => <div className="TopNav">{children}</div>;

TopNav.propTypes = { children: PropTypes.array.isRequired };

TopNav.defaultProps = {};

export default TopNav;
