import { apiProvider } from '@ent/browser';

import * as CONST from '~/store/cards/constants';
import valet from '~/store/cards/valets/cardsValet';

const getCards = ({
  force,
  state,
  reducer = 'cards',
  mockProvider = null,
  redirectOn403 = true,
  autoUpdateWorkflows = true,
} = {}) => (dispatch, getState) => new Promise(resolve => {
  const should = valet.shouldGet({ force, state, reducer, getState, autoUpdateWorkflows });
  if (!should) {
    return resolve();
  }

  dispatch({ type: CONST.CARDS_GET });

  const request = { url: '/Banking/api/cards/list', redirectOn403, autoUpdateWorkflows };
  const success = ({ cards }) => dispatch({ type: CONST.CARDS_GET_SUCCESS, payload: cards });
  const failure = error => dispatch({ type: CONST.CARDS_GET_ERROR, payload: error.message });

  const provider = mockProvider || apiProvider;
  const result = provider(request)
    .then(success)
    .catch(failure);
  return resolve(result);
});

export default getCards;
