import { createSelector } from 'reselect';

import initialState from '~/store/suffixes/valets/suffixesInitialState';

const selectIsInitialState = state => state.suffixes === initialState;
const selectIsLoading = state => state.suffixes.loading;

const selectIsSuffixesLoading = createSelector(
  selectIsInitialState,
  selectIsLoading,
  (initial, loading) => initial || loading,
);

export default selectIsSuffixesLoading;
