import { call, put, takeLatest, select } from 'redux-saga/effects';
import { apiProvider } from '@ent/browser';

import * as CONST from '../constants';
import selectAllSuffixes from '../selectors/selectAllSuffixes';

function* validateSuffixDataIntegrity(action) {
  try {
    const state = yield select() || {};
    const storeSuffixes = selectAllSuffixes(state);
    const { payload: balances } = action;

    if (storeSuffixes && balances && storeSuffixes.length !== balances.length) {
      yield put({ type: CONST.SUFFIXES_GET });
      const data = yield call(apiProvider, { url: '/Banking/api/accounts/suffixes', autoUpdateWorkflows: true });
      yield put({ type: CONST.SUFFIXES_GET_SUCCESS, payload: data });
    }
  } catch (e) {
    yield put({ type: CONST.SUFFIXES_GET_ERROR, payload: e.message });
  }
}

function* suffixIntegrityCheck() {
  yield takeLatest(CONST.SUFFIXES_GET_BALANCE_SUCCESS, validateSuffixDataIntegrity);
}

export default suffixIntegrityCheck;
