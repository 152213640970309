import selectPageByPath from './selectPageByPath';

// prettier-ignore
const selectPathname = state => (
  state && state.router && state.router.location
    ? state.router.location.pathname
    : window.location.pathname
);

const selectCurrentPage = state => selectPageByPath(selectPathname(state))(state);

export default selectCurrentPage;
