import React from 'react';

import ncua from './ncua.png';
import equalHousing from './equal_housing.png';

/* eslint-disable react/jsx-one-expression-per-line */
export default () => (
  <div className="footer small text-center">
    <div>
      <a href="https://www.ent.com/Privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      &nbsp;|&nbsp;
      <a href="https://www.ent.com/Legal" target="_blank" rel="noopener noreferrer">
        Important Account Information
      </a>
      &nbsp;|&nbsp;
      <a href="https://www.ent.com/Legal" target="_blank" rel="noopener noreferrer">
        Accessibility
      </a>
    </div>
    <div>
      <div className="d-inline-block">&copy; {new Date().getFullYear()} Ent Credit Union</div>
      &nbsp;&#8226;&nbsp;
      <div className="d-inline-block">Equal Housing Opportunity</div>
      &nbsp;&#8226;&nbsp;
      <div className="d-inline-block">Insured by NCUA</div>
      &nbsp;&#8226;&nbsp;
      <div className="d-inline-block">Equal Opportunity Lender</div>
      &nbsp;&#8226;&nbsp;
      <div className="d-inline-block">Ent is a registered trademark of Ent Credit Union</div>
    </div>
    <div>
      <img src={equalHousing} alt="Equal Housing Lender" height="25" />
      <img src={ncua} alt="NCUA" height="25" />
    </div>
  </div>
);
