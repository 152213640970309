export const AB_TEST = { };

export const ACCOUNT_TYPE = {
  GENERAL_MEMBERSHIP: 0,
  CORPORATE: 4,
  BUSINESS: 5,
};

export const ACH_ACCOUNT_TYPE = {
  CHECKING: 1,
  SAVINGS: 2,
};

export const ACH_ACCOUNT_TYPE_TEXT = {
  [ACH_ACCOUNT_TYPE.CHECKING]: 'Checking',
  [ACH_ACCOUNT_TYPE.SAVINGS]: 'Savings',
};

export const ADDRESS_TYPE = {
  UNKNOWN: -1,
  DOMESTIC: 0,
  FOREIGN: 1,
};

export const ALERT_CHANNEL = {
  EMAIL: 'SMTP',
  SMS: 'SMS Email',
  PUSH: 'Push',
};

export const ALERT_CHANNEL_TEXT = {
  [ALERT_CHANNEL.EMAIL]: 'Email Alert',
  [ALERT_CHANNEL.SMS]: 'Text Alert',
  [ALERT_CHANNEL.PUSH]: 'Push Alert',
};

export const ALLOWED_LOAN_PAYOFF_TYPES = {
  NEW_AUTO: 1,
  USED_AUTO: 2,
  SHARE_CERT_SECURED: 5,
  PERSONAL: 6,
  PERSONAL_LINE_OF_CREDIT: 7,
  RECREATIONAL_VEHICLE: 8,
  MOTORCYCLE: 9,
  OTHER_GOODS: 11,
  PARKVIEW: 13,
  NEW_AUTO_INDIRECT: 18,
  USED_AUTO_INDIRECT: 19,
  OTHER_NEW_VEHICLE_INDIRECT: 20,
  OTHER_USED_VEHICLE_INDIRECT: 21,
  BUSINESS_LOC: 60,
  BUSINESS_AUTO: 61,
};

// NOTE: Add entries for ORCC API calls
export const API_REQUEST_TOKEN = {};

export const CARD_ACTION = {
  NONE: 0,
  ACTIVATE: 1,
  CHANGE_PIN: 8,
  TRAVEL: 16,
};

export const CARD_STATUS = {
  INACTIVE: 1,
  ACTIVE: 2,
  EXCESSIVE_PIN_TRIES: 3,
  CLOSED: 4,
  FRAUD: 5,
  REISSUED: 6,
  PENDING_ACTIVATION: 7,
};

export const CERTIFICATE_MATURITY_OPTION = {
  RENEW_SAME: '0',
  RENEW_DIFFERENT: '1',
  TRANSFER_BALANCE: '2',
};

export const DISCLOSURE = { ELECTRONIC_SERVICES_AGREEMENT: 'AccountAgreement' };

export const ENT_MOBILE_ACTION = {
  INAUTH_START: 1,
  INAUTH_COMPLETE: 2,
  BIOMETRIC_INIT: 3,
  BIOMETRIC_SUBMIT: 4,
  BIOMETRIC_SUBMIT_ERROR: 5,
  BIOMETRIC_SUBMIT_SUCCESS: 6,
  LOGIN_SUCCESS: 7,
  LOG: 8,
  CREDITCARD_SSO: 9,
  TRANSFER_SUCCESS: 10,
  PAYBILL_SUCCESS: 11,
  BACKBASE_REDIRECT: 12,
};

export const ENT_PROTECT_STATUS = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  FAILED: 'Failed',
};

export const LOCATION_SERVICES = {
  NONE: 0,
  ITM: 1,
  DRIVE_UP_ATM: 2,
  WALK_UP_ATM: 4,
  COIN_COUNTER: 8,
  INSTANT_ISSUE_DEBIT: 16,
  OLB_KIOSK: 32,
  SAFE_BOXES: 64,
  ACCEPT_DEPOSIT: 128,
  ACCEPT_CASH: 256,
};

export const LOCATION_TYPES = {
  ENT_CENTER: 1,
  ENT_EXPRESS: 2,
  ENT_ATM: 4,
  SHARED_BRANCH: 8,
  SHARED_ATM: 16,
};

export const MESSAGE_TYPE = {
  PRIMARY: 0,
  INFO: 1,
  SUCCESS: 2,
  WARNING: 3,
  ERROR: 4,
};

export const MFA_METHODS = {
  NONE: 0,
  SMS: 1,
  CALL: 2,
  PUSH: 4,
  EMAIL: 16,
};

export const NAME_SUFFIX = {
  'JR.': 1,
  'SR.': 2,
  I: 3,
  II: 4,
  III: 5,
  IV: 6,
};

export const NAME_TITLE = {
  'MR.': 1,
  'MS.': 2,
  'MRS.': 3,
  MISS: 4,
  'DR.': 5,
};

export const NUM_ACCOUNTS = {
  DEPOSITS: 4,
  LOANS: 3,
};

export const OPT_IN = {
  UNSPECIFIED: 0,
  OPTED_IN: 1,
  OPTED_OUT: 2,
};

export const OPT_IN_STATE = {
  UNSPECIFIED: 'UNSPECIFIED',
  DO_NOT_CONTACT: 'DO_NOT_CONTACT',
  BOTH: 'BOTH',
  PHONE_ONLY: 'PHONE_ONLY',
  TEXT_ONLY: 'TEXT_ONLY',
};

export const RELATED_NAMES = {
  BENEFICIARY: 'Beneficiary',
  JOINT: 'Joint',
  RESPONSIBLE_INDIVIDUAL: 'Responsible Individual',
  COSIGNER: 'Cosigner',
  TRUSTEE: 'Trustee',
  CUSTODIAN: 'Custodian',
};

export const OTHER_INCOME = {
  SOCIAL_SECURITY: 1,
  PENSION: 2,
  RETIREMENT_INVESTMENTS: 3,
  DISABILITY: 4,
  UNEMPLOYMENT: 5,
  RENTAL_PROPERTY: 6,
  ALIMONY: 7,
  CHILD_SUPPORT: 8,
  OTHER: 9,
};

export const OTHER_INCOME_TEXT = {
  [OTHER_INCOME.SOCIAL_SECURITY]: 'Social Security',
  [OTHER_INCOME.PENSION]: 'Pension',
  [OTHER_INCOME.RETIREMENT_INVESTMENTS]: 'Retirement Investments',
  [OTHER_INCOME.DISABILITY]: 'Disability',
  [OTHER_INCOME.UNEMPLOYMENT]: 'Unemployment',
  [OTHER_INCOME.RENTAL_PROPERTY]: 'Rental Property',
  [OTHER_INCOME.ALIMONY]: 'Alimony',
  [OTHER_INCOME.CHILD_SUPPORT]: 'Child Support',
  [OTHER_INCOME.OTHER]: 'Other',
};

export const REGEX = {
  LOAN_ACCOUNT: /^(([12](\d{9})\d{3})|((\d{1,10})(-[L]?\d{2})?))$/i,
  PASSWORD: /^(?=.*\d)(?=.*[a-zA-Z])[\w'~!@#$%^&*()_+\-{}|;:?,./"]*$/,
  USERNAME: /^(?=.*[a-z])[\w.-]*$/i,
};

export const SORT_DIRECTION = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const STATEMENT_PREFERENCE = {
  ELECTRONIC: 1,
  ELECTRONIC_AND_PAPER: 2,
};

export const SUFFIX_ENTITLEMENT = {
  STOP_PAYMENT: 1,
  VIEW: 2,
  TRANSFER_FROM: 3,
  TRANSFER_TO: 4,
  WIRES: 5,
  ORDER_CHECKS: 6,
  CD_MANAGEMENT: 7,
  DEPOSIT_CHECKS: 8,
};

export const SUFFIX_TYPE = {
  SAVINGS: 0,
  CHECKING: 1,
  CERTIFICATE: 2,
  LOAN: 3,
  LOC: 4,
  MORTGAGE: 5,
};

export const USER_STATUS = {
  ACTIVE: 1,
  LOCKED: 2,
  DISABLED: 3,
};
