import { log } from '~/common/log';
import { selectUser } from '~/store/user';

import { ACTIONS } from '../constants';

const setVariant = (name, variant) => (dispatch, getState) => {
  const user = selectUser(getState());
  const { accountNumber, userId } = user || {};

  log({
    category: 7, // audit
    message: 'ABTEST START',
    details: JSON.stringify({
      name,
      variant,
      account: accountNumber,
      userId,
    }),
  });

  dispatch({
    type: ACTIONS.SET_VARIANT,
    payload: {
      name,
      variant,
    },
  });
};

export default setVariant;
