import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SUFFIX_TYPE } from '~/constants';
import { format } from '~/common';

import Transactions from './Transactions';

class Suffix extends Component {
  click = () => {
    const { loadSuffix, id } = this.props;
    loadSuffix(id);
  };

  render() {
    const { available, balance, transactions, type } = this.props;
    const suffix = format.suffix['[*000-]S00 - Name'](this.props);
    const amount = type === SUFFIX_TYPE.LOAN || type === SUFFIX_TYPE.MORTGAGE ? balance : available;

    const icon = transactions instanceof Array // eslint-disable-line no-nested-ternary
      ? 'fa-caret-down'
      : transactions === 'Loading...' // eslint-disable-line no-nested-ternary
        ? 'fa-spinner fa-spin'
        : typeof transactions === 'string'
          ? 'fa-exclamation-point'
          : 'fa-caret-right';

    /* eslint-disable react/jsx-one-expression-per-line */
    return (
      <div className="Suffix">
        <button
          type="button"
          className="btn btn-default"
          onClick={this.click}
          title={`Load recent transactions for ${suffix}`}>
          <div className="pull-left">
            <i className={classNames('fa fa-fw', icon)} aria-hidden="true" />
            {suffix}
          </div>
          <div className="pull-right">{format.currency(amount)}</div>
        </button>
        <Transactions transactions={transactions} />
      </div>
    );
  }
}

Suffix.propTypes = {
  available: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  transactions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  loadSuffix: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

Suffix.defaultProps = { transactions: undefined };

export default Suffix;
