import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// HACK: This is a hack that allows optimize to place ads.
// It discourages component updating and snapshots data before updates to dangerously set after an update.
// It's a bunch of very bad ideas that should be considered an antipattern and is in no way kind to react.
// This functionality may not even be supported at a later date.
class Ad extends React.Component {
  innerRef = React.createRef();

  ads = new Map();

  componentDidMount() {
    if (window.dataLayer && window.dataLayer.push) {
      const { id } = this.props;
      window.dataLayer.push({ event: `optimize.${id}` });
    }
  }

  shouldComponentUpdate({ location }) {
    const { path } = location || {};
    const { location: currentLocation } = this.props;
    const { path: currentPath } = currentLocation || {};

    return currentPath !== path;
  }

  // NOTE: suppress warning
  componentDidUpdate() {}

  getSnapshotBeforeUpdate({ location }) {
    const { path } = location || {};
    const { location: currentLocation } = this.props;
    const { path: currentPath } = currentLocation || {};

    if (currentPath === path && this.innerRef && this.innerRef.current && this.innerRef.current.innerHTML) {
      this.ads.set(path, this.innerRef.current.innerHTML);
    }

    return null;
  }

  render() {
    const { id, className, location } = this.props;
    const { path } = location || {};
    const ad = this.ads.get(path);

    /* eslint-disable react/no-danger */
    return (
      <div
        id={id}
        ref={this.innerRef}
        className={`ad-space ${className || ''}`}
        dangerouslySetInnerHTML={{ __html: ad }} />
    );
    /* eslint-enable react/no-danger */
  }
}

Ad.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  location: PropTypes.object,
};

Ad.defaultProps = { className: '', location: null };

export default withRouter(Ad);
