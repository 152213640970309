import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/jsx-one-expression-per-line */
const HrefItem = ({ text, dataAutoId, icon, url, className }) => (
  <a className={`link-item ${className}`} data-autoid={dataAutoId} href={url}>
    <span>
      <i className={`fa fa-fw ${icon}`} aria-hidden="true" />
      &nbsp; <span>{text}</span>
    </span>
  </a>
);

HrefItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  dataAutoId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

HrefItem.defaultProps = { className: '' };

export default HrefItem;
