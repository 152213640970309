import { apiProvider } from '@ent/browser';

import { ACTIONS } from '../constants';
import { parse } from '../helpers';

export default () => async dispatch => {
  const response = await apiProvider({
    method: 'GET',
    url: '/Banking/api/userprofile/Ent',
  });

  dispatch({ type: ACTIONS.SET_USER_PROFILE, payload: parse(response) });
};
