import { createSelector } from 'reselect';
import { get } from '@ent/functional';

import selectPageByID from './selectPageByID';
import selectAllPages from './selectAllPages';

const selectReturnUrl = state => get(state, 'router.location.state.returnUrl');
const selectReturnState = state => get(state, 'router.location.state.returnState');

const selectReturnPage = id => createSelector(
  selectPageByID(id),
  selectAllPages,
  selectReturnUrl,
  selectReturnState,
  (page, allPages, returnUrl, returnState) => {
    const returnPath = (returnUrl || '').replace(document.location.origin, '');
    const returnPage = allPages.find(p => p.path === returnPath);

    return {
      ...(returnPage || page),
      selectParent: returnPage ? () => null : page.selectParent,
      selectState: () => returnState || page.state,
    };
  }
);

export default selectReturnPage;
