import WORKFLOWS from '~/constants/workflows';
import { setWorkflow } from '~/store/workflow';

import { createWorkflowEnforcer } from '~/common/createWorkflowEnforcer';
import { createRedirector } from '~/common/createRedirector';

export default (store, history) => {
  const queue = (window.workflows || []).map(wf => WORKFLOWS[wf]).filter(v => !!v);
  if (queue.length) {
    store.dispatch(
      setWorkflow({
        queue,
        finishUrl: undefined,
      })
    );
    const workflowEnforcer = createWorkflowEnforcer(store.getState);
    const workflowUrl = workflowEnforcer();
    if (workflowUrl) {
      const redirect = createRedirector(history);
      redirect(workflowUrl);
    }
  }
};
