import React from 'react';
import PropTypes from 'prop-types';

import Context from './MessageQueueContext';

import './styles.scss';

const MessageQueueProvider = ({ children, addMessage, clearMessages }) => (
  <Context.Provider
    value={{
      addMessage,
      clearMessages,
    }}>
    {children}
  </Context.Provider>
);

MessageQueueProvider.propTypes = {
  children: PropTypes.any.isRequired,
  // connect
  addMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
};

MessageQueueProvider.defaultProps = {};

export default MessageQueueProvider;
