const zip = value => {
  const digits = (value || '').replace(/\D/g, '');
  if (digits.length >= 5) {
    return digits.substr(0, 5);
  }
  return '';
};

const zipPlus4 = value => {
  const digits = (value || '').replace(/\D/g, '');
  if (digits.length >= 9) {
    return `${digits.substr(0, 5)}-${digits.substr(5, 4)}`;
  }
  return '';
};

export default {
  '00000': zip,
  '00000-0000': zipPlus4,
  '00000[-0000]': value => zipPlus4(value) || zip(value),
};
