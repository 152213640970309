import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MessageQueueItem from './MessageQueueItem';

class MessageQueue extends Component {
  close = ({ id }) => {
    const { clearMessages } = this.props;
    clearMessages({ id });
  };

  render() {
    const { messages } = this.props;
    if (!messages || messages.length === 0) {
      return null;
    }

    return (
      <div className="message-queue">
        {messages.map(item => (
          <MessageQueueItem key={item.id} item={item} close={this.close} />
        ))}
      </div>
    );
  }
}

MessageQueue.propTypes = {
  // connect
  messages: PropTypes.arrayOf(MessageQueueItem.propTypes.item).isRequired,
  clearMessages: PropTypes.func.isRequired,
};

MessageQueue.defaultProps = {};

export default MessageQueue;
