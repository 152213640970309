import { createSelector } from 'reselect';

import selectUser from './selectUser';

// prettier-ignore
const selectDecile = createSelector(
  selectUser,
  ({ accountNumber } = {}) => parseInt(accountNumber, 10) % 10
);

export default selectDecile;
