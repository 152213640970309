import React from 'react';
import PropTypes from 'prop-types';

const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.ent.mobile';
const IOS_LINK = 'itms-apps://itunes.apple.com/app/id464301726';

function getLink(entMobileIos, entMobileAndroid) {
  let url = '';

  if (entMobileIos) {
    url = IOS_LINK;
  }
  if (entMobileAndroid) {
    url = ANDROID_LINK;
  }

  return url;
}

// prettier-ignore
/* eslint-disable react/jsx-one-expression-per-line */
const OutdatedEntMobileMessage = ({ entMobileIos, entMobileAndroid }) => (
  <span>
    We’ve added new features and enhanced the security of your mobile banking experience.{' '}
    <a href={getLink(entMobileIos, entMobileAndroid)}>Click here</a>
    {' '}to update to the latest version of Ent Mobile to continue logging in.
  </span>
);

OutdatedEntMobileMessage.propTypes = {
  entMobileIos: PropTypes.bool.isRequired,
  entMobileAndroid: PropTypes.bool.isRequired,
};

OutdatedEntMobileMessage.defaultProps = {};

export default OutdatedEntMobileMessage;
