import * as CONST from '../constants';

export default (state = {}, { type, payload } = {}) => {
  switch (type) {
    case CONST.ACCOUNT_HISTORY_SET:
      return {
        ...state,
        [payload.secureSuffixId]: { ...(payload || {}) },
      };
    case CONST.ACCOUNT_HISTORY_CLEAR:
      return {
        ...state,
        [payload]: undefined,
      };
    default:
      return state;
  }
};
