const page = ({ id, title, path }) => ({ id, title, path });


const selectPageHierarchy = pageSelector => state => {
  const current = pageSelector(state);
  const hierarchy = [{
    ...page(current),
    state: current.selectState ? current.selectState(state) : null,
  }];

  let parent = current.selectParent(state);
  while (parent) {
    hierarchy.unshift({
      ...page(parent),
      state: parent.selectState ? parent.selectState(state) : null,
    });
    parent = parent.selectParent(state);
  }

  return hierarchy;
};

export default selectPageHierarchy;
