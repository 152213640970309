import { createWorkflowEnforcer } from '~/common/createWorkflowEnforcer';
import { createRedirector } from '~/common/createRedirector';
import { EVENT } from '@ent/browser';

import defaultValetState from './defaultValetState';

export default ({ defaultReducer, history, initialState, msCacheDuration, GET, ERROR, SUCCESS, RESET, EXPIRE }) => {
  const fullInitialState = Object.assign({}, defaultValetState, initialState);

  const shouldGet = ({
    force,
    state,
    reducer = defaultReducer,
    getState,
    eventTarget = window,
    autoUpdateWorkflows = true,
  }) => {
    let should;
    if (force === true) {
      should = true;
    } else if (typeof force === 'function') {
      should = force({ type: GET, getState, state, reducer });
    } else {
      const current = state || getState()[reducer];
      if (!current) {
        should = true;
      } else {
        const { data, expires, loading } = current;
        const expired = expires < new Date().getTime();
        // Only GET if we are not currently loading and the content is expired or the data is null.
        should = !loading && (expired || !data);
      }
    }

    if (!should && eventTarget) {
      eventTarget.dispatchEvent(new CustomEvent(EVENT.VALET_FETCH_SKIPPED));
    }

    if (autoUpdateWorkflows) {
      const workflowEnforcer = createWorkflowEnforcer(getState);
      const enforceUrl = workflowEnforcer();
      if (enforceUrl) {
        createRedirector(history)(enforceUrl);
      }
    }

    return should;
  };

  const perform = (state, action) => {
    switch (action.type) {
      case GET:
        return Object.assign({}, state, { loading: true, error: '' });
      case ERROR:
        return Object.assign({}, state, { loading: false, error: action.payload });
      case SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          expires: new Date().getTime() + msCacheDuration,
          error: '',
          data: action.payload,
        });
      case RESET:
        return fullInitialState;
      case EXPIRE:
        return Object.assign({}, state, { expires: 0 });
      default:
        return state;
    }
  };

  return {
    shouldGet,
    perform,
    constants: { GET, ERROR, SUCCESS, RESET, EXPIRE },
    initialState: fullInitialState,
  };
};
