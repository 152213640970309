import { createSelector } from 'reselect';
import moment from 'moment';

import selectUser from './selectUser';
import selectLastAuth from './selectLastAuth';
import selectIsUserLoading from './selectIsUserLoading';

const expired = expires => expires >= moment().subtract(20, 'minutes').toDate();

const selectIsAuthenticated = createSelector(
  selectIsUserLoading,
  selectUser,
  selectLastAuth,
  state => state.user.expires,
  (loading, user, auth, expires) => !loading && !!user.id && !!auth && expired(expires),
);

export default selectIsAuthenticated;
