import { createSelector } from 'reselect';

import { Format, Parse } from '~/common/data-tada';

import selectAllSuffixes from './selectAllSuffixes';

const parse = {
  float: Parse.number({ required: true })
    .catch(Format.number.zero)
    .value.resolved(),
  date: Parse.date({ required: true })
    .catch(Format.date.invalid)
    .then(Format.date.date)
    .value.resolved(),
};

const createSuffixSelector = () => {
  const exists = (v, s) => v !== undefined && v !== null; // eslint-disable-line no-unused-vars
  const greaterThanZero = (v, s) => v > 0; // eslint-disable-line no-unused-vars
  const past = (v, s) => !!v && v < new Date(); // eslint-disable-line no-unused-vars
  const isTrue = (v, s) => v === true; // eslint-disable-line no-unused-vars

  const FILTER = {
    types: (...types) => s => [].concat(...types).some(t => s.type === t),
    entitlement: (entitlement, condition = exists) => s => condition(s.entitlements[entitlement], s),
    balance: (condition = greaterThanZero) => s => condition(parse.float(s.balance), s),
    paymentDue: (condition = past) => s => condition(parse.date(s.paymentDueDate), s),
    crossAccount: (condition = exists) => s => condition(s.accountNumber, s),
    isValidEntProtectAccount: (condition = isTrue) => s => condition(s.isValidEntProtectAccount, s),
  };
  const not = f => s => !f(s);

  const filters = [];

  const select = createSelector(
    selectAllSuffixes,
    suffixes => suffixes.filter(s => filters.every(f => f(s)))
  );

  const addFilter = f => filters.push(f) && select;

  select.filter = f => addFilter(f);
  select.not = {};
  select.not.filter = f => addFilter(not(f));

  Object.keys(FILTER).forEach(k => {
    const f = FILTER[k];
    select[k] = (...args) => addFilter(f(...args));
    select.not[k] = (...args) => addFilter(not(f(...args)));
  });

  return select;
};

export default createSuffixSelector;
