import { apiProvider } from '@ent/browser';

import { selectRequestTokens, selectCsrfToken } from '~/store/user';

import { GET_INTERNAL_ACCOUNTS } from '../constants';

const getInternalAccounts = ({ autoPublishMessages } = {}) => async (dispatch, getState) => {
  const state = getState();
  const csrfToken = selectCsrfToken(state);
  const requestTokens = selectRequestTokens(state);

  const { accounts: internalAccounts } = await apiProvider({
    method: 'GET',
    url: '/Banking/api/transfers/accounts/internal',
    csrfToken,
    requestTokens,
    autoPublishMessages,
  });

  dispatch({ type: GET_INTERNAL_ACCOUNTS, payload: internalAccounts });
};

export default getInternalAccounts;
