import { createSelector } from 'reselect';

import selectIsProduction from './selectIsProduction';

const selectSmartyStreetsKey = createSelector(
  selectIsProduction,
  prod => (prod ? '7933832100369514' : '7933829571150109')
);

export default selectSmartyStreetsKey;
