import React from 'react';

import PrintContext from './PrintContext';

// prettier-ignore
export default () => Component => props => (
  <PrintContext.Consumer>
    {({ Image }) => <Component {...props} Image={Image} />}
  </PrintContext.Consumer>
);
