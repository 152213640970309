import { createValet } from '~/common/redux/valet';
import history from '~/store/history';
import * as CONST from '~/store/user/constants';

import initialState from './userInitialState';

const userValet = createValet({
  defaultReducer: 'user',
  history,
  initialState,
  msCacheDuration: 20 * 60 * 1000, // 20 minutes
  GET: CONST.USER_GET,
  ERROR: CONST.USER_GET_ERROR,
  SUCCESS: CONST.USER_GET_SUCCESS,
  RESET: CONST.USER_RESET,
  EXPIRE: CONST.USER_EXPIRE,
});

export default userValet;
