import React from 'react';
import moment from 'moment';

const parseAndFormat = format => value => {
  const dt = moment(value, [format, 'MM/DD/YYYY', moment.ISO_8601]);
  return dt.isValid() ? dt.format(format) : '';
};

// prettier-ignore
/* eslint-disable react/jsx-one-expression-per-line */
export default {
  DD: parseAndFormat('DD'),
  MMM: parseAndFormat('MMM'),
  YYYYMMDD: parseAndFormat('YYYYMMDD'),
  'MM/YY': parseAndFormat('MM/YY'),
  'MM/DD': parseAndFormat('MM/DD'),
  'MM/DD/YYYY': parseAndFormat('MM/DD/YYYY'),
  'MM/DD/YYYY h:mm A': parseAndFormat('MM/DD/YYYY h:mm A'),
  'MMM Do, YYYY': parseAndFormat('MMM Do, YYYY'),
  'MMMM Do, YYYY': parseAndFormat('MMMM Do, YYYY'),
  'MMM D, YYYY': parseAndFormat('MMM D, YYYY'),
  'MMM DD': parseAndFormat('MMM DD'),
  rich: value => {
    const dt = moment(value, moment.ISO_8601);
    if (!value || !dt.isValid()) return '';
    return (
      <span>
        {dt.format('MM/DD/YYYY h:mm')}&nbsp;{dt.format('A')}
      </span>
    );
  },
  day: value => {
    const oneWeek = moment().startOf('day').add(1, 'week');
    const oneWeekPrior = moment().startOf('day').subtract(1, 'week');
    const today = moment().startOf('day');
    const tomorrow = moment().startOf('day').add(1, 'day');
    const dt = moment(value, moment.ISO_8601);

    if (dt.isValid()) {
      if (dt.isSame(today, 'day')) {
        return `Today, ${dt.format('MMMM DD')}`;
      }
      if (dt.isSame(tomorrow, 'day')) {
        return `Tomorrow, ${dt.format('MMMM DD')}`;
      }
      if (dt.isSameOrBefore(oneWeek, 'day') && dt.isSameOrAfter(oneWeekPrior, 'day')) {
        return dt.format('dddd');
      }
      return dt.format('MMMM Do');
    }

    return '';
  },
};
