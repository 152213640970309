import { fromEvent } from 'rxjs';
import { EVENT } from '@ent/browser';

import { parseWorkflows } from '~/common/parseWorkflows';
import { createRedirector } from '~/common/createRedirector';
import { createWorkflowEnforcer } from '~/common/createWorkflowEnforcer';
import { history } from '~/store';

import { setWorkflow } from '../actions';
import { selectWorkflows } from '../selectors';

const defaultObserver = fromEvent(window, EVENT.API_RESPONSE);

export default (store, observer = defaultObserver) => {
  const workflowEnforcer = createWorkflowEnforcer(store.getState);

  return observer.subscribe(({ response: { workflows, parser = parseWorkflows } = {} } = {}) => {
    if (store && workflows) {
      const { queue: oldQueue, finishUrl } = selectWorkflows(store.getState());
      const newQueue = parser(workflows);

      if (JSON.stringify(oldQueue) !== JSON.stringify(newQueue)) {
        store.dispatch(
          setWorkflow({
            queue: newQueue,
            finishUrl: finishUrl || '/Banking/Home.aspx',
          })
        );

        const enforceUrl = workflowEnforcer();
        if (enforceUrl) {
          createRedirector(history)(enforceUrl);
        }
      }
    }
  });
};
