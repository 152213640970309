import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { apiProvider } from '@ent/browser';

import decorator from './decorators';

export default () => BaseComponent => {
  class WithApi extends Component {
    /**
     * Calls the apiProvider with tokens.
     * requestToken is an optional API_REQUEST_TOKEN constant
     */
    api = ({ requestToken, ...other }) => {
      // TODO: Better define role and responsibility of this guy.
      // (e.g. where are requestTokens stored? redux? here? How?)
      const { requestTokens, csrfToken } = this.props;
      return apiProvider({
        csrfToken,
        requestToken: requestTokens[requestToken],
        ...other,
      });
    };

    render() {
      const { tokens, ...other } = this.props;
      return <BaseComponent api={this.api} {...other} />;
    }
  }

  WithApi.propTypes = {
    // connect
    csrfToken: PropTypes.string.isRequired,
    requestTokens: PropTypes.object.isRequired,
  };

  WithApi.defaultProps = {};

  WithApi.displayName = `WithApi(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return decorator(WithApi);
};
