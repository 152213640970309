import { apiProvider } from '@ent/browser';

import * as CONST from '~/store/sms/constants';
import valet from '~/store/sms/valets/smsValet';

const getSms = ({ force, state, reducer = 'cards', mockProvider = null, autoUpdateWorkflows = true } = {}) => (
  dispatch,
  getState
) => new Promise(resolve => {
  const should = valet.shouldGet({ force, state, reducer, getState, autoUpdateWorkflows });
  if (!should) {
    return resolve();
  }
  dispatch({ type: CONST.SMS_GET });

  const request = { url: '/Banking/api/sms/phones', autoUpdateWorkflows };
  const success = data => dispatch({ type: CONST.SMS_GET_SUCCESS, payload: data });
  const failure = error => dispatch({ type: CONST.SMS_GET_ERROR, payload: error.message });

  const provider = mockProvider || apiProvider;
  const result = provider(request)
    .then(success)
    .catch(failure);
  return resolve(result);
});

export default getSms;
