import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { identity } from '@ent/functional';

import { MFA_METHODS } from '~/constants';

// prettier-ignore
const MfaGridButtonMultipleOptions = ({
  mode,
  options,
  format,
  focusHandler,
}) => (mode === MFA_METHODS.PUSH || options.length === 1 ? null : (
  <div className="select-container">
    <div>
      <Field name={`ids.${mode}`} component="select" className="form-control" onFocus={focusHandler}>
        {options.map(({ id, text }) => (
          <option key={id} value={id}>
            {format(text)}
          </option>
        ))}
      </Field>
    </div>
  </div>
));

MfaGridButtonMultipleOptions.propTypes = {
  mode: PropTypes.number.isRequired,
  options: PropTypes.array,
  format: PropTypes.func,
  focusHandler: PropTypes.func.isRequired,
};

MfaGridButtonMultipleOptions.defaultProps = {
  format: identity,
  options: [],
};

export default MfaGridButtonMultipleOptions;
