import { connect } from 'react-redux';

import { selectLastAuth } from '~/store/user';
import { selectWorkflows } from '~/store/workflow/selectors';

const select = state => ({ lastAuth: selectLastAuth(state), workflow: selectWorkflows(state) });

const boundActions = {};

export default connect(
  select,
  boundActions
);
