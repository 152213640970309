import { initCookies, removeCookie, selectCookies } from '~/store/cookie';
import { getUser, selectIsAuthenticated } from '~/store/user';
import { startInAuth } from '~/store/inauth';
import { selectForgotUsernameOrPasswordUrl } from '~/store/env';
import { selectInitialValues, selectQBEnabled, selectReturnUrl } from './selectors';

const select = state => ({
  authenticated: selectIsAuthenticated(state),
  cookies: selectCookies(state),
  initialValues: selectInitialValues(state),
  qbEnabled: selectQBEnabled(state),
  returnUrl: selectReturnUrl(state),
  forgotUrl: selectForgotUsernameOrPasswordUrl(state),
});

const boundActions = {
  getUser,
  initCookies,
  removeCookie,
  startInAuth,
};

export default [select, boundActions];
