import React from 'react';
import PropTypes from 'prop-types';

const EmptyTableRow = ({ colSpan, message }) => {
  if (message === null) {
    return null;
  }
  return (
    <tr>
      <td className="text-center" colSpan={colSpan}>
        {message}
      </td>
    </tr>
  );
};

EmptyTableRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};

EmptyTableRow.defaultProps = { message: 'Nothing to display' };

export default EmptyTableRow;
