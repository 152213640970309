import * as CONST from '../constants';
import initialState from './workflowInitialState';

const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.WORKFLOW_CLEAR:
      return state.queue.length && state.finishUrl ? initialState : state;
    case CONST.WORKFLOW_DEQUEUE: {
      const [, ...queue] = state.queue;
      return !state.queue.length
        ? state
        : {
          ...state,
          queue,
        };
    }
    case CONST.WORKFLOW_ENQUEUE:
      return {
        ...state,
        queue: [...state.queue, action.payload.item],
      };
    case CONST.WORKFLOW_SET: {
      const { queue, finishUrl } = action.payload;
      return {
        ...state,
        queue,
        finishUrl,
      };
    }
    default:
      return state;
  }
};

export default workflowReducer;
