import * as CONST from '~/store/user/constants';
import valet from '~/store/user/valets/userValet';
import initialState from '~/store/user/valets/userInitialState';

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.USER_AUTHENTICATED:
      return {
        ...state,
        lastAuth: new Date().getTime(),
      };
    case CONST.USER_GET_ERROR: {
      const curState = ['401', '403'].some(s => (action.payload || '').startsWith(s)) ? initialState : state;
      return {
        ...curState,
        loading: false,
        error: action.payload,
      };
    }
    case CONST.USER_GET_SUCCESS: {
      const valetState = valet.perform(state, action);
      return {
        ...valetState,
        lastAuth: !state.lastAuth ? new Date().getTime() : state.lastAuth,
      };
    }
    default:
      return valet.perform(state, action);
  }
};

export default userReducer;
