import { createSelector } from 'reselect';

import selectInternalAccounts from './selectInternalAccounts';
import selectExternalAccounts from './selectExternalAccounts';

// prettier-ignore
export default createSelector(
  selectInternalAccounts,
  selectExternalAccounts,
  (internalAccounts, externalAccounts) => [
    ...internalAccounts,
    ...externalAccounts,
  ]
);
