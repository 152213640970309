import React from 'react';
import PropTypes from 'prop-types';

import Suffix from './Suffix';

const Suffixes = ({ suffixes, loadSuffix }) => (
  <div className="Suffixes">{suffixes.map(s => <Suffix key={s.id} loadSuffix={loadSuffix} {...s} />)}</div>
);

Suffixes.propTypes = {
  suffixes: PropTypes.array.isRequired,
  loadSuffix: PropTypes.func.isRequired,
};

Suffixes.defaultProps = {};

export default Suffixes;
