import { createSelector } from 'reselect';

import selectUser from './selectUser';

const selectIsAccountType = (...types) => createSelector(
  selectUser,
  user => types.some(t => t === user.accountType)
);

export default selectIsAccountType;
