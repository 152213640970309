import { createSelector } from 'reselect';

import selectEnv from './selectEnv';

const selectForgotUsernameOrPasswordUrl = createSelector(
  selectEnv,
  env => env.forgotUsernameOrPasswordUrl || '',
);

export default selectForgotUsernameOrPasswordUrl;
