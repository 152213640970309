import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AddressDialog from './AddressDialog';
import decorator from './decorators';

const withValidAddress = () => BaseComponent => {
  class WithValidAddress extends Component {
    /* NOTE: This function returns a promise.
     * When the promise resolves it returns the selected address object for the update (or null if there is no change).
     * It is resolved when:
     *   1. fetchAddress resolves and returns the result (even if it could not match the address) AND EITHER:
     *        a. The 'Suggested' address is a direct match.
     *        b. The user selects either the 'Suggested' or the 'As-Is' address from the dialog.
     *   2. fetchAddress rejects (timeout or server returned a non-200 response)
     * It is rejected when:
     *   1. The user selected to 'Re-enter' their address.
     */
    validateAddress = address => {
      const { fetchAddress, selectAddressIssues, selectAddress } = this.props;

      return new Promise((resolve, reject) => {
        const failOpen = () => resolve();

        fetchAddress(address)
          // eslint-disable-next-line consistent-return
          .then(resp => {
            const newAddress = selectAddress(resp);
            const issues = selectAddressIssues(resp);
            if (!issues.length) {
              return resolve(newAddress);
            }

            const eventHandler = fn => (...args) => event => {
              event.nativeEvent.preventDefault();
              this.dialog = null;
              this.forceUpdate();
              fn(...args);
            };
            const accept = eventHandler(resolve);
            const cancel = eventHandler(reject);

            this.dialog = (
              <AddressDialog
                issues={issues}
                suggested={newAddress}
                original={address}
                suggestedHandler={accept(newAddress)}
                originalHandler={accept(null)}
                cancelHandler={cancel(null)} />
            );
            this.forceUpdate();
          })
          .catch(failOpen);
      });
    };

    render() {
      return (
        <Fragment>
          {this.dialog}
          <BaseComponent validateAddress={this.validateAddress} {...this.props} />
        </Fragment>
      );
    }
  }

  WithValidAddress.propTypes = {
    // withSmartyStreets
    fetchAddress: PropTypes.func.isRequired,
    selectAddress: PropTypes.func.isRequired,
    selectAddressIssues: PropTypes.func.isRequired,
  };

  WithValidAddress.defaultProps = {};

  WithValidAddress.displayName = `WithValidAddress(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return decorator(WithValidAddress);
};

export default withValidAddress;
