import peekWorkflow from '~/store/workflow/selectors/peekWorkflow';

const unenforcedUrls = ['/Banking/Logoff.aspx'].map(s => s.toLowerCase());

const defaultGetPath = () => document.location.pathname;

export default (getState, getPath = defaultGetPath) => () => {
  const path = (getPath() || '').toLowerCase();
  if (!unenforcedUrls.includes(path)) {
    const state = getState();
    const workflow = peekWorkflow(state);
    if (workflow) {
      const urls = workflow.urls || [];
      const valid = urls.some(url => (url || '').toLowerCase() === path);
      return valid ? undefined : urls[0];
    }
  }
  return undefined;
};
