import React from 'react';
import PropTypes from 'prop-types';
import { identity } from '@ent/functional';

import { MFA_METHODS } from '~/constants';

// prettier-ignore
const MfaGridButtonSingleOption = ({
  mode,
  options,
  format,
}) => (mode === MFA_METHODS.PUSH || options.length > 1 ? null : (
  <div>{format(options[0].text)}</div>
));

MfaGridButtonSingleOption.propTypes = {
  mode: PropTypes.number.isRequired,
  options: PropTypes.array,
  format: PropTypes.func,
};

MfaGridButtonSingleOption.defaultProps = {
  format: identity,
  options: [],
};

export default MfaGridButtonSingleOption;
