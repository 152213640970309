import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PageLink from './PageLink';

class PageRow extends Component {
  first = () => {
    const { setPage } = this.props;
    setPage(0);
  };

  prev = () => {
    const { setPage, curPage } = this.props;
    setPage(curPage - 1);
  };

  next = () => {
    const { setPage, curPage } = this.props;
    setPage(curPage + 1);
  };

  last = () => {
    const { setPage, maxPage } = this.props;
    setPage(maxPage);
  };

  render() {
    const { curPage, maxPage } = this.props;

    /* eslint-disable react/jsx-one-expression-per-line */
    return (
      <div className="text-center">
        <nav>
          <ul className="pagination" data-autoid="Pagination">
            <PageLink text="First" icon="fast-backward" onClick={this.first} />
            <PageLink text="Previous" icon="step-backward" onClick={this.prev} />
            <li className="disabled" data-autoid="PageIndex">
              <span>
                page {curPage + 1} of {maxPage + 1}
              </span>
            </li>
            <PageLink text="Next" icon="step-forward" onClick={this.next} />
            <PageLink text="Last" icon="fast-forward" onClick={this.last} />
          </ul>
        </nav>
      </div>
    );
  }
}

PageRow.propTypes = {
  setPage: PropTypes.func.isRequired,
  curPage: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
};

PageRow.defaultProps = {};

export default PageRow;
