import { createSelector } from 'reselect';

import userInitialState from '~/store/user/valets/userInitialState';

const selectIsInitialState = state => (state.user === userInitialState);
const selectIsLoading = state => state.user.loading;

const selectIsUserLoading = createSelector(
  selectIsInitialState,
  selectIsLoading,
  (initial, loading) => initial || loading,
);

export default selectIsUserLoading;
