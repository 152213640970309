import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Disclosure } from '~/components/Disclosure';

import decorator from './decorators';

export default ({ disclosure }) => BaseComponent => {
  class RequireDisclosure extends Component {
    constructor(props) {
      super(props);
      this.state = { accepted: props.userAccepted };
      props.getUser({ redirectOn403: false });
    }

    componentWillReceiveProps(nextProps) {
      const { userAccepted } = this.props;
      if (!userAccepted && nextProps.userAccepted) {
        this.setState({ accepted: true });
      }
    }

    // eslint-disable-next-line no-unused-vars
    accept = async values => {
      const { api, authenticated, addMessage, getUser } = this.props;

      if (!authenticated) {
        this.afterAccept();
        return undefined;
      }

      try {
        await api({
          url: `/Banking/api/user/disclosure/${disclosure}`,
          method: 'POST',
          data: null,
        });
        const complete = () => {
          getUser({ force: true });
          this.afterAccept();
        };
        setTimeout(complete, 0);
      } catch (e) {
        addMessage({ text: e.message });
      }

      return undefined;
    };

    afterAccept() {
      this.setState({ accepted: true });
    }

    render() {
      const { accepted } = this.state;
      return accepted ? (
        <BaseComponent {...this.props} />
      ) : (
        <div className="col-xs-12 col-lg-6">
          <Disclosure disclosure={disclosure} acceptHandler={this.accept} />
        </div>
      );
    }
  }

  RequireDisclosure.propTypes = {
    // connect
    authenticated: PropTypes.bool.isRequired,
    userAccepted: PropTypes.bool.isRequired,
    getUser: PropTypes.func.isRequired,
    // withApi
    api: PropTypes.func.isRequired,
    // withMessages
    addMessage: PropTypes.func.isRequired,
  };

  RequireDisclosure.defaultProps = {};

  const componentName = BaseComponent.displayName || BaseComponent.name || 'Component';
  RequireDisclosure.displayName = `RequireDisclosure(${componentName})`;

  return decorator(RequireDisclosure);
};
