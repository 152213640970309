import { combineReducers } from 'redux';
import { reducer as pagestate } from '@ent/redux-pagestate';
import { connectRouter } from 'connected-react-router';

import { accountHistoryReducer as accountHistory } from './account-history';
import { accountOpenReducer as accountOpen } from './account-open';
import { cardsReducer as cards } from './cards';
import { cardnavDestinationsReducer as cardnavDestinations } from './cardnav-destinations';
import { cardnavReducer as cardnav } from './cardnav';
import { cookieReducer as cookie } from './cookie';
import { creditCardsReducer as creditCardData } from './credit-cards';
import { envReducer as env } from './env';
import { inAuthReducer as inauth } from './inauth';
import { messagesReducer as messages } from './messages';
import { sessionReducer as session } from './session';
import { smsReducer as sms } from './sms';
import { suffixesReducer as suffixes } from './suffixes';
import { tableReducer as table } from './table';
import { testReducer as test } from './testing';
import { transferReducer as transfer } from './transfer';
import { uiReducer as ui } from './ui';
import { userProfileReducer as userProfile } from './user-profile';
import { userReducer as user } from './user';
import { workflowReducer as workflow } from './workflow';

const createRootReducer = history => combineReducers({
  accountHistory,
  accountOpen,
  cards,
  cardnav,
  cardnavDestinations,
  cookie,
  creditCardData,
  env,
  inauth,
  messages,
  pagestate,
  session,
  sms,
  suffixes,
  table,
  test,
  transfer,
  ui,
  userProfile,
  user,
  workflow,
  router: connectRouter(history),
});

export default createRootReducer;
