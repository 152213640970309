import * as CONST from '~/store/ui/constants';

const toggleSideNav = showSideNav => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.UI_TOGGLE_SIDE_NAV,
    payload: showSideNav,
  })
);

export default toggleSideNav;
