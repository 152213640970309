const includeGoogleTagManager = ({ dataLayer = 'dataLayer', containerId, isProd }) => {
  // https://developers.google.com/tag-manager/quickstart
  /* eslint-disable */
  // prettier-ignore
  (function (w, d, s, l, i, p) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js', });
    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    var env = p ? '' : '&gtm_auth=ZHfK2ftDRpr8sLbAzy04Tw&gtm_preview=env-412&gtm_cookies_win=x';
    j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl + env;
    f && f.parentNode && f.parentNode.insertBefore(j, f);
  })(window, document, 'script', dataLayer, containerId, isProd);
  /* eslint-enable */
};

export default includeGoogleTagManager;
