import React from 'react';
import { Route, Switch } from 'react-router';

import { device } from '@ent/browser';

import Pages from './pages';

const { isIE } = device;

const routes = [
  ['/Banking/app/open-account', false, Pages.AccountOpening],
  ['/Banking/app/accounts', true, Pages.AccountList],
  ['/Banking/app/accounts/favorites', true, Pages.AccountFavorites],
  ['/Banking/app/accounts/detail', true, Pages.AccountDetails],
  ['/Banking/app/accounts/detail/print', true, Pages.AccountDetailsPrint],
  ['/Banking/app/accounts/detail/transaction/print', true, Pages.AccountDetailsTransactionsPrint],
  ['/Banking/app/accounts/detail/checks/print', true, Pages.AccountDetailsCheckPrint],
  ['/Banking/app/accounts/detail/loan-payoff', true, Pages.AccountDetailsLoanPayoff],
  ['/Banking/app/accounts/detail/loan-payoff/print', true, Pages.AccountDetailsLoanPayoffPrint],
  ['/Banking/app/accounts/detail/escrow', true, Pages.MortgageEscrowVideo],
  ['/Banking/app/accounts/transaction', true, Pages.AccountTransactionDetails],
  ['/Banking/app/accounts/transaction/print', true, Pages.AccountTransactionDetailsPrint],
  ['/Banking/app/accounts/check/print', true, Pages.AccountTransactionCheckPrint],
  ['/Banking/app/billpay', false, isIE ? Pages.BillPayIERedirect : Pages.BillPay],
  ['/Banking/app/broncos-checking/conversion', true, Pages.BroncosCheckingConversion],
  ['/Banking/app/certificate/dividend', true, Pages.CertificateDividendOptions],
  ['/Banking/app/certificate/maturity', true, Pages.CertificateMaturityOptions],
  ['/Banking/app/contact', true, Pages.ContactUs],
  ['/Banking/app/credit-card/detail', true, Pages.CreditCardDetail],
  ['/Banking/app/locations', true, Pages.Locations],
  ['/Banking/app/login', true, Pages.LoginUsername],
  ['/Banking/app/login/password', true, Pages.LoginPassword],
  ['/Banking/app/login/tether', true, Pages.LoginTether],
  ['/Banking/app/login/success', true, Pages.LoginSuccess],
  ['/Banking/app/access-denied', true, Pages.AccessDenied],
  ['/Banking/app/activate-card', true, Pages.CardActivateUnauthenticated],
  ['/Banking/app/activate-card/success', true, Pages.CardActivateUnauthenticatedSuccess],
  ['/Banking/app/loan-application/life-happens', true, Pages.LifeHappens],
  ['/Banking/app/loan-application/continue', true, Pages.LoanApplicationContinue],
  ['/Banking/app/loan-application/new', true, Pages.LoanApplicationNew],
  ['/Banking/app/loan-application/select', true, Pages.LoanApplicationSelect],
  ['/Banking/app/loan-application/contact-info', true, Pages.AddressPhone],
  ['/Banking/app/loan-pay', true, Pages.LoanPayUnauthSplash],
  ['/Banking/app/loan-pay/payment', true, Pages.LoanPayUnauthPayment],
  ['/Banking/app/loan-pay/success', true, Pages.LoanPayUnauthSuccess],
  ['/Banking/app/loan-pay/verify', true, Pages.LoanPayUnauthVerify],
  ['/Banking/app/disclosure/:disclosure', true, Pages.Disclosure],
  ['/Banking/app/forgot-password', true, Pages.ForgotPassword],
  ['/Banking/app/forgot-password/complete', true, Pages.ForgotPasswordComplete],
  ['/Banking/app/member-ambassador', true, Pages.MemberAmbassador],
  ['/Banking/app/mfa', true, Pages.MfaChallenge],
  ['/Banking/app/mfa/call', true, Pages.MfaCall],
  ['/Banking/app/mfa/sms', true, Pages.MfaSms],
  ['/Banking/app/mfa/push', true, Pages.MfaPush],
  ['/Banking/app/mfa/email', true, Pages.MfaEmail],
  ['/Banking/app/mfa/fail', true, Pages.MfaFail],
  ['/Banking/app/mfa/success', true, Pages.MfaSuccess],
  ['/Banking/app/opt-in/phone', true, Pages.OptInPhoneInterstitial],
  ['/Banking/app/opt-in/address-phone', true, Pages.AddressPhone],
  ['/Banking/app/promotion/skip-a-pay', true, Pages.LoanSkipPay],
  ['/Banking/app/promotions', true, Pages.Promotions],
  ['/Banking/app/prequal/loan', true, Pages.LoanPreQual],
  ['/Banking/app/services', true, Pages.Services],
  ['/Banking/app/services/order-checks', true, Pages.OrderChecks],
  ['/Banking/app/services/stop-payment', true, Pages.StopPayment],
  ['/Banking/app/services/text-banking', true, Pages.TextBanking],
  ['/Banking/app/services/text-banking/enroll', true, Pages.TextBankingEnroll],
  ['/Banking/app/services/text-banking/unenroll', true, Pages.TextBankingUnenroll],
  ['/Banking/app/services/overdraft', true, Pages.Overdraft],
  ['/Banking/app/services/downloads', true, Pages.Downloads],
  ['/Banking/app/services/tax-payments', true, Pages.TaxPayments],
  ['/Banking/app/services/quick-balance', true, Pages.QuickBalance],
  ['/Banking/app/services/cards', true, Pages.Cards],
  ['/Banking/app/services/cards/activate', true, Pages.CardActivateAuthenticated],
  ['/Banking/app/services/cards/alerts', true, Pages.CardAlerts],
  ['/Banking/app/services/cards/freeze', true, Pages.CardFreeze],
  ['/Banking/app/services/cards/pin/change', true, Pages.CardPinChange],
  ['/Banking/app/services/cards/travel/edit', true, Pages.CardTravelNoteEdit],
  ['/Banking/app/services/cards/unfreeze', true, Pages.CardUnfreeze],
  ['/Banking/app/services/cards/restriction', true, Pages.CardRestriction],
  ['/Banking/app/services/cards/spending-limit', true, Pages.CardSpendingLimit],
  ['/Banking/app/services/desktop-deposit-checks', true, Pages.DesktopDepositChecks],
  ['/Banking/app/services/ent-protect', true, Pages.EntProtectProductPage],
  ['/Banking/app/profile', true, Pages.Profile],
  ['/Banking/app/profile/call-center-password', true, Pages.CallCenterPassword],
  ['/Banking/app/profile/address-phone', true, Pages.AddressPhone],
  ['/Banking/app/profile/contact-info', true, Pages.ContactInfo],
  ['/Banking/app/profile/security-profile', true, Pages.SecurityProfile],
  ['/Banking/app/profile/email', true, Pages.Email],
  ['/Banking/app/profile/password', true, Pages.Password],
  ['/Banking/app/profile/user-activity', true, Pages.UserActivity],
  ['/Banking/app/profile/username', true, Pages.Username],
  ['/Banking/app/profile/users', true, Pages.UsersList],
  ['/Banking/app/profile/users/edit', true, Pages.UserEdit],
  ['/Banking/app/setup/address-phone', true, Pages.AddressPhone],
  ['/Banking/app/setup/contact-info', true, Pages.ContactInfo],
  ['/Banking/app/setup/email', true, Pages.Email],
  ['/Banking/app/setup/password', true, Pages.Password],
  ['/Banking/app/setup/security-profile', true, Pages.SecurityProfile],
  ['/Banking/app/statements', true, Pages.Statements],
  ['/Banking/app/statements/preferences', true, Pages.StatementPreferences],
  ['/Banking/app/finances', true, Pages.Finances],
  ['/Banking/app/finances/about', true, Pages.FinancesAbout],
  ['/Banking/app/finances/configure', true, Pages.FinancesConfigure],
  ['/Banking/app/transfer/loan', true, Pages.TransferLoan],
  ['/Banking/app/transfer/loan/accounts', true, Pages.TransferLoanAccount],
  ['/Banking/app/transfer/loan/accounts/external/edit', true, Pages.TransferLoanAccountExternalEdit],
  ['/Banking/app/transfer/loan/accounts/external/verify', true, Pages.TransferLoanAccountExternalVerify],
  ['/Banking/app/transfer/loan/accounts/internal/edit', true, Pages.TransferLoanAccountInternalEdit],
  ['/Banking/app/transfer/loan/edit', true, Pages.TransferLoanEdit],
  ['/Banking/app/transfer/share', true, Pages.TransferShare],
  ['/Banking/app/transfer/share/accounts', true, Pages.TransferShareAccount],
  ['/Banking/app/transfer/share/accounts/external/edit', true, Pages.TransferShareAccountExternalEdit],
  ['/Banking/app/transfer/share/accounts/external/verify', true, Pages.TransferShareAccountExternalVerify],
  ['/Banking/app/transfer/share/accounts/internal/edit', true, Pages.TransferShareAccountInternalEdit],
  ['/Banking/app/transfer/share/edit', true, Pages.TransferShareEdit],
  ['/Banking/app/registration', true, Pages.Registration],
  ['/Banking/app/registration/complete', true, Pages.RegistrationComplete],
  ['/Banking/app/ent-protect/enroll', true, Pages.EntProtectEnroll],
  ['/Banking/app/ent-protect/enroll/confirmation', true, Pages.EntProtectEnrollConfirmation],
  ['/Banking/app/ent-protect/enroll/contact-info', true, Pages.AddressPhone],
  ['/Banking/app/ent-protect/information', true, Pages.EntProtectInformation],
  ['/Banking/app/migration/start', true, Pages.BackbaseMigrationVerify],
];

export default (
  <Switch>
    {routes.map(([path, exact, component]) => (
      <Route exact={exact} key={path} path={path} component={component} />
    ))}
    <Route component={Pages.Page} />
  </Switch>
);
