import { createSelector } from 'reselect';
import moment from 'moment';

import selectUser from './selectUser';
import selectIsAccountType from './selectIsAccountType';
import { ACCOUNT_TYPE } from '~/constants';

const minDate = moment().endOf('day').subtract(18, 'year');

const selectBusinessCorporate = selectIsAccountType(
  ACCOUNT_TYPE.BUSINESS,
  ACCOUNT_TYPE.CORPORATE
);

export default createSelector(
  selectUser,
  selectBusinessCorporate,
  (user, isBusinessOrCorporate) => {
    const { birthDate: birthDateStr } = user || {};
    const birthDate = moment(birthDateStr, 'YYYY-MM-DDThh:mm:ss').startOf('day');
    return !isBusinessOrCorporate && birthDateStr && birthDate.isValid() && birthDate.isAfter(minDate);
  }
);
