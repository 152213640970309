import { apiProvider } from '@ent/browser';

import * as CONST from '~/store/cardnav-destinations/constants';
import valet from '~/store/cardnav-destinations/valets/cardnavDestinationsValet';

let currentPromise = null;

export default ({
  force,
  state,
  reducer = 'cardnavDestinations',
  mockProvider = null,
  redirectOn403 = true,
  autoUpdateWorkflows = true,
} = {}) => (dispatch, getState) => {
  const should = valet.shouldGet({ force, state, reducer, getState, autoUpdateWorkflows });
  if (should) {
    const provider = mockProvider || apiProvider;

    const fetchData = () => provider({
      url: '/Banking/api/cardnav/destinations/list',
      redirectOn403,
      autoUpdateWorkflows,
    });

    const processData = ({ destinations }) => {
      dispatch({
        type: CONST.CARDNAV_DESTINATIONS_GET_SUCCESS,
        payload: destinations,
      });
      return destinations;
    };

    const handleError = ex => dispatch({
      type: CONST.CARDNAV_DESTINATIONS_GET_ERROR,
      payload: ex.message,
    });

    currentPromise = new Promise(resolve => resolve(dispatch({ type: CONST.CARDNAV_DESTINATIONS_GET })))
      .then(fetchData)
      .then(processData)
      .catch(handleError);
  }

  return currentPromise;
};
