// TODO: relocate me to be with the other transfers stuff
export const TRANSACTION_ACTION_CODE = {
  LOAN_ADD_ON: 'A',
  COMMENT: 'C',
  DEPOSIT: 'D',
  NEW_LOAN: 'N',
  LOAN_PAYMENT: 'P',
  LOAN_REFINANCE: 'R',
  WITHDRAWAL: 'W',
};

export const TRANSFER_ACCOUNT_GROUP_KEY = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  LOCKED: 'LOCKED',
};

export const TRANSFER_ACCOUNT_INTERNAL_DIRECTION = {
  UNKNOWN: 0,
  FROM: 1,
  TO: 2,
};

export const TRANSFER_ACCOUNT_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  UNLINKED: 'UNLINKED',
};

export const TRANSFER_ACCOUNT_EXTERNAL_STATUS = {
  UNKNOWN: 0,
  ACTIVE: 1,
  ACTIVE_DESTINATION_ONLY: 2,
  ACTIVE_SOURCE_ONLY: 3,
  PENDING: 4,
  LOCKED: 5,
  ERROR: 6,
};

export const TRANSFER_TYPE = {
  HOST: 0,
  RETAIL: 1,
  RETAIL_PROCESSED: 2,
};

export const TRANSFER_VIEW_TYPE = {
  COMBINED: 0,
  SHARE: 1,
  LOAN: 2,
};
