import qs from 'querystring';
import { memoize } from '@ent/functional';

const memoizeKey = args => JSON.stringify(args).toLowerCase();

const createFetchAddressSuggest = memoize(
  key => {
    let callCount = 0;

    const fetchAddressSuggest = memoize(
      ({ prefix, prefer = 'CO' }) => new Promise((resolve, reject) => {
        const url = `https://us-autocomplete.api.smartystreets.com/suggest?${qs.encode({
          'auth-id': key,
          prefix,
          prefer,
        })}`;

        fetch(url, { 'content-type': 'application/json' }).then(resp => {
          const { status, statusText } = resp;

          if (status === 200) {
            return resp.json().then(json => {
              callCount++;
              return resolve(json.suggestions);
            });
          }

          // eslint-disable-next-line prefer-promise-reject-errors
          return reject({ status, statusText });
        });

        // eslint-disable-next-line prefer-promise-reject-errors
        setTimeout(() => reject({ timeout: true }), 1500);
      }),
      { key: memoizeKey }
    );

    fetchAddressSuggest.logUsage = () => {
      if (callCount) {
        window.dataLayer.push({
          event: 'Custom Event',
          action: 'SmartyStreets: Suggest: Usage',
          value: callCount,
        });
        callCount = 0;
      }
    };

    return fetchAddressSuggest;
  },
  { ttl: 0 }
);

export default createFetchAddressSuggest;
