import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatter } from './parsers';
import HrefItem from './HrefItem';
import './styles.scss';

/* eslint-disable react/jsx-one-expression-per-line */
const SideNav = ({ user, primaryLinks, collapsed, currentPath }) => (
  <div className={`side-nav ${collapsed ? 'collapsed' : ''}`} data-autoid="side-navigation" role="navigation">
    <div className="links-list">
      {primaryLinks.map(({ id, Item, text, dataAutoId, icon, url }) => (
        <Item key={id} text={text} dataAutoId={dataAutoId} icon={icon} url={url} />
      ))}
    </div>
    <div className="links-list bottom">
      <HrefItem text="Contact Us" dataAutoId="contact-us" icon="fa-envelope-o fa-lg" url="/Banking/ViewMessages.aspx" />
      <HrefItem
        text={formatter.switch(user.accountNumber)}
        className="switch"
        icon="fa-random"
        dataAutoId="switch-accounts"
        url="/Banking/SwitchAccounts.aspx"
        currentPath={currentPath} />
    </div>
    {!!user && !!user.accountNumber && (
      <div className="messages" data-autoid="account-meta-data">
        <p>
          <span>Welcome</span>{' '}
          <span>
            {user.firstName} {user.middleName} {user.lastName}
          </span>
          <br />
          {!!user.lastLogIn && moment(user.lastLogIn).isValid() && (
            <span>Last Log On {moment(user.lastLogIn).format('MM/DD/YYYY h:mm a')}</span>
          )}
        </p>
      </div>
    )}
  </div>
);

SideNav.propTypes = {
  user: PropTypes.object,
  collapsed: PropTypes.bool,
  // react-redux
  primaryLinks: PropTypes.array.isRequired,
  currentPath: PropTypes.string.isRequired,
};

SideNav.defaultProps = { user: null, collapsed: false };

export default SideNav;
