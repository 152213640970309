import { connect } from 'react-redux';

import selectPrimaryLinks from './selectPrimaryLinks';

const select = state => ({ primaryLinks: selectPrimaryLinks(state) });

const boundActions = {};

export default connect(
  select,
  boundActions
);
