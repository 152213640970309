import { filter, take } from 'rxjs/operators';
import { ENT_MOBILE_ACTION } from '~/constants';
import { entMobile } from '~/common/entMobile';

export default async () => {
  const { dispatch, observable } = entMobile;

  let subscription;

  dispatch({ type: ENT_MOBILE_ACTION.INAUTH_START });

  // NOTE: Resolve the promise on the first INAUTH_COMPLETE event
  const event = await new Promise(resolve => {
    subscription = observable
      .pipe(
        filter(e => e.detail.type === ENT_MOBILE_ACTION.INAUTH_COMPLETE),
        take(1),
      )
      .subscribe(resolve);
  });

  if (subscription) subscription.unsubscribe();

  return event.detail.payload.payload;
};
