import * as CONST from '~/store/messages/constants';

const clearMessages = ({ all = false, owner = null, id = null }) => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.MESSAGES_CLEAR,
    payload: { all, owner, id },
  })
);

export default clearMessages;
