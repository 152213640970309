import { createSelector } from 'reselect';

import { CARDNAV_AUTH_ENABLED } from '~/constants/cardnav';

import selectSelectedCard from './selectSelectedCard';
import selectSelectedCardRestrictionBitmap from './selectSelectedCardRestrictionBitmap';

const getMax = card => {
  if (card && card.debitCard) return card.pointOfSaleLimit || 0;
  if (card && !card.debitCard) return card.atmUsageLimit || 0;
  return 0;
};

// prettier-ignore
export default createSelector(
  selectSelectedCard,
  selectSelectedCardRestrictionBitmap,
  (card, restrictions) => ({
    // eslint-disable-next-line no-bitwise
    enabled: !!(restrictions & CARDNAV_AUTH_ENABLED.TRANSACTION_THRESHOLD),
    value: CARDNAV_AUTH_ENABLED.TRANSACTION_THRESHOLD,
    amount: card && card.cardnav ? card.cardnav.controlPolicy.authMaxTxnAmount : 0,
    max: getMax(card),
  })
);
