import { identity } from '@ent/functional';

import { Storage } from '~/common/Storage';
import { STORAGE_PERSIST } from '../constants';

let persist = true;

export default ({ key = 'store', space = true, map = identity } = {}) => store => next => action => {
  const result = next(action);

  if (action.type === STORAGE_PERSIST) {
    const { storage, enabled } = action.payload;
    if (!storage || storage === 'local') {
      persist = enabled;
    }
  }

  if (persist) {
    Storage.local.setItem(key, JSON.stringify(map(store.getState()), null, space));
  }

  return result;
};
