import { formatter } from './parsers';

export default methodType => ({ pageState, redirect, setPageState }, { methods } = {}) => {
  if (methods) {
    setPageState({
      ...pageState,
      methods,
    });
    const method = methods.find(m => m.type === methodType);
    if (!method || (method.lockout && formatter.lockout(method.lockout))) {
      redirect('/Banking/app/mfa');
    }
  }
};
