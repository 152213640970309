import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { selectCurrentPage } from '~/store/sitemap';
import { selectUser } from '~/store/user';

export function getAppInsightsRef(instrumentationKey = window.APP_INSIGHTS_TOKEN) {
  if (!window.appInsightsRef && instrumentationKey) {
    window.appInsightsRef = new ApplicationInsights({ config: { instrumentationKey } });
    window.appInsightsRef.loadAppInsights();
  }
  return window.appInsightsRef;
}
export const includeAppInsights = (store, history, instrumentationKey) => {
  if (!instrumentationKey) {
    // eslint-disable-next-line no-console
    console.warn('ApplicationInsights missing instrumentation key. Failed to initialize.');
    return undefined;
  }
  const appInsightsRef = getAppInsightsRef(instrumentationKey);
  history.listen(() => {
    const state = store.getState();
    const { id: accountId } = selectUser(state) || {};
    const { title: name } = selectCurrentPage(state) || {};
    if (accountId) {
      appInsightsRef.setAuthenticatedUserContext(accountId.toString(), accountId.toString());
    }
    appInsightsRef.trackPageView({ name, url: document.location.href });
    appInsightsRef.trackPageViewPerformance({ name, url: document.location.href });
  });
  return appInsightsRef;
};
