import { createSelector } from 'reselect';

export default createSelector(
  state => state.device,
  device => (url, ...other) => {
    const win = window.open(url, ...other);
    if (!win && !device.isEntMobile) {
      // eslint-disable-next-line no-alert
      alert(
        [
          'Your browser is using a pop-up blocker.',
          "To proceed to our partner site, you will need to enable pop-ups in your browser's settings.",
        ].join(' ')
      );
    }
  }
);
