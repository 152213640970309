import React from 'react';

import { Help } from '~/common';

/* eslint-disable react/jsx-one-expression-per-line */
export default () => (
  <Help className="SecurePasswordHelp" title="Strong password help">
    <p>A strong password:</p>
    <ul>
      <li>
        Does not contain your name, account number or social security number
      </li>
      <li>Has not been compromised in a data breach</li>
    </ul>
  </Help>
);
