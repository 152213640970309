export default value => {
  const digits = (value || '').replace(/\D/g, '');
  if (digits.length === 7) {
    return `(719) ${digits.substr(0, 3)}-${digits.substr(3, 4)}`;
  }
  if (digits.length === 10) {
    return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-${digits.substr(6, 4)}`;
  }
  if (digits.length === 11 && digits[0] === '1') {
    return `(${digits.substr(1, 3)}) ${digits.substr(4, 3)}-${digits.substr(7, 4)}`;
  }
  return '';
};
