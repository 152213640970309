import { createSelector } from 'reselect';
import { device } from '@ent/browser';

import { SELECT_USER_ENTITLEMENT, SELECT_USER_ROLE } from '~/store/user';

import HrefItem from './HrefItem';
import LinkItem from './LinkItem';

const { BILL_PAY, SPENDING, STATEMENTS, TRANSFER, WIRES } = SELECT_USER_ENTITLEMENT;
const {
  CONSERVATOR,
  GOLDLEAF,
  IMPERSONATED,
  RESTRICT_BILL_PAY,
  NO_LOANS,
} = SELECT_USER_ROLE;

const selectPrimaryLinks = createSelector(
  BILL_PAY,
  SPENDING,
  STATEMENTS,
  TRANSFER,
  WIRES,
  CONSERVATOR,
  GOLDLEAF,
  IMPERSONATED,
  RESTRICT_BILL_PAY,
  NO_LOANS,
  (
    billPay,
    spending,
    statements,
    transfer,
    wires,
    conservator,
    goldleaf,
    impersonated,
    restrictBillPay,
    noLoans,
  ) => {
    const { isEntMobile: entMobile } = device;
    const items = [];

    let id = 0;
    const addItem = item => items.push({ id: ++id, ...item });

    addItem({
      Item: LinkItem,
      text: 'Accounts',
      dataAutoId: 'accounts',
      icon: 'fa-dollar fa-lg',
      url: '/Banking/app/accounts',
    });

    if (transfer && !conservator) {
      addItem({
        Item: LinkItem,
        text: 'Transfer',
        dataAutoId: 'transfer',
        icon: 'fa-exchange fa-lg',
        url: '/Banking/app/transfer/share',
      });
      if (!noLoans) {
        addItem({
          Item: LinkItem,
          text: 'Pay Loans',
          dataAutoId: 'pay-loan',
          icon: 'fa-car fa-lg',
          url: '/Banking/app/transfer/loan',
        });
      }
    }

    if (billPay && !conservator && !impersonated && !restrictBillPay) {
      addItem({
        Item: LinkItem,
        text: 'Pay Bills',
        dataAutoId: 'pay-bills',
        icon: 'fa-envelope fa-lg',
        url: '/Banking/app/billpay',
      });
    }

    addItem({
      Item: HrefItem,
      text: 'Deposit',
      dataAutoId: 'deposit',
      icon: 'fa-mobile-phone fa-lg',
      url: '/Banking/RDC/Default.aspx',
    });

    if (spending && !impersonated) {
      addItem({
        Item: LinkItem,
        text: 'Money Insight',
        dataAutoId: 'money-insight',
        icon: 'fa-pie-chart fa-lg',
        url: '/Banking/app/finances',
      });
    }

    addItem({
      Item: HrefItem,
      text: 'Alerts',
      dataAutoId: 'alerts',
      icon: 'fa-bell fa-lg',
      url: '/Banking/Alerts/Default.aspx',
    });

    addItem({
      Item: LinkItem,
      text: 'Services',
      dataAutoId: 'services',
      icon: 'fa-gear fa-lg',
      url: '/Banking/app/services',
    });

    if (wires) {
      addItem({
        Item: HrefItem,
        text: 'Wires',
        dataAutoId: 'wires',
        icon: 'fa-bank fa-lg',
        url: '/Banking/Wires/Default.aspx',
      });
    }

    if (goldleaf && !entMobile && !impersonated) {
      addItem({ Item: HrefItem, text: 'ACH', icon: 'fa-money fa-lg', url: '/Banking/ACH.aspx' });
    }

    if (statements) {
      addItem({
        Item: LinkItem,
        text: 'Statements',
        dataAutoId: 'statements',
        icon: 'fa-file-text fa-lg',
        url: '/Banking/app/statements',
      });
    }

    addItem({
      Item: HrefItem,
      text: 'Offers',
      dataAutoId: 'offers',
      icon: 'fa-user fa-lg',
      url: '/Banking/SpecialOffers.aspx',
    });

    return items;
  }
);

export default selectPrimaryLinks;
