import { connect } from 'react-redux';

import { selectRequestTokens, selectCsrfToken } from '~/store/user';

const select = state => ({
  csrfToken: selectCsrfToken(state),
  requestTokens: selectRequestTokens(state),
});

const boundActions = {};

export default connect(
  select,
  boundActions
);
