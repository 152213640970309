import { createSelector } from 'reselect';

import selectEnv from './selectEnv';

const selectIsProduction = createSelector(
  selectEnv,
  env => (env.serverMode === null ? (env.mode === 'production') : (env.serverMode === 4))
);

export default selectIsProduction;
