/* NOTE: This is a convenience function for applying an address update.
 * This function requires the Formik names match the address props (street, city, state, zip).
 */
export default (props, values) => async update => {
  if (update) {
    const { street, city, state, zip, zipPlus4 } = update;

    // Update the redux form
    await props.setFieldValue('street', street);
    await props.setFieldValue('city', city);
    await props.setFieldValue('state', state);
    await props.setFieldValue('zip', zip);
    await props.setFieldValue('zipPlus4', zipPlus4);

    // Update the current values
    /* eslint-disable no-param-reassign */
    values.street = street;
    values.city = city;
    values.state = state;
    values.zip = zip;
    values.zipPlus4 = zipPlus4;
    /* eslint-enable no-param-reassign */
  }
};
