import { selectUser } from '~/store/user';
import { format } from '~/common/format';

/* eslint-disable */
export const updateChatInformation = (userName, showChatBubble = false, name = '', email = '', phone = '') => {
  if (typeof(sm) === 'undefined') {
    includeChatBot();
    return;
  }

  const username = userName === undefined ? '' : `UN:${userName.toLowerCase()}`;

  sm.getApi({ version: 'v1' }).then(saleMove => {
    saleMove.updateInformation({
      customAttributesUpdateMethod: 'merge',
      customAttributes: {
        showChatBubble: `${showChatBubble}`,
        chatContext: 'olb-legacy',
        timeStamp: new Date().toISOString(),
      },
      name: name,
      externalId: username,
      email: email,
      phone: phone,
    });
  });
};

export const updateChatInformationFromUserObject = data => {
  const { username, showChatBubble, firstName, lastName, email, mobilePhone, mobilePhoneIsValid, homePhone, homePhoneIsValid } = data;
  const name = lastName === undefined || firstName === undefined ? '' : `${firstName} ${lastName}`;
  let phone = mobilePhoneIsValid ? mobilePhone : homePhoneIsValid ? homePhone : '';
  phone = format.phone(phone);
  updateChatInformation(username, showChatBubble, name, email, phone);
};

export const updateChatInformationFromStore = store => {
  if (typeof(store) === 'undefined') return;
  const state = store.getState();
  updateChatInformationFromUserObject(selectUser(state));
};

export const includeChatBot = store => {
  appendScript('glia', '/Banking/hlm/Ent.Responsive.Template/media/scripts/salemove_integration.js', () => updateChatInformationFromStore(store), 'head');
  setTimeout(() =>
    appendScript('gliaNavigation', '/Banking/hlm/Ent.Responsive.Template/media/scripts/glia-channel-events.js', null, 'body'), 1000);
};

const appendScript = (id, url, onload, location) => {
  if(document.getElementById(id) === null) {
    const scriptglia = document.createElement('script');
    scriptglia.type = 'text/javascript';
    scriptglia.async = true;
    scriptglia.src = url;
    scriptglia.setAttribute('id', id);
    if (onload) scriptglia.onload = onload;
    if (location === 'head') document.head.appendChild(scriptglia);
    else if (location === 'body') document.body.appendChild(scriptglia);
  }
}
