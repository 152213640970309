import { createSelector } from 'reselect';
import { selectPageState } from '@ent/redux-pagestate';
import qs from 'querystring';
import { identity } from '@ent/functional';

import { decrypt, format } from '~/common';
import { selectCookies } from '~/store/cookie';

export const selectQBEnabled = createSelector(
  selectCookies,
  selectPageState('login'),
  (cookies, pageState = {}) => {
    const { rememberMe, quickBalance } = cookies || {};
    return cookies ? !!rememberMe && !!quickBalance : pageState.quickBalance;
  }
);

export const selectReturnUrl = createSelector(identity, state => {
  try {
    return qs.parse(state.router.location.search.slice(1)).returnUrl || '';
  } catch (e) {
    return '';
  }
});

export const selectInitialValues = createSelector(selectCookies, cookies => {
  const { hash, rememberMe, quickBalance } = cookies || {};
  const username = decrypt(hash, rememberMe);
  return {
    username: {
      display: format.maskMiddle(username),
      value: username || '',
    },
    rememberMe: !!username,
    quickBalance: !!quickBalance,
  };
});
