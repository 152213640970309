import { log } from '~/common/log';
import { selectUser } from '~/store/user';

import { ACTIONS } from '../constants';

const createTest = (name, variant, subVariant, status) => (dispatch, getState) => {
  const user = selectUser(getState());
  const { accountNumber, userId } = user || {};

  log({
    category: 7, // audit
    message: 'ABTEST CREATE',
    details: JSON.stringify({
      name,
      variant,
      subVariant,
      account: accountNumber,
      userId,
    }),
  });

  dispatch({
    type: ACTIONS.CREATE_TEST,
    payload: {
      name,
      variant,
      subVariant,
      status,
    },
  });
};

export default createTest;
