import React from 'react';
import PropTypes from 'prop-types';

function expandTelephoneNumbers(value) {
  // NOTE: `###-###-####` or `(###) ###-####`
  const regTel = /(\(\d{3}\) |\d{3}-)\d{3}-\d{4}/g;
  return value.replace(regTel, (match, group, index) => {
    // Do not match tel:{number} because it is already a hyperlink
    if (value.substr(index - 4, 4) === 'tel:') {
      return match;
    }
    // Do not match {number}</a> because it is already a hyperlink
    if (value.substr(index + match.length, 4) === '</a>') {
      return match;
    }
    return `<a href="tel:${match.replace(/\D/g, '')}">${match}</a>`;
  });
}

const ExpandTelephoneNumber = ({ text }) => (typeof text === 'string' ? (
// eslint-disable-next-line react/no-danger
  <span dangerouslySetInnerHTML={{ __html: expandTelephoneNumbers(text) }} />
) : (
  <span>{text}</span>
));

ExpandTelephoneNumber.propTypes = { text: PropTypes.node.isRequired };

ExpandTelephoneNumber.defaultProps = {};

export default ExpandTelephoneNumber;
