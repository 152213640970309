import { connect } from 'react-redux';
import { compose } from 'redux';

import { getUser, SELECT_USER_ROLE } from '~/store/user';

import { withMessages } from '../withMessages';

const { IMPERSONATED } = SELECT_USER_ROLE;

const select = state => ({ impersonated: IMPERSONATED(state) });

const boundActions = { getUser };

export default compose(
  connect(
    select,
    boundActions
  ),
  withMessages()
);
