import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import { Button } from '@ent/fields';

import { createSessionManager } from './createSessionManager';

class SessionTimeout extends Component {
  constructor(props) {
    super(props);
    this.manager = createSessionManager({ ...props });
  }

  componentDidMount() {
    const { authenticated } = this.props;
    if (authenticated) {
      this.manager.start();
    }
  }

  componentWillReceiveProps(nextState) {
    const { authenticated } = this.props;
    if (authenticated !== nextState.authenticated) {
      const { start, stop } = this.manager;
      (nextState.authenticated ? start : stop)();
    }
  }

  componentWillUnmount() {
    this.manager.stop();
  }

  // eslint-disable-next-line no-unused-vars
  submit = event => {
    const { setSessionStatus } = this.props;
    this.manager.continue();
    setSessionStatus(null);
  };

  render() {
    const { status, logoutHandler } = this.props;

    const result = {
      ok: null,
      /* eslint-disable react/jsx-one-expression-per-line */
      warning: (
        <Modal show>
          <Modal.Header>
            <Modal.Title>Session Timeout</Modal.Title>
            <Modal.Body>
              <p>
                Your session is about to expire at {this.manager.getTimeoutTime()}. For security reasons, you are
                automatically logged out after {this.manager.timeoutDurationInMinutes} minutes of inactivity.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" kind="primary" onClick={this.submit}>
                Continue
              </Button>
              <Button type="button" kind="default" onClick={logoutHandler}>
                Log Out
              </Button>
            </Modal.Footer>
          </Modal.Header>
        </Modal>
      ),
      timeout: null,
    };

    return result[status || 'ok'];
  }
}

SessionTimeout.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  logoutHandler: PropTypes.func.isRequired,
  // react-redux
  status: PropTypes.string,
  setSessionStatus: PropTypes.func.isRequired,
};

SessionTimeout.defaultProps = { status: null };

export default SessionTimeout;
