import React from 'react';

import './styles.scss';

export default ({ Component: SkeletonLoadingComponent }) => Component => {
  // eslint-disable-next-line react/destructuring-assignment
  const SkeletonLoader = props => (props.loading ? (
    <div className="SkeletonLoader">
      <SkeletonLoadingComponent {...props} />
    </div>
  ) : (
    <Component {...props} />
  ));
  SkeletonLoader.propTypes = {};
  SkeletonLoader.defaultProps = {};

  return SkeletonLoader;
};
