import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MFA_METHODS } from '~/constants';

import { formatter } from './parsers';
import MfaGridButtonMultipleOptions from './MfaGridButtonMultipleOptions';
import MfaGridButtonSingleOption from './MfaGridButtonSingleOption';

class MfaGridButton extends Component {
  state = { lockoutText: undefined };

  componentDidMount() {
    // NOTE: This component may display a "Disabled until" message, we need it to render at least once per second.
    this.interval = setInterval(this.tick, 1000);
    this.tick();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getOptions() {
    const { phones, emails, mode } = this.props;
    switch (mode) {
      case MFA_METHODS.SMS:
      case MFA_METHODS.CALL:
        return {
          options: phones,
          format: formatter.phone,
        };
      case MFA_METHODS.EMAIL:
        return { options: emails };
      default:
        return {};
    }
  }

  tick = () => {
    const { lockout } = this.props;
    const { lockoutText: curLockoutText } = this.state;
    const lockoutText = lockout && formatter.lockout(lockout);
    if (curLockoutText !== lockoutText) {
      this.setState({ lockoutText });
    }
  };

  click = async () => {
    const { mode, form } = this.props;
    await form.setFieldValue('mode', mode);
  };

  render() {
    const { field, mode, text } = this.props;
    const { lockoutText } = this.state;
    const { value } = field;
    const lockout = !!lockoutText;
    const active = !lockout && mode === value;
    const classes = classNames('MfaGridButton', { active, 'has-error': lockout });
    const { options, format } = this.getOptions();

    return (
      <div className={classes} data-autoid="MfaGridButton">
        <MfaGridButtonMultipleOptions mode={mode} options={options} format={format} focusHandler={this.click} />
        <button type="button" onClick={this.click} disabled={lockout} data-autoid={text}>
          <div className={classNames({ 'with-lockout': !!lockout })}>
            {active ? <i className="fa fa-fw fa-check-square-o" /> : <i className="fa fa-fw fa-square-o" />}
            <strong>{text}</strong>
            <MfaGridButtonSingleOption mode={mode} options={options} format={format} />
            {lockout && (
              <div className="small">
                <i className="fa fa-fw fa-exclamation-circle" />
                <em>{lockoutText}</em>
              </div>
            )}
          </div>
        </button>
      </div>
    );
  }
}

MfaGridButton.propTypes = {
  lockout: PropTypes.object,
  mode: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  phones: PropTypes.array,
  emails: PropTypes.array,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

MfaGridButton.defaultProps = {
  lockout: undefined,
  phones: [],
  emails: [],
};

export default MfaGridButton;
