import React from 'react';
import PropTypes from 'prop-types';

import ChallengePage from './ChallengePage';

import decorator from './decorators';

const defaultDuration = 2 * 60 * 1000; // 2 minutes

export default ({ duration = defaultDuration } = {}) => BaseComponent => {
  const RequireAuthentication = props => {
    const { lastAuth, workflow } = props;
    // expires updates every render, lastAuth does not. So if lastAuth was 2 minutes ago, then challenge.
    const expires = new Date().getTime() - duration;
    const valid = lastAuth >= expires;
    return (valid || (workflow?.queue || []).length > 0) ? <BaseComponent {...props} /> : <ChallengePage />;
  };

  RequireAuthentication.propTypes = { lastAuth: PropTypes.number.isRequired };

  const componentName = BaseComponent.displayName || BaseComponent.name || 'Component';
  RequireAuthentication.displayName = `RequireAuthentication(${componentName})`;

  return decorator(RequireAuthentication);
};
