import valet from '~/store/credit-cards/valets/creditCardsValet';
import initialState from '~/store/credit-cards/valets/creditCardsInitialState';
import * as CONST from '~/store/credit-cards/constants';

const creditCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.CREDIT_CARDS_GET:
      return {
        ...state,
        creditCardData: action.payload,
      };
    default:
      return valet.perform(state, action);
  }
};

export default creditCardsReducer;
