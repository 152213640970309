import { createSelector } from 'reselect';
import { identity } from '@ent/functional';

import { selectUserProfileKey } from './userProfile';

export const selectShareNonFavorites = selectUserProfileKey('shares', []);
export const selectShareFavorites = selectUserProfileKey('shareFavorites', []);
export const selectLoanNonFavorites = selectUserProfileKey('loans', []);
export const selectLoanFavorites = selectUserProfileKey('loanFavorites', []);

const sharesOrdered = createSelector(
  selectShareNonFavorites,
  selectShareFavorites,
  (nonFavorites, favorites) => nonFavorites.length !== 0 || favorites.length !== 0
);

const loansOrdered = createSelector(
  selectLoanNonFavorites,
  selectLoanFavorites,
  (nonFavorites, favorites) => nonFavorites.length !== 0 || favorites.length !== 0
);

// prettier-ignore
const orderSuffixes = (shareIds, suffixes) => shareIds
  .map(id => suffixes.find(s => s.suffix === id))
  .filter(identity);

const mapShareIdToSuffixAndAppendMissing = (shareIds, suffixes) => {
  const missing = suffixes.filter(s => shareIds.indexOf(s.suffix) === -1);
  const orderedSuffixes = orderSuffixes(shareIds, suffixes);
  return [...orderedSuffixes, ...missing];
};

const getOrderedShares = (selectShares, selectOrdered, selectFavorites, selectNonFavorites) => createSelector(
  selectOrdered,
  selectFavorites,
  selectNonFavorites,
  selectShares,
  (ordered, favorites, nonFavorites, all) =>
  // eslint-disable-next-line implicit-arrow-linebreak
    (ordered ? mapShareIdToSuffixAndAppendMissing([...favorites, ...nonFavorites], all) : all)
);

// prettier-ignore
export const getSelectOrderedShares = shareSelector => getOrderedShares(
  shareSelector,
  sharesOrdered,
  selectShareFavorites,
  selectShareNonFavorites
);

// prettier-ignore
export const getSelectOrderedLoans = loanSelector => getOrderedShares(
  loanSelector,
  loansOrdered,
  selectLoanFavorites,
  selectLoanNonFavorites
);
