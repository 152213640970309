import React from 'react';
import PropTypes from 'prop-types';

const TopNavLink = ({ className, children, onClick, title }) => (
  <button type="button" className={className} onClick={onClick} aria-label={title}>
    {children}
  </button>
);

TopNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

TopNavLink.defaultProps = {
  onClick: undefined,
  className: '',
  title: '',
};

export default TopNavLink;
