import PropTypes from 'prop-types';

const columnShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.node.isRequired,
  value: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  sort: PropTypes.func,
  filter: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.func]),
});

export default columnShape;
