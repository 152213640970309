import { select } from 'redux-saga/effects';
import Cookie from 'universal-cookie';

import { USER_GET_SUCCESS } from '../constants';
import { selectCookie } from '~/store/cookie';
import { log } from '~/common/log';
import { takeFirst } from '~/common/redux';

const cookie = new Cookie();

function* publishUserReferrer() {
  try {
    const referrer = yield select(selectCookie('referrer')) || {};
    const utm = yield select(selectCookie('utm')) || {};
    if (!!referrer || !!utm) {
      log({
        category: 7, // Audit
        message: 'User Login Parameters',
        details: JSON.stringify({ referrer, utm }),
        context: 'true',
      });
    }
    cookie.remove('referrer', { domain: '.ent.com', path: '/' });
    cookie.remove('utm', { domain: '.ent.com', path: '/' });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* logUserReferrer() {
  yield takeFirst(USER_GET_SUCCESS, publishUserReferrer);
}

export default logUserReferrer;
