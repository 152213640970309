import * as CONST from '~/store/messages/constants';

import initialState from './messagesInitialState';

const unique = ({ type, text }) => m => m.type !== type || m.text !== text;

const addMessage = (state, action) => [
  ...state.filter(unique(action.payload)),
  action.payload,
];

const clearMessages = (state, action) => {
  const { all, owner, id } = action.payload;

  if (all) {
    return state.length === 0 ? state : [];
  }

  const notMatch = m => (id && m.id !== id) || (owner && m.owner !== owner);
  const filtered = state.filter(notMatch);
  return filtered.length === state.length ? state : filtered;
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.MESSAGES_ADD:
      return addMessage(state, action);
    case CONST.MESSAGES_CLEAR:
      return clearMessages(state, action);
    default:
      return state;
  }
};

export default messagesReducer;
