import { memoize } from '@ent/functional';

import WORKFLOWS from '~/constants/workflows';
import { log } from '~/common/log';

export default memoize(
  workflows => {
    const queue = workflows.map(wf => WORKFLOWS[wf]).filter(v => !!v);

    if (queue.length !== workflows.length) {
      const invalid = JSON.stringify(workflows.filter(wf => !WORKFLOWS[wf]));
      console.warn(`Invalid workflow(s) specified: ${invalid}`); // eslint-disable-line no-console
      log({
        category: 0, // Unspecified
        severity: 300, // Warning
        message: 'Invalid workflow(s) specified',
        details: invalid,
        url: document.location.href,
      });
    }

    return queue;
  },
  { ttl: 0 }
);
