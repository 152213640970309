import { preload } from '~/common';
import PAGE from '~/pages';

// prettier-ignore
export default () => preload(
  PAGE.MfaChallenge,
  PAGE.Disclosure,
  PAGE.Password,
  PAGE.SecurityProfile,
  PAGE.ContactInfo,
  PAGE.LoginSuccess
);
