import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withPageState } from '@ent/redux-pagestate';
import { noop } from '@ent/functional';

import { MFA_METHODS } from '~/constants';
import { withApi, withMessages, withRouter } from '~/hocs';
import { MfaChallenge } from '~/components/MfaChallenge';

import connectConfig from './connect';
import pageStateConfig from './page-state';
import preload from './preload';

class MfaChallengeContent extends Component {
  componentDidMount() {
    const { pageState } = this.props;
    if (pageState.loading) {
      this.initPageState();
    }
    preload();
    this.resolve = noop;
    new Promise(resolve => {
      this.resolve = fn => resolve(fn);
    }).then(fn => fn());
  }

  componentDidUpdate() {
    const { fail } = this.props;
    if (fail) this.resolve(this.fail);
  }

  initPageState = async () => {
    const { addMessage, api, setPageState } = this.props;

    try {
      const { call, phones, emails, methods, lastMethod } = await api({
        url: '/Banking/api/authentication/mfa',
        method: 'GET',
        data: undefined,
      });
      const { pageState } = this.props;
      setPageState({
        ...pageState,
        loading: false,
        call,
        phones,
        emails,
        methods,
        lastMethod,
      });
    } catch (e) {
      addMessage({ text: e.message });
      setPageState(pageStateConfig.value);
    }
  };

  fail = () => {
    const { redirect } = this.props;
    redirect('/Banking/app/mfa/fail');
  };

  submit = values => {
    const { pageState, redirect, setPageState } = this.props;
    const { mode, ids } = values;
    const id = ids[mode];

    setPageState({
      ...pageState,
      mode,
      ids: {
        ...pageState.ids,
        [mode]: id,
      },
    });

    const path = {
      [MFA_METHODS.SMS]: 'sms',
      [MFA_METHODS.CALL]: 'call',
      [MFA_METHODS.PUSH]: 'push',
      [MFA_METHODS.EMAIL]: 'email',
    };

    redirect(`/Banking/app/mfa/${path[mode]}`);
  };

  render() {
    const { config, defaultMethod, pageState, xs } = this.props;
    const { loading } = pageState;

    const initialValues = { ...defaultMethod };

    return (
      <div className="col-xs-12 col-sm-8">
        {loading && 'Loading...'}
        {!loading && <MfaChallenge initialValues={initialValues} onSubmit={this.submit} config={config} xs={xs} />}
      </div>
    );
  }
}

MfaChallengeContent.propTypes = {
  // connect
  xs: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  defaultMethod: PropTypes.object.isRequired,
  fail: PropTypes.bool.isRequired,
  // withApi
  api: PropTypes.func.isRequired,
  // withMessages
  addMessage: PropTypes.func.isRequired,
  // withPageState
  pageState: PropTypes.object.isRequired,
  setPageState: PropTypes.func.isRequired,
  // withRouter
  redirect: PropTypes.func.isRequired,
};

MfaChallengeContent.defaultProps = {};

export default compose(
  withApi(),
  withRouter(),
  withMessages({ clearOnUnload: true }),
  withPageState(pageStateConfig),
  connect(...connectConfig)
)(MfaChallengeContent);
