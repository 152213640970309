import { connect } from 'react-redux';

import { selectSessionStatus, startSession, keepAlive, setSessionStatus, stopSession } from '~/store/session';

const select = state => ({ status: selectSessionStatus(state) });

const boundActions = {
  startSession,
  keepAlive,
  setSessionStatus,
  stopSession,
};

export default connect(
  select,
  boundActions
);
