/* eslint-disable */
// prettier-ignore
  
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const CheckMarkLoading = ({ isLoading, isWaiting }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="checkmark-circle">
      <path id="checkmark" d="M11.5224 7.25689C11.286 7.01129 10.9653 6.87331 10.6309 6.87331C10.2965 6.87331 9.97576 7.01129 9.73931 7.25689C9.50285 7.50248 9.37001 7.83559 9.37001 8.18291C9.37001 8.53024 9.50285 8.86334 9.73931 9.10894L13.5065 13.0217C13.6238 13.1426 13.763 13.2383 13.916 13.3032C14.069 13.3681 14.2328 13.401 14.398 13.4C14.5699 13.3943 14.7389 13.352 14.8944 13.2757C15.05 13.1995 15.1887 13.0908 15.3022 12.9565L24.0922 2.5224C24.2952 2.26067 24.3931 1.92767 24.3654 1.59283C24.3378 1.25799 24.1867 0.947153 23.9438 0.725136C23.7009 0.503118 23.3849 0.387056 23.0616 0.401147C22.7384 0.415238 22.4328 0.558396 22.2086 0.800771L14.398 10.1915L11.5224 7.25689Z" />
      {isLoading && <path id="checkmark-loading" d="M11.5217 7.25652C11.2853 7.01092 10.9646 6.87294 10.6302 6.87294C10.2958 6.87294 9.97506 7.01092 9.7386 7.25652C9.50215 7.50211 9.36931 7.83522 9.36931 8.18254C9.36931 8.52987 9.50215 8.86297 9.7386 9.10857L13.5058 13.0214C13.6231 13.1423 13.7623 13.2379 13.9153 13.3028C14.0683 13.3677 14.2321 13.4006 14.3973 13.3996C14.5692 13.3939 14.7382 13.3517 14.8937 13.2754C15.0493 13.1991 15.188 13.0904 15.3015 12.9562L24.0915 2.52203C24.2945 2.2603 24.3924 1.9273 24.3647 1.59246C24.3371 1.25762 24.186 0.946782 23.9431 0.724765C23.7002 0.502747 23.3842 0.386685 23.0609 0.400776C22.7376 0.414867 22.4321 0.558025 22.2079 0.8004L14.3973 10.1911L11.5217 7.25652Z" />}
      {!isLoading && !isWaiting && <path id="checkmark-done" d="M11.5217 7.25652C11.2853 7.01092 10.9646 6.87294 10.6302 6.87294C10.2958 6.87294 9.97506 7.01092 9.7386 7.25652C9.50215 7.50211 9.36931 7.83522 9.36931 8.18254C9.36931 8.52987 9.50215 8.86297 9.7386 9.10857L13.5058 13.0214C13.6231 13.1423 13.7623 13.2379 13.9153 13.3028C14.0683 13.3677 14.2321 13.4006 14.3973 13.3996C14.5692 13.3939 14.7382 13.3517 14.8937 13.2754C15.0493 13.1991 15.188 13.0904 15.3015 12.9562L24.0915 2.52203C24.2945 2.2603 24.3924 1.9273 24.3647 1.59246C24.3371 1.25762 24.186 0.946782 23.9431 0.724765C23.7002 0.502747 23.3842 0.386685 23.0609 0.400776C22.7376 0.414867 22.4321 0.558025 22.2079 0.8004L14.3973 10.1911L11.5217 7.25652Z" />}
      <g id="circle" filter="url(#filter0_d)">
        <mask id="path-3-inside-1" fill="white">
          <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48048 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" />
        </mask>
        <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48048 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" />
        <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48048 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" strokeWidth="2" mask="url(#path-3-inside-1)" />
      </g>
      {isLoading && <g id="circle-loading" filter="url(#filter1_d)">
        <mask id="path-4-inside-2" fill="white">
          <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48049 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" />
        </mask>
        <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48049 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" />
        <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48049 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" strokeWidth="2" mask="url(#path-4-inside-2)" />
      </g>}
      {!isLoading && !isWaiting && <g id="circle-done" filter="url(#filter1_d)">
        <mask id="path-4-inside-2" fill="white">
          <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48049 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" />
        </mask>
        <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48049 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" />
        <path d="M22.9481 9C22.6828 9 22.4285 9.10536 22.2409 9.29289C22.0534 9.48043 21.9481 9.73478 21.9481 10C21.9481 12.1217 21.1052 14.1566 19.6049 15.6569C18.1046 17.1571 16.0698 18 13.9481 18C12.3681 17.9993 10.8238 17.5308 9.50982 16.6535C8.19582 15.7763 7.17098 14.5297 6.56454 13.0708C5.95809 11.6119 5.7972 10.0061 6.10214 8.45589C6.40709 6.90569 7.16422 5.48049 8.27806 4.36C9.01876 3.60938 9.90173 3.01404 10.8753 2.60881C11.8489 2.20358 12.8935 1.99661 13.9481 2C14.5875 2.004 15.2246 2.07777 15.8481 2.22C15.9785 2.26035 16.1159 2.27356 16.2516 2.2588C16.3874 2.24404 16.5187 2.20163 16.6375 2.13418C16.7562 2.06673 16.8599 1.97567 16.9421 1.86662C17.0243 1.75757 17.0833 1.63284 17.1154 1.50011C17.1476 1.36739 17.1522 1.22948 17.129 1.0949C17.1059 0.960317 17.0554 0.831909 16.9806 0.717592C16.9059 0.603274 16.8086 0.505471 16.6946 0.430211C16.5807 0.354952 16.4525 0.303831 16.3181 0.28C15.5412 0.0972427 14.7461 0.00330743 13.9481 0C11.9724 0.0102635 10.044 0.605521 8.40635 1.71065C6.76866 2.81578 5.49503 4.38126 4.74617 6.20953C3.99732 8.0378 3.80681 10.0469 4.19869 11.9834C4.59057 13.9198 5.54727 15.6968 6.94806 17.09C8.80475 18.9475 11.3217 19.9939 13.9481 20C16.6002 20 19.1438 18.9464 21.0191 17.0711C22.8945 15.1957 23.9481 12.6522 23.9481 10C23.9481 9.73478 23.8427 9.48043 23.6552 9.29289C23.4676 9.10536 23.2133 9 22.9481 9Z" strokeWidth="2" mask="url(#path-4-inside-2)" />
      </g>}
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="27.9481" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter id="filter1_d" x="0" y="0" width="27.9481" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>

);

CheckMarkLoading.propTypes = {
  isLoading: PropTypes.bool,
  isWaiting: PropTypes.bool,
};

CheckMarkLoading.defaultProps = {isLoading: true};

export default CheckMarkLoading;
