import { createSelector } from 'reselect';

import selectUser from './selectUser';

const selectEntProtectInfo = createSelector(
  selectUser,
  user => user.entProtectInfo,
);

export default selectEntProtectInfo;
