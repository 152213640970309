import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button, ScrollToFirstError, TextField } from '@ent/fields';
import { Module } from '@ent/component';

import { RecaptchaField, ShowHideLink } from '~/common';

import { getInitialValues, validationSchema } from './form';
import SecureImage from './SecureImage';
import BiometricPass from './BiometricPass';
import './styles.scss';

class LoginPassword extends Component {
  formik = null;

  componentDidMount() {
    document.getElementsByName('password').forEach(f => f.classList.add('sm_cobrowsing_masked_field'));
  }

  async componentDidUpdate(prevProps) {
    const { recaptchaRequired } = this.props;
    if (this.formik && recaptchaRequired !== prevProps.recaptchaRequired) {
      await this.formik.setFieldValue('recaptchaRequired', false);
    }
  }

  render() {
    const {
      bioSubmitting,
      entMobileOutdated,
      image,
      inAuthLoading,
      initialValues,
      loginRecoverUrl,
      phrase,
      recaptchaRequired,
      onSubmit,
      onRecaptchaSuccess,
      showPassword,
      showHidePasswordClick,
    } = this.props;
    return (
      <Module className="default LoginPassword no-margin">
        <Module.Body>
          <Formik
            initialValues={getInitialValues({ ...initialValues, recaptchaRequired })}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {formik => {
              this.formik = formik;
              const { handleSubmit, isSubmitting, isValid } = formik;
              return (
                <form onSubmit={handleSubmit}>
                  <ScrollToFirstError isSubmitting={isSubmitting} isValid={isValid} />
                  <TextField name="username" label="Username:" readOnly />
                  <SecureImage image={image} phrase={phrase} />
                  <TextField
                    name="password"
                    label="Password:"
                    type={showPassword ? 'text' : 'password'}
                    autoFocus
                    suppressError={bioSubmitting}
                    componentOverride={bioSubmitting ? BiometricPass : undefined}
                    note={(
                      <ShowHideLink
                        show={showPassword}
                        onClick={showHidePasswordClick}
                        fieldName="password"
                        fieldDescription="password" />
                    )} />
                  <RecaptchaField onRecaptchaSuccess={onRecaptchaSuccess} />
                  <div className="narrow-gutter-row continue-forgot-group">
                    <Button
                      type="submit"
                      className="clearfix col-xs-12 col-sm-4 col pull-right"
                      disabled={entMobileOutdated || bioSubmitting || isSubmitting}>
                      Continue
                    </Button>
                    <button
                      type="button"
                      className="clearfix btn btn-link col-xs-12 col-sm-4 forgot-password-username"
                      data-autoid="forgot-password-username"
                      onClick={() => { window.location = loginRecoverUrl; }}
                      disabled={inAuthLoading}>
                        Forgot Password/Username
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Module.Body>
      </Module>
    );
  }
}

LoginPassword.propTypes = {
  bioSubmitting: PropTypes.bool.isRequired,
  entMobileOutdated: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  inAuthLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  loginRecoverUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  phrase: PropTypes.string.isRequired,
  recaptchaRequired: PropTypes.bool.isRequired,
  onRecaptchaSuccess: PropTypes.func,
  showPassword: PropTypes.string.isRequired,
  showHidePasswordClick: PropTypes.func.isRequired,
};

LoginPassword.defaultProps = { onRecaptchaSuccess: undefined };

export default LoginPassword;
