import * as CONST from '../constants';

const setWorkflow = ({ queue, finishUrl }) => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.WORKFLOW_SET,
    payload: { queue, finishUrl },
  })
);

export default setWorkflow;
