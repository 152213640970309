import * as Yup from 'yup';

import { MFA_METHODS } from '~/constants';

export const getInitialValues = initialValues => ({
  mode: '',
  ids: {
    [MFA_METHODS.SMS]: '',
    [MFA_METHODS.CALL]: '',
    [MFA_METHODS.EMAIL]: '',
  },
  ...initialValues,
});

export const validationSchema = Yup.object({ mode: Yup.string().required('Required') });
