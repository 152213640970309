import { once } from '@ent/functional';
import { log } from '~/common/log';

import { sid, tid, collectorUrl } from './constants';

export default once(cc => {
  cc.push(['ci', { sid, tid }]);
  cc.push(['cf', 1022963]); // flags
  cc.push(['st', 700]); // timeout
  cc.push(['run', collectorUrl]);

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `${collectorUrl}/cc.js?ts=${new Date().getTime()}&sid=${sid}&tid=${tid}`;

  const first = document.getElementsByTagName('script')[0];
  first.parentNode.insertBefore(script, first);

  log({
    category: 7, // audit
    message: 'InAuth: InBrowser Collector Started',
    context: false,
    details: `TID: ${tid}`,
  });
});
