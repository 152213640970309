import moment from 'moment';

import { Parse, Format } from '~/common';

const init = {
  digits4: config => Parse.string({ required: true, minLength: 4, maxLength: 4, regex: /^\d*$/, ...config }),
  lockout: config => Parse.date({ required: true, validate: dt => moment().isBefore(dt), ...config }),
  string: config => Parse.string({ required: true, ...config }),
};

export const formatter = {
  phone: init
    .digits4()
    .then(Format.string.maskPhone)
    .catch(Format.string.empty)
    .value.resolved(),
  lockout: init
    .lockout()
    .then(m => `Disabled. Please try again ${moment().to(m)}.`)
    .catch(Format.string.empty)
    .value.resolved(),
};

export const validator = {
  mode: init.string().value.rejected(),
  string: init.string().value.rejected(),
};
