import { connect } from 'react-redux';

import { dequeueWorkflow, peekWorkflow, selectNextUrl } from '~/store/workflow';

const select = state => ({
  current: peekWorkflow(state),
  nextUrl: selectNextUrl(state),
});

const boundActions = { dequeue: dequeueWorkflow };

export default connect(
  select,
  boundActions
);
