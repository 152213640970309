import React from 'react';
import PropTypes from 'prop-types';

const SecureImage = ({ image, phrase }) => (
  <div className="text-center SecureImage">
    <div className="title">Secure Image &amp; Phrase:</div>
    <div className="image">
      <img src={image} role="presentation" alt={phrase} />
    </div>
    <div className="phrase">{phrase}</div>
  </div>
);

SecureImage.propTypes = {
  image: PropTypes.string,
  phrase: PropTypes.string,
};

SecureImage.defaultProps = {
  image: '/Banking/AuthenticationManager.aspx?cache=0',
  phrase: 'Fraud',
};

export default SecureImage;
