import { createValet } from '~/common/redux/valet';
import * as CONST from '~/store/cardnav/constants';

import history from '~/store/history';

import initialState from './cardnavInitialState';

const cardnavValet = createValet({
  defaultReducer: 'cardnav',
  history,
  initialState,
  msCacheDuration: 20 * 60 * 1000, // 20 minutes
  GET: CONST.CARDNAV_GET,
  ERROR: CONST.CARDNAV_GET_ERROR,
  SUCCESS: CONST.CARDNAV_GET_SUCCESS,
  RESET: CONST.CARDNAV_RESET,
  EXPIRE: CONST.CARDNAV_EXPIRE,
});

export default cardnavValet;
