import { createValet } from '~/common/redux/valet';
import * as CONST from '~/store/cards/constants';
import history from '~/store/history';

import initialState from './cardsInitialState';

const cardsValet = createValet({
  defaultReducer: 'cards',
  history,
  initialState,
  msCacheDuration: 20 * 60 * 1000, // 20 minutes
  GET: CONST.CARDS_GET,
  ERROR: CONST.CARDS_GET_ERROR,
  SUCCESS: CONST.CARDS_GET_SUCCESS,
  RESET: CONST.CARDS_RESET,
  EXPIRE: CONST.CARDS_EXPIRE,
});

export default cardsValet;
