import * as CONST from '~/store/session/constants';

const setSessionStatus = status => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.SESSION_SET_STATUS,
    payload: status,
  })
);

export default setSessionStatus;
