import { apiProvider } from '@ent/browser';

import { log } from '~/common/log';
import { tid } from './constants';

export default async ({ start, payload }) => {
  try {
    await apiProvider({
      url: '/Banking/api/inauth/logs/browser',
      method: 'POST',
      data: {
        payload,
        transactionID: tid,
        duration: Date.now() - start,
      },
      autoUpdateWorkflows: false,
    });

    window.dataLayer.push({
      event: 'Custom Event',
      action: 'InAuth Submitted',
    });

    return true;
  } catch (error) {
    log({
      category: 7, // audit
      severity: 300, // warning
      message: 'InAuth: InBrowser log submission failed',
      context: false,
      details: error.stack || error.message,
    });
    return false;
  }
};
