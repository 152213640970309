/* eslint-disable */
import { React, useEffect, useState } from 'react';

const webBeacon = `(function() {
  function _rn(s) {
    return s.replace(/[a-zA-Z]/g, function(c) {
      return String.fromCharCode(
        (c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26
      );
    });
  }

  if (window.location.host.match(/(^|\.)ent\.com$/) === null) {
    var hh =
      '//content-cdn.com/1028/' +
      btoa(_rn(window.location.toString())) +
      '.gif';
    var tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.async = true;
    tag.innerHTML =
      "(function(){ var img = new Image(); img.referrerpolicy = 'origin'; img.src = '" +
      hh +
      "'; })();";
    var first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(tag, first);
  }
})()`;

const WebBeacon = () => {
  const [checkForWindowInterval, setCheckForWindowInterval] = useState(
    () => null
  );
  const [windowLoaded, setWindowLoaded] = useState(() => false);

  useEffect(() => {
    setCheckForWindowInterval(
      setInterval(() => {
        if (window && document) {
          setWindowLoaded(true);
          clearInterval(checkForWindowInterval);
          setCheckForWindowInterval(null);
        }
      }, 100)
    );
    return () => {
      if (checkForWindowInterval !== null) {
        clearInterval(checkForWindowInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (!windowLoaded) return;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = webBeacon;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [windowLoaded]);

  return null;
};

export default WebBeacon;
