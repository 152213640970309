import { createSelector } from 'reselect';

import { selectSelectedID } from '~/store/cards/selectors';
import selectCards from './selectCards';

// prettier-ignore
export default createSelector(
  selectCards,
  selectSelectedID,
  (cards, selectedId) => cards.find(({ id }) => id === selectedId)
);
