import { apiProvider } from '@ent/browser';

import * as CONST from '~/store/user/constants';
import valet from '~/store/user/valets/userValet';

const getUser = ({
  force,
  state,
  reducer = 'user',
  mockProvider = null,
  redirectOn403 = true,
  autoUpdateWorkflows = true,
  autoPublishMessages,
} = {}) => (dispatch, getState) => new Promise(resolve => {
  const should = valet.shouldGet({ force, state, reducer, getState, autoUpdateWorkflows });
  if (!should) {
    return resolve();
  }

  dispatch({ type: CONST.USER_GET });

  const request = { url: '/Banking/api/user/userinfo', redirectOn403, autoUpdateWorkflows, autoPublishMessages };
  const success = data => dispatch({ type: CONST.USER_GET_SUCCESS, payload: data });
  const failure = error => dispatch({ type: CONST.USER_GET_ERROR, payload: error.message });

  const provider = mockProvider || apiProvider;
  const result = provider(request)
    .then(success)
    .catch(failure);
  return resolve(result);
});

export default getUser;
