import { createSelector } from 'reselect';

import selectCardnavCards from './selectCardnavCards';

// prettier-ignore
export default createSelector(
  selectCardnavCards,
  cardnavCards => (cardnavCards || []).reduce(
    (acc, cardnav) => ({
      ...acc,
      [cardnav.id]: cardnav,
    }),
    {}
  )
);
