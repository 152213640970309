const toRad = n => (Math.PI * n) / 180; // eslint-disable-line no-mixed-operators

const calcDistance = ({ from, to = {}, unit = 'M' }) => {
  // TODO: Research Google Maps API
  // llFrom = new google.maps.LatLng(from.lat, from.lng);
  // llTo = new google.maps.LatLng(to.lat, to.lng);
  // google.maps.geometry.spherical.computeDistanceBetween(llFrom, llTo);
  // NOTE: 2.5k requests per day before pay-service

  if (from) {
    // https://www.geodatasource.com/developers/javascript
    const { lat: lat1, lng: lng1 } = from;
    const { lat: lat2, lng: lng2 } = to;
    const radLat1 = toRad(lat1);
    const radLat2 = toRad(lat2);
    const radTheta = toRad(lng1 - lng2);
    // eslint-disable-next-line no-mixed-operators
    let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    dist = Math.acos(dist);
    dist *= 180 / Math.PI;
    dist *= 60 * 1.1515;
    dist *= unit === 'K' ? 1.609344 : unit === 'N' ? 0.8684 : 1; // eslint-disable-line no-nested-ternary
    return Math.round(dist * 100) / 100;
  }

  return NaN;
};

export default calcDistance;
