import { EVENT } from '@ent/browser';

import { ENT_MOBILE_ACTION } from '~/constants';
import { log } from '~/common/log';

export default provider => action => {
  switch (action.type) {
    // App -> Client
    case ENT_MOBILE_ACTION.LOG:
      return log({
        category: 7, // audit
        severity: 300, // warning
        message: 'EntMobile: Log',
        context: true,
        details: '',
        ...action.payload,
      });
    case ENT_MOBILE_ACTION.INAUTH_COMPLETE:
    case ENT_MOBILE_ACTION.BIOMETRIC_SUBMIT:
      window.dispatchEvent(new CustomEvent(EVENT.ENT_MOBILE, { detail: action }));
      return Promise.resolve();
    // Client -> App
    case ENT_MOBILE_ACTION.INAUTH_START:
    case ENT_MOBILE_ACTION.BIOMETRIC_INIT:
    case ENT_MOBILE_ACTION.BIOMETRIC_SUBMIT_ERROR:
    case ENT_MOBILE_ACTION.BIOMETRIC_SUBMIT_SUCCESS:
    case ENT_MOBILE_ACTION.LOGIN_SUCCESS:
    case ENT_MOBILE_ACTION.CREDITCARD_SSO:
    case ENT_MOBILE_ACTION.TRANSFER_SUCCESS:
    case ENT_MOBILE_ACTION.PAYBILL_SUCCESS:
    case ENT_MOBILE_ACTION.BACKBASE_REDIRECT:
      return provider(action);
    default:
      return Promise.reject(Error(`Unknown EntMobile Action: ${action.type}`));
  }
};
