import { createSelector } from 'reselect';
import { createVersion } from '@ent/browser';

export default createSelector(
  state => state.development,
  state => state.entMobile,
  state => state.device,
  (development, entMobile, userDevice) => {
    const dev = development && !!entMobile;

    const { isEntMobileIos, isEntMobileAndroid: android, entMobileVersion: version } = userDevice;

    const ios = isEntMobileIos || dev;
    const device = ios ? 'iOS' : android ? 'Android' : ''; // eslint-disable-line no-nested-ternary
    // prettier-ignore
    const dispatchable = dev
  || (ios && version > createVersion('5.0.1.25'))
  || (android && version > createVersion('4.3.3.4003'));

    return {
      ios,
      android,
      device,
      version,
      dispatchable,
    };
  }
);
