import moment from 'moment';

export const COOKIE_INIT_BEGIN = 'Cookie: Init: Begin';
export const COOKIE_INIT_END = 'Cookie: Init: End';
export const COOKIE_SET = 'Cookie: Set';
export const COOKIE_REMOVE = 'Cookie: Remove';

const { hostname } = window.location;
const domain = (hostname.match(/\.[a-z]{2,}\.[a-z]{2,}$/) || [])[0];

export const COOKIE = {
  HASH: 'hash',
  ENT: 'ent',
  UNAUTHENTICATED_TOKEN: 'UnauthenticatedToken',
  REMEMBER_ME: 'rememberMe',
  QUICK_BALANCE: 'quickBalance',
  DISABLED: 'COOKIES_DISABLED',
  OPTIONS: {
    domain: domain && domain.indexOf('azureinternal') === -1 ? domain : hostname,
    expires: moment()
      .add(365, 'days')
      .toDate(),
    path: '/',
    secure: hostname !== 'localhost',
  },
};
