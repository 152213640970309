import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { log } from '~/common/log';

import decorator from './decorators';

const withImpersonation = () => BaseComponent => {
  class WithImpersonation extends Component {
    componentDidMount() {
      const { getUser } = this.props;
      getUser({ redirectOn403: false });
    }

    denyImpersonation = fn => (...args) => {
      const { impersonated, addMessage } = this.props;
      if (impersonated) {
        let values;
        addMessage({ text: 'Inadequate permissions to submit form while logged in as user.', autoScroll: true });

        try {
          values = JSON.stringify(args[0]);
        } catch (ex) {} // eslint-disable-line no-empty

        log({
          category: 0, // Unspecified
          severity: 300, // Warning
          message: 'Impersonated user attempted to submit restricted form.',
          details: values,
          url: document.location.href,
        });
        return undefined;
      }
      return fn(...args);
    };

    render() {
      const { impersonated } = this.props;
      return <BaseComponent {...this.props} impersonated={impersonated} denyImpersonation={this.denyImpersonation} />;
    }
  }

  WithImpersonation.propTypes = {
    // connect
    impersonated: PropTypes.bool.isRequired,
    getUser: PropTypes.func.isRequired,
    // withMessages
    addMessage: PropTypes.func.isRequired,
  };

  WithImpersonation.defaultProps = {};

  const componentName = BaseComponent.displayName || BaseComponent.name || 'Component';
  WithImpersonation.displayName = `WithImpersonation(${componentName})`;

  return decorator(WithImpersonation);
};

export default withImpersonation;
