import React from 'react';
import { noop } from '@ent/functional';

import { selectAddress, selectAddressIssues } from '../../smartyStreets';

export default React.createContext({
  addMessage: noop,
  clearMessages: noop,
  selectAddress,
  selectAddressIssues,
});
