import { call, put, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { apiProvider } from '@ent/browser';

import * as CONST from '../constants';

function* refetchSuffixes(action) {
  try {
    if (
      action.payload.pathname.toLowerCase().startsWith('/banking/app/accounts')
      && document.referrer
      && document.referrer.toLowerCase().endsWith('/banking/summary.aspx')
    ) {
      yield put({ type: CONST.SUFFIXES_GET });

      const data = yield call(apiProvider, {
        url: '/Banking/api/accounts/suffixes?reload=true',
        autoUpdateWorkflows: true,
      });
      yield put({ type: CONST.SUFFIXES_GET_SUCCESS, payload: data });
    }
  } catch (e) {
    yield put({ type: CONST.SUFFIXES_GET_ERROR, payload: e.message });
  }
}

function* accountCreateCacheEvict() {
  yield takeLatest(LOCATION_CHANGE, refetchSuffixes);
}

export default accountCreateCacheEvict;
