import { DISCLOSURE } from './constants';

export const WORKFLOW = {
  MFA_CHALLENGE: 100,
  MIGRATION: 150,
  ACCOUNT_AGREEMENT: 300,
  CHANGE_PASSWORD: 400,
  CHANGE_USERNAME: 450,
  SECURITY_SETUP: 500,
  CONTACT_INFO: 600,
  OPTIN_PHONE: 700,
  OAO_FUNDING: 800,
  LOGIN_SUCCESS: 999,
};

export const WORKFLOW_FLAGS = {
  NONE: 0,
  LOGIN: 1,
};

export default {
  [WORKFLOW.MFA_CHALLENGE]: {
    id: WORKFLOW.MFA_CHALLENGE,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: [
      '/Banking/app/mfa',
      '/Banking/app/mfa/sms',
      '/Banking/app/mfa/call',
      '/Banking/app/mfa/push',
      '/Banking/app/mfa/email',
      '/Banking/app/mfa/fail',
      '/Banking/app/mfa/success',
    ],
  },
  [WORKFLOW.ACCOUNT_AGREEMENT]: {
    id: WORKFLOW.ACCOUNT_AGREEMENT,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: [`/Banking/app/disclosure/${DISCLOSURE.ELECTRONIC_SERVICES_AGREEMENT}`],
  },
  [WORKFLOW.CHANGE_PASSWORD]: {
    id: WORKFLOW.CHANGE_PASSWORD,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: ['/Banking/app/setup/password'],
  },
  [WORKFLOW.CHANGE_USERNAME]: {
    id: WORKFLOW.CHANGE_USERNAME,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: [
      '/Banking/app/profile/username',
    ],
  },
  [WORKFLOW.SECURITY_SETUP]: {
    id: WORKFLOW.SECURITY_SETUP,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: ['/Banking/app/setup/security-profile'],
  },
  [WORKFLOW.CONTACT_INFO]: {
    id: WORKFLOW.CONTACT_INFO,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: ['/Banking/app/setup/contact-info', '/Banking/app/setup/address-phone', '/Banking/app/setup/email'],
  },
  [WORKFLOW.OPTIN_PHONE]: {
    id: WORKFLOW.OPTIN_PHONE,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: ['/Banking/app/opt-in/phone', '/Banking/app/opt-in/address-phone'],
  },
  [WORKFLOW.OAO_FUNDING]: {
    id: WORKFLOW.OAO_FUNDING,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: ['/Banking/OAO/Funding.aspx'],
  },
  [WORKFLOW.LOGIN_SUCCESS]: {
    id: WORKFLOW.LOGIN_SUCCESS,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: ['/Banking/app/login/success'],
  },
  [WORKFLOW.MIGRATION]: {
    id: WORKFLOW.MIGRATION,
    flags: WORKFLOW_FLAGS.LOGIN,
    urls: ['/Banking/app/migration/start'],
  },
};
