import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { memoize } from '@ent/functional';
import { apiProvider } from '@ent/browser';

import { format } from '~/common/format';

class RoutingNumber extends Component {
  state = { current: undefined };

  componentDidUpdate = prevProps => {
    const { field } = this.props;
    if (prevProps.field.value !== field.value) {
      this.update();
    }
  };

  getRouting = memoize(query => apiProvider({
    url: `/Banking/api/ach/routing/${query}`,
    method: 'GET',
  }));

  update = async () => {
    const { field, form } = this.props;
    const { current } = this.state;
    const nameField = `${field.name}:name`;
    const valid = field.value && /^\d{9}$/.test(field.value || '');
    if (valid) {
      const { routing } = await this.getRouting(field.value);
      const { name } = routing || {};
      this.setState({ current: routing });
      await form.setFieldValue(nameField, name);
    } else if (current) {
      this.setState({ current: undefined });
      await form.setFieldValue(nameField, undefined);
    }
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { field, form, ...other } = this.props;
    const { current } = this.state;

    return (
      <div>
        <input className="form-control rich-select" maxLength="9" {...field} {...other} />
        {current && (
          <div className="help-block text-muted small">{`Institution Name: ${format.title(current.name)}`}</div>
        )}
      </div>
    );
  }
}

RoutingNumber.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

RoutingNumber.defaultProps = {};

export default RoutingNumber;
