import { eventChannel } from 'redux-saga';

export default function periodicEmitter(interval = 15000) {
  return eventChannel(emitter => {
    let count = 0;
    const intervalId = setInterval(() => {
      emitter(count++);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  });
}
