import { createSelector } from 'reselect';

import PAGE from '../constants/page';
import selectAllPages from './selectAllPages';

// prettier-ignore
const selectPageByID = id => createSelector(
  selectAllPages,
  pages => pages.find(p => p.id === id) || pages.find(p => p.id === PAGE.NOT_FOUND)
);

export default selectPageByID;
