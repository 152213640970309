import { device } from '@ent/browser';
import { Storage } from '~/common/Storage';

import selectConfig from './selectConfig';

export default selectConfig({
  development: process.env.NODE_ENV === 'development',
  entMobile: Storage.session.getItem('entMobile').value.resolved(),
  device,
});
