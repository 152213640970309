import { createSelector } from 'reselect';

export default createSelector(
  state => state.development,
  state => state.config,
  state => state.dispatch,
  state => state.observable,
  (development, config, dispatch, observable) => {
    const entMobile = {
      config,
      dispatch,
      observable,
    };

    if (development || config.device) {
      window.entMobile = entMobile;
    }

    return entMobile;
  }
);
