import { log as defaultLog } from '~/common/log';

export default ({ name, timeout, log = defaultLog, getPayload, submitLogs }) => {
  const TIMEOUT_MESSAGE = `InAuth: ${name} timeout exceeded`;

  return new Promise(async (resolve, reject) => {
    try {
      setTimeout(() => reject(new Error(TIMEOUT_MESSAGE)), timeout);

      const start = Date.now();

      const payload = await getPayload();
      const payloadTime = Date.now();

      const result = await submitLogs({ start, payload });

      resolve(result);

      if (Date.now() - start > timeout) {
        log({
          category: 7, // audit
          severity: 300, // warning
          message: `InAuth: ${name} completed after timeout exceeded`,
          context: false,
          details: `${Date.now() - start}ms = ${payloadTime - start}ms + ${Date.now() - payloadTime}ms`,
        });
      }
    } catch (error) {
      reject(error);
    }
  }).catch(error => {
    log({
      category: 7, // audit
      severity: 300, // warning
      message: `InAuth: ${name} failed`,
      context: false,
      details: error.message === TIMEOUT_MESSAGE ? `${TIMEOUT_MESSAGE} [${timeout}ms]` : error.stack || error.message,
    });
    return false;
  });
};
