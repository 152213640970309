import { KEYS_FOR_ARRAYS } from './constants';

const serializeArray = (key, value) => (KEYS_FOR_ARRAYS.includes(key) && value !== null ? value.toString() : value);
// prettier-ignore
export const serialize = data => Object.entries(data)
  .reduce((a, [k, v]) => ({ ...a, [k]: serializeArray(k, v) }), {});

const parseArray = value => (value.length > 0 ? value.split(',') : []);
// prettier-ignore
export const parse = response => Object.entries(response)
  .reduce((a, [k, v]) => ({
    ...a,
    [k]: KEYS_FOR_ARRAYS.includes(k) ? parseArray(v) : v,
  }), {});
