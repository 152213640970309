import valet from '~/store/cards/valets/cardsValet';
import initialState from '~/store/cards/valets/cardsInitialState';
import * as CONST from '~/store/cards/constants';

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.CARDS_SELECT:
      return {
        ...state,
        selectedID: action.payload,
      };
    default:
      return valet.perform(state, action);
  }
};

export default cardsReducer;
