import { createSelector } from 'reselect';

import { CARDNAV_AUTH_ENABLED } from '~/constants/cardnav';

import selectCardnavCards from './selectCardnavCards';

// prettier-ignore
const selectCardnavCardMap = createSelector(
  selectCardnavCards,
  cardnavCards => (cardnavCards || []).reduce(
    (acc, { id, controlPolicy: { authEnabledBitmap } }) => {
      // prettier-ignore
      // eslint-disable-next-line no-bitwise
      const transactionLimitCount = (authEnabledBitmap & CARDNAV_AUTH_ENABLED.TRANSACTION_THRESHOLD) > 0
        ? 1 : 0;

      // prettier-ignore
      // eslint-disable-next-line no-bitwise
      const monthlyTransactionLimitCount = (authEnabledBitmap & CARDNAV_AUTH_ENABLED.MONTHLY_SPEND_THRESHOLD) > 0
        ? 1 : 0;

      return ({
        ...acc,
        [id]: transactionLimitCount + monthlyTransactionLimitCount,
      });
    },
    {}
  )
);

export default selectCardnavCardMap;
