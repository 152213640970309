import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/jsx-one-expression-per-line */
// eslint-disable-next-line camelcase
const Suggestion = ({ street_line: street, city, state }) => (
  <div>
    {street}&nbsp;&nbsp;
    <small>
      {city}, {state}
    </small>
  </div>
);

Suggestion.propTypes = {
  street_line: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default Suggestion;
