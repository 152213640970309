import { title } from '~/common/format/title';
import map from './map';

const hoc = fn => product => fn(map(product));

export default {
  '0000000000': hoc(({ accountNumber }) => `${accountNumber}`),
  '0000000000-S00': hoc(({ accountNumber, suffix }) => `${accountNumber}-${suffix}`),
  '0000000000-S00 - NAME': hoc(({ accountNumber, suffix, name }) => `${accountNumber}-${suffix} - ${name}`),
  '0000000000-S00 - Name': hoc(({ accountNumber, suffix, name }) => `${accountNumber}-${suffix} - ${title(name)}`),
  '*000': hoc(({ maskedAccountNumber }) => `${maskedAccountNumber}`),
  '*000-S00': hoc(({ maskedAccountNumber, suffix }) => `${maskedAccountNumber}-${suffix}`),
  '*000-S00 - NAME': hoc(({ maskedAccountNumber, suffix, name }) => `${maskedAccountNumber}-${suffix} - ${name}`),
  '*000-S00 - Name': hoc(
    ({ maskedAccountNumber, suffix, name }) => `${maskedAccountNumber}-${suffix} - ${title(name)}`
  ),
  '[*000-]S00': hoc(
    ({
      isCrossAccount,
      maskedAccountNumber,
      suffix,
    }) => `${isCrossAccount ? `${maskedAccountNumber}-` : ''}${suffix}`
  ),
  '[*000-]S00 - NAME': hoc(
    ({
      isCrossAccount,
      maskedAccountNumber,
      suffix,
      name,
    }) => `${isCrossAccount ? `${maskedAccountNumber}-` : ''}${suffix} - ${name}`
  ),
  '[*000-]S00 - Name': hoc(
    ({
      isCrossAccount,
      maskedAccountNumber,
      suffix,
      name,
    }) => `${isCrossAccount ? `${maskedAccountNumber}-` : ''}${suffix} - ${title(name)}`
  ),
  S00: hoc(({ suffix }) => `${suffix}`),
  'S00 - NAME': hoc(({ suffix, name }) => `${suffix} - ${name}`),
  'S00 - Name': hoc(({ suffix, name }) => `${suffix} - ${title(name)}`),
  'S00 *000': hoc(({ maskedAccountNumber, suffix }) => `${suffix} ${maskedAccountNumber}`),
  NAME: hoc(({ name }) => `${name}`),
  description: hoc(({ nickname, description }) => nickname || title(description)),
  Name: hoc(({ name }) => `${title(name)}`),
};
