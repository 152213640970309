import { createSelector } from 'reselect';

import PAGE from '../constants/page';

import selectAllPages from './selectAllPages';
import selectPageByID from './selectPageByID';

const lower = s => (typeof s === 'string' ? s.toLowerCase() : s);
const equal = (a, b) => (a instanceof RegExp ? a.test(b) : lower(a) === lower(b));

const selectPageByPath = path => createSelector(
  selectAllPages,
  selectPageByID(PAGE.NOT_FOUND),
  (pages, notFound) => pages.find(p => equal(p.path, path)) || Object.assign({}, notFound, { path })
);

export default selectPageByPath;
