import { createSelector } from 'reselect';

import selectUser from './selectUser';

const selectAllUserEntitlements = createSelector(
  selectUser,
  user => user.entitlements || {}
);

export default selectAllUserEntitlements;
