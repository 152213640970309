import { createValet } from '~/common/redux/valet';
import * as CONST from '~/store/credit-cards/constants';
import history from '~/store/history';

import initialState from './creditCardsInitialState';

const creditCardsValet = createValet({
  defaultReducer: 'creditCardData',
  history,
  initialState,
  msCacheDuration: 20 * 60 * 1000, // 20 minutes
  GET: CONST.CREDIT_CARDS_GET,
  ERROR: CONST.CREDIT_CARDS_GET_ERROR,
  SUCCESS: CONST.CREDIT_CARDS_GET_SUCCESS,
  RESET: CONST.CREDIT_CARDS_RESET,
  EXPIRE: CONST.CREDIT_CARDS_EXPIRE,
});

export default creditCardsValet;
