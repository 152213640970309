import { createValet } from '~/common/redux/valet';

import history from '~/store/history';

import initialState from './suffixesInitialState';

import * as CONST from '../constants';

export default createValet({
  defaultReducer: 'suffixes',
  history,
  initialState,
  msCacheDuration: 20 * 60 * 1000, // 20 minutes
  GET: CONST.SUFFIXES_GET,
  ERROR: CONST.SUFFIXES_GET_ERROR,
  SUCCESS: CONST.SUFFIXES_GET_SUCCESS,
  RESET: CONST.SUFFIXES_RESET,
  EXPIRE: CONST.SUFFIXES_EXPIRE,
});
