import React from 'react';
import PropTypes from 'prop-types';

import ContentMenuItem from './ContentMenuItem';
import './styles.scss';

const ContentMenu = ({ items }) => (
  <div className="content-menu">
    {(items || [])
      .filter(i => i.visible !== false)
      .map(item => (
        <ContentMenuItem key={item.id} {...item} />
      ))}
  </div>
);

ContentMenu.propTypes = { items: PropTypes.arrayOf(PropTypes.shape(ContentMenuItem.propTypes)) };

ContentMenu.defaultProps = { items: [] };

export default ContentMenu;
