import valet from '~/store/suffixes/valets/suffixesValet';
import initialState from '~/store/suffixes/valets/suffixesInitialState';

import * as CONST from '../constants';

const mergeSuffixBalances = (state, action) => {
  const { payload: balances } = action;
  // eslint-disable-next-line eqeqeq
  const findSuffix = id => balances.find(({ id: b }) => b == id);
  const getBalance = id => {
    const { balance } = findSuffix(id) || {};
    return balance;
  };
  const getAvailable = id => {
    const { available } = findSuffix(id) || {};
    return available;
  };
  return {
    ...state,
    data: (state.data || []).map(suffix => ({
      ...suffix,
      available: getAvailable(suffix.id),
      balance: getBalance(suffix.id),
    })),
  };
};

const suffixesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SUFFIXES_GET_BALANCE_SUCCESS:
      return mergeSuffixBalances(state, action);
    default:
      return valet.perform(state, action);
  }
};

export default suffixesReducer;
