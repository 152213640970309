export const INAUTH_START = 'InAuth: Start';
export const INAUTH_INMOBILE = 'InAuth: InMobile';
export const INAUTH_INBROWSER = 'InAuth: InBrowser';
export const INAUTH_COMPLETE_FAILURE = 'InAuth: Complete: Failure';
export const INAUTH_COMPLETE_SUCCESS = 'InAuth: Complete: Success';
export const INAUTH_EXPIRE = 'InAuth: Expire';
export const INAUTH_RESET = 'InAuth: Reset';

export const INMOBILE_TIMEOUT = 10000;
export const INBROWSER_TIMEOUT = 7000;
