import moment from 'moment';

export const ACCOUNT_HISTORY_SET = 'AccountHistory: Set';
export const ACCOUNT_HISTORY_CLEAR = 'AccountHistory: Clear';

export const ACCOUNT_HISTORY_LOADING_STATE = {
  PENDING: 'PENDING',
  PARTIAL: 'PARTIAL',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
};

export const DATE_FORMAT = 'YYYYMMDD';

export const TIMEFRAME = {
  MONTH: [
    moment()
      .subtract(1, 'month')
      .format(DATE_FORMAT),
    moment().format(DATE_FORMAT),
  ],
  THREE_MONTHS: [
    moment()
      .subtract(3, 'month')
      .format(DATE_FORMAT),
    moment().format(DATE_FORMAT),
  ],
  SIX_MONTHS: [
    moment()
      .subtract(6, 'month')
      .format(DATE_FORMAT),
    moment().format(DATE_FORMAT),
  ],
  YEAR: [
    moment()
      .subtract(1, 'year')
      .format(DATE_FORMAT),
    moment().format(DATE_FORMAT),
  ],
};

export const TRANSACTION_TYPE = {
  ANY: 'Any',
  DEPOSIT: 'Deposits',
  WITHDRAWAL: 'Withdrawals',
  PAYMENT: 'Payments',
  ADVANCE: 'Advances',
  TRANSFER: 'Transfers',
  CHECK: 'Checks',
  ATM: 'ATM Transactions',
  ACH: 'ACH Transactions',
};
