import * as CONST from '../constants';

const enqueueWorkflow = ({ item }) => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.WORKFLOW_ENQUEUE,
    payload: { item },
  })
);

export default enqueueWorkflow;
