// https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0

export default s => {
  let h = 0;
  const l = (s || '').length;
  let i = 0;

  // eslint-disable-next-line no-bitwise, no-mixed-operators
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};
