import { createSelector } from 'reselect';

import selectUser from './selectUser';

const selectUserType = type => createSelector(
  selectUser,
  user => user.userType === type
);

export default selectUserType;
