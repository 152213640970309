import { apiProvider } from '@ent/browser';

import { selectRequestTokens, selectCsrfToken } from '~/store/user';

import { GET_EXTERNAL_ACCOUNTS } from '../constants';

const getExternalAccounts = ({ autoPublishMessages } = {}) => async (dispatch, getState) => {
  const state = getState();
  const csrfToken = selectCsrfToken(state);
  const requestTokens = selectRequestTokens(state);

  const { accounts: externalAccounts } = await apiProvider({
    method: 'GET',
    url: '/Banking/api/transfers/accounts/external',
    csrfToken,
    requestTokens,
    autoPublishMessages,
  });

  dispatch({ type: GET_EXTERNAL_ACCOUNTS, payload: externalAccounts });
};

export default getExternalAccounts;
