import { createValet } from '~/common/redux/valet';
import * as CONST from '~/store/sms/constants';
import history from '~/store/history';

import initialState from './smsInitialState';

const smsValet = createValet({
  defaultReducer: 'cards',
  history,
  initialState,
  msCacheDuration: 20 * 60 * 1000, // 20 minutes
  GET: CONST.SMS_GET,
  ERROR: CONST.SMS_GET_ERROR,
  SUCCESS: CONST.SMS_GET_SUCCESS,
  RESET: CONST.SMS_RESET,
  EXPIRE: CONST.SMS_EXPIRE,
});

export default smsValet;
