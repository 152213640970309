import { createSelector } from 'reselect';

import selectAllUserRoles from './selectAllUserRoles';

const selectUserRole = role => createSelector(
  selectAllUserRoles,
  roles => roles.some(r => r === role)
);

export default selectUserRole;
