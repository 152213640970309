import React from 'react';
import PropTypes from 'prop-types';
import { once } from '@ent/functional';

import decorator from './decorators';

export default ({ selector, url, message }) => BaseComponent => {
  const log = once(err => {
    console.error(err); // eslint-disable-line no-console
  });

  const RequirePermission = props => {
    const { permission, production, addMessage, redirect } = props;

    if (!production && typeof permission !== 'boolean') {
      log(
        new Error(
          [
            `requirePermission<${BaseComponent.name}> selector expected boolean,`,
            `but received ${typeof permission}: ${permission}`,
          ].join(' ')
        )
      );
    }

    if (permission === false) {
      setTimeout(() => {
        if (url) {
          redirect(url);
        }
        if (message) {
          addMessage(typeof message === 'string' ? { text: message } : message);
        }
      }, 0);
    }

    return <BaseComponent {...props} />;
  };

  RequirePermission.propTypes = {
    // react-redux
    permission: PropTypes.bool.isRequired,
    production: PropTypes.bool.isRequired,
    // with-messages
    addMessage: PropTypes.func.isRequired,
    // withRouter
    redirect: PropTypes.func.isRequired,
  };

  RequirePermission.defaultProps = {};

  const componentName = BaseComponent.displayName || BaseComponent.name || 'Component';
  RequirePermission.displayName = `RequirePermission(${componentName})`;

  return decorator(selector)(RequirePermission);
};
