import { createSelector } from 'reselect';

import { Format, Parse } from '~/common/data-tada';

import selectAllCards from './selectAllCards';

const parse = {
  date: Parse.date({ required: true })
    .catch(Format.date.invalid)
    .then(Format.date.date)
    .value.resolved(),
};

const selectUnexpiredCards = createSelector(selectAllCards, cards => {
  const filter = s => !s.expirationDate || parse.date(s.expirationDate) >= new Date();
  return cards.filter(filter);
});

export default selectUnexpiredCards;
