import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MESSAGE_TYPE } from '~/constants';
import { ExpandTelephoneNumber } from '../ExpandTelephoneNumber';

const isAssertive = type => type === MESSAGE_TYPE.ERROR || type === MESSAGE_TYPE.WARNING;
const role = type => (isAssertive(type) ? 'alert' : 'status');

const rootClasses = type => classNames({
  'message-queue-item alert alert-dismissable': true,
  'alert-primary': type === MESSAGE_TYPE.PRIMARY,
  'alert-success': type === MESSAGE_TYPE.SUCCESS,
  'alert-info': type === MESSAGE_TYPE.INFO,
  'alert-warning': type === MESSAGE_TYPE.WARNING,
  'alert-danger': type === MESSAGE_TYPE.ERROR,
});

const iconClasses = type => classNames({
  'fa fa-fw': true,
  'fa-comment': type === MESSAGE_TYPE.PRIMARY,
  'fa-check': type === MESSAGE_TYPE.SUCCESS,
  'fa-info-circle': type === MESSAGE_TYPE.INFO,
  'fa-exclamation-triangle': type === MESSAGE_TYPE.WARNING,
  'fa-exclamation-circle': type === MESSAGE_TYPE.ERROR,
});

class MessageQueueItem extends Component {
  // eslint-disable-next-line no-unused-vars
  click = event => {
    const { item, close } = this.props;
    close(item);
  };

  render() {
    const {
      item: { type, text },
      displayClose,
      displayIcon,
    } = this.props;
    return (
      <div className={rootClasses(type)} role={role(type)} aria-live="polite">
        <span>
          {displayIcon && <i className={iconClasses(type)} />}
          <ExpandTelephoneNumber text={text} />
        </span>
        {displayClose && (
          <button type="button" className="close" onClick={this.click} title="Close">
            <i className="fa fa-fw fa-close" />
          </button>
        )}
      </div>
    );
  }
}

MessageQueueItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    owner: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(MESSAGE_TYPE).map(k => MESSAGE_TYPE[k])),
    text: PropTypes.node.isRequired,
  }).isRequired,
  displayClose: PropTypes.bool,
  displayIcon: PropTypes.bool,
  close: PropTypes.func,
};

MessageQueueItem.defaultProps = {
  displayClose: true,
  displayIcon: false,
  close: () => {},
};

export default MessageQueueItem;
