import { compose } from 'recompose';
import { connect } from 'react-redux';
import { BOOTSTRAP_SIZE } from '@ent/browser';

import { storagePersist } from '~/common';
import { withRouter } from '~/hocs/withRouter';
import { getEnv, selectSmartyStreetsKey } from '~/store/env';
import {
  toggleSideNav,
  selectBootstrapSize,
  selectIsBootstrapSize,
  selectShowSideNav,
  selectMigrationStart,
} from '~/store/ui';
import { selectIsAuthenticated, selectUser } from '~/store/user';
import { selectCurrentPageHierarchy, selectCurrentPage } from '~/store/sitemap';

const select = state => ({
  authenticated: selectIsAuthenticated(state),
  showSideNav: selectShowSideNav(state),
  bsSize: selectBootstrapSize(state),
  xl: selectIsBootstrapSize(BOOTSTRAP_SIZE.XL)(state),
  user: selectUser(state),
  page: selectCurrentPage(state),
  hierarchy: selectCurrentPageHierarchy(state),
  smartyStreetsKey: selectSmartyStreetsKey(state),
  migrationStart: selectMigrationStart(state),
});

const boundActions = {
  toggleSideNav,
  getEnv,
  storagePersist,
};

export default compose(connect(select, boundActions), withRouter());
