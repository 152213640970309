import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { identity } from '@ent/functional';

import { TextField, Validator } from '@ent/fields';
import SecurePasswordHelp from './SecurePasswordHelp';
import { PASSWORD_VALIDATORS, getPasswordValidatorMap } from './form';

import './styles.scss';

const STATUS_MAP = [
  [0.5, 'Weak Password'],
  [0.7, 'Fair Password'],
  [0.8, 'Good Password'],
  [1, 'Excellent Password'],
];

const PasswordField = ({ name, label, registrationKey, ...props }) => {
  const [percent, setPercent] = useState(0);
  const percentage = Math.min(parseInt(percent * 100, 10), 100);
  const [, statusText] = STATUS_MAP.find(([v]) => percent <= v) || [];
  const [{ value }] = useField(name);
  const passwordValidatorMap = getPasswordValidatorMap(registrationKey);

  useEffect(() => {
    Promise.all(
      Object.values(passwordValidatorMap).map(validator => validator.isValid(value))
    ).then(results => {
      const successes = results.filter(identity);
      const validatorPercent = successes.length / results.length;
      const lengthPercent = successes.length === results.length
        ? Math.min(value.length / 24, 1)
        : 0;
      setPercent((validatorPercent + lengthPercent) * 0.5);
    });
  }, [value]);

  return (
    <div className="PasswordField">
      <TextField name={name} label={label} type="password" maxLength="50" {...props} />
      <div className="validator-group">
        <div className="progress-wrapper clearfix">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${percentage}%` }}
              aria-valuenow={percentage}
              aria-valuemin="0"
              aria-valuemax="100" />
          </div>
          <div className="progress-status">{statusText}</div>
        </div>

        <Validator
          value={value}
          validator={passwordValidatorMap[PASSWORD_VALIDATORS.LENGTH]}>
          Between 8 and 50 characters in length
        </Validator>
        <Validator
          value={value}
          validator={passwordValidatorMap[PASSWORD_VALIDATORS.NUMBER_REQUIRED]}>
          Number
        </Validator>
        <Validator
          value={value}
          validator={
            passwordValidatorMap[PASSWORD_VALIDATORS.CAPITAL_LETTER_REQUIRED]
          }>
          Uppercase letter
        </Validator>
        <Validator
          value={value}
          validator={passwordValidatorMap[PASSWORD_VALIDATORS.LETTER_REQUIRED]}>
          Lowercase letter
        </Validator>
        <Validator
          value={value}
          validator={
            passwordValidatorMap[PASSWORD_VALIDATORS.SPECIAL_CHARACTER_REQUIRED]
          }>
          Special character
          {' '}
          {"('~!@#$%^&*()_+-{}|;:?.,/\")"}
        </Validator>
        <Validator
          value={value}
          validator={
            passwordValidatorMap[PASSWORD_VALIDATORS.SECURE_PASSWORD_REQUIRED]
          }>
          Strong password
          <SecurePasswordHelp placement="right" />
        </Validator>
      </div>
    </div>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  registrationKey: PropTypes.string.isRequired,
};

export default PasswordField;
