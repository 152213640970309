import { LOCATION_CHANGE } from 'connected-react-router';
import * as CONST from '~/store/ui/constants';

import initialState from './uiInitialState';

const initialize = (state, action) => {
  const { bootstrapSize } = action.payload;
  return Object.assign({}, state, { bootstrapSize });
};

const resize = (state, action) => {
  const { bootstrapSize } = action.payload;
  return Object.assign({}, state, { bootstrapSize });
};

const migrate = (state, action) => {
  const { migrationStart } = action.payload;
  return Object.assign({}, state, { migrationStart });
};

const activate = (state, action) => Object.assign({}, state, { active: action.payload });

const toggleSideNav = (state, action) => ({
  ...state,
  showSideNav: action === undefined || action.payload === undefined ? !state.showSideNav : !!action.payload,
});

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.UI_INITIALIZE:
      return initialize(state, action);
    case CONST.UI_RESIZE:
      return resize(state, action);
    case CONST.UI_ACTIVATE:
      return activate(state, action);
    case CONST.UI_TOGGLE_SIDE_NAV:
      return toggleSideNav(state);
    case LOCATION_CHANGE:
      return toggleSideNav(state, { payload: false });
    case CONST.UI_MIGRATION_START:
      return migrate(state, action);
    default:
      return state;
  }
};

export default uiReducer;
