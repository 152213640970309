import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import { Button } from '@ent/fields';

import './styles.scss';

const onClick = event => {
  event.preventDefault();
  event.nativeEvent.stopImmediatePropagation();
  return false;
};

const Help = ({ icon, id, title, placement, children, className, ...other }) => {
  const popover = (
    <Popover id={id} title={title} placement={placement} {...other}>
      {children}
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" rootClose placement={placement} overlay={popover} onClick={onClick}>
      <Button className={`help ${className || ''}`} kind="icon" title={title} aria-label={title}>
        <i className={`fa fa-fw ${icon}`} aria-hidden="true" />
      </Button>
    </OverlayTrigger>
  );
};

Help.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.any.isRequired,
  placement: PropTypes.string,
};

Help.defaultProps = {
  className: '',
  icon: 'fa-question',
  id: shortid.generate(),
  title: 'Help',
  placement: 'bottom',
};

export default Help;
