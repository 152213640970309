import * as Yup from 'yup';

export const getInitialValues = initialValues => ({
  username: {
    display: '',
    value: '',
  },
  rememberMe: false,
  quickBalance: false,
  ...initialValues,
});

export const validationSchema = Yup.object({
  username: Yup.object({
    display: Yup.string()
      .required('Required')
      .min(3, 'Too short')
      .max(34, 'Too long')
      .matches(/^[\w.@-]+\**[\w.@-]+$/, 'Invalid'),
    value: Yup.string(),
  }),
  rememberMe: Yup.bool(),
  quickBalance: Yup.bool(),
});

export const normalizer = {
  // prettier-ignore
  username: value => (value.includes('*') ? '' : value.replace(/[^\w.@-]/g, '').slice(0, 34)),
};
