import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withPageState } from '@ent/redux-pagestate';
import classNames from 'classnames';
import { device } from '@ent/browser';

import { SUFFIX_TYPE, ENT_MOBILE_ACTION } from '~/constants';
import { encrypt, Storage, TopNav, TopNavLink, updateChatInformation, entMobile } from '~/common';
import { withApi, withMessages, withRouter } from '~/hocs';
import { LoginUsername } from '~/components/LoginUsername';
import { QuickBalanceList } from '~/components/QuickBalanceList';
import { OutdatedEntMobileMessage } from '~/components/OutdatedEntMobileMessage';
import { WebBeacon } from '../../components/WebBeacon';
import { COOKIE } from '~/store/cookie';

import connectConfig from './connect';
import pageStateConfig from './page-state';
import Links from './Links';
import './styles.scss';

class LoginUsernameContent extends Component {
  state = {
    suffixes: undefined,
    qbView: false,
    processingCookies: true,
  };

  async componentDidMount() {
    const { getUser, initCookies, redirect } = this.props;
    this.isEntMobileOutdated();
    await Promise.all([
      getUser({
        force: true,
        redirectOn403: false,
        autoUpdateWorkflows: false,
      }),
      initCookies(),
    ]);
    const { authenticated } = this.props;
    if (authenticated) {
      redirect('/Banking/app/accounts');
    }
    this.setState({ processingCookies: false });
    this.initQB();
  }

  componentDidUpdate(prevProps) {
    const { qbEnabled } = this.props;
    if (!prevProps.qbEnabled && qbEnabled) {
      this.initQB();
    }
  }

  reload = () => {
    const { suffixes } = this.state;
    const suffix = (suffixes || []).find(s => s.transactions instanceof Array) || {};
    this.setState({ suffixes: undefined });
    this.loadSuffixes(suffix.id);
  };

  disableQB = ({ aborted } = {}) => {
    if (!aborted) {
      const { pageState, setPageState, removeCookie } = this.props;
      removeCookie({ key: COOKIE.QUICK_BALANCE });
      setPageState({
        ...pageState,
        quickBalance: false,
      });
      this.setState({ suffixes: undefined, qbView: false });
    }
  };

  loadSuffixes = async suffixID => {
    const { api } = this.props;
    try {
      const { suffixes } = await api({
        url: '/Banking/api/quickbalance/suffixes',
        method: 'POST',
        data: {},
      });
      this.setState({ suffixes });

      const first = type => suffixes.find(s => s.type === type);
      const suffix = suffixes.find(s => s.id === suffixID) || first(SUFFIX_TYPE.CHECKING) || first(SUFFIX_TYPE.SAVINGS);
      if (suffix) {
        this.loadSuffix(suffix.id);
      }
    } catch (e) {
      this.disableQB(e);
    }
  };

  loadSuffix = async id => {
    const { api } = this.props;
    const { suffixes: curSuffixes } = this.state;
    const skip = !(curSuffixes || []).some(s => s.id === id);
    if (skip) return;

    try {
      this.setState({ suffixes: curSuffixes.map(s => (s.id === id ? { ...s, transactions: 'Loading' } : s)) });
      const { suffix, transactions } = await api({
        url: `/Banking/api/quickbalance/${id}/history`,
        method: 'POST',
        data: {},
      });

      const suffixes = curSuffixes.map(s => (s.id === id ? { ...suffix, transactions } : { ...s, transactions: null }));
      this.setState({ suffixes });
    } catch (e) {
      const suffixes = curSuffixes.filter(s => s.id !== id);
      if (!suffixes.length) {
        this.disableQB(e);
        return;
      }
      this.setState({ suffixes });
    }
  };

  setQbView = qbView => () => this.setState({ qbView });

  submit = async values => {
    const { addMessage, api, cookies, redirect, returnUrl, setPageState } = this.props;
    const { hash } = cookies || {};
    const { username, rememberMe, quickBalance } = values;
    const user = username.value || username.display;

    try {
      const { image, phrase, showChatBubble, isMigrated, redirectUrl } = await api({
        url: '/Banking/api/authentication/reverse',
        method: 'POST',
        data: { username: user },
      });

      if (isMigrated) {
        const { isEntMobile } = device;
        if (isEntMobile) {
          entMobile.dispatch({
            type: ENT_MOBILE_ACTION.BACKBASE_REDIRECT,
            payload: { },
          });
        } else {
          redirect(redirectUrl);
        }
      }
      if (returnUrl) {
        Storage.session.setItem('returnUrl', returnUrl);
      }
      setPageState({
        ...pageStateConfig.value,
        username,
        rememberMe: rememberMe ? encrypt(hash, user) : undefined,
        quickBalance,
        image,
        phrase,
        showChatBubble,
      });
      updateChatInformation(user, showChatBubble);
      redirect('/Banking/app/login/password');
    } catch (e) {
      addMessage({ text: e.message });
    }
  };

  isEntMobileOutdated = () => {
    const { addMessage } = this.props;
    const { entMobileOutdated, isEntMobileIos, isEntMobileAndroid } = device;

    if (entMobileOutdated) {
      addMessage({
        text: <OutdatedEntMobileMessage entMobileIos={isEntMobileIos} entMobileAndroid={isEntMobileAndroid} />,
        autoClose: false,
      });
    }

    return entMobileOutdated;
  };

  registerClick = () => {
    const { redirect } = this.props;
    redirect('/Banking/app/registration');
  };

  removeQuickBalanceCookie = () => {
    const { removeCookie } = this.props;
    removeCookie({ key: COOKIE.QUICK_BALANCE });
  };

  removeRememberMeCookie = () => {
    const { removeCookie } = this.props;
    removeCookie({ key: COOKIE.REMEMBER_ME });
    this.removeQuickBalanceCookie();
  };

  initQB() {
    const { qbEnabled, setPageState } = this.props;
    setPageState({
      ...pageStateConfig.value,
      quickBalance: qbEnabled,
    });

    if (qbEnabled) {
      this.loadSuffixes();
    }
  }

  render() {
    const { entMobileOutdated } = device;
    const { initialValues, pageState, qbEnabled, startInAuth, forgotUrl } = this.props;
    const { quickBalance } = pageState;
    const { processingCookies, suffixes, qbView } = this.state;

    const classes = classNames('LoginUsernameContent col-xs-12 col-narrow', { qbEnabled });
    if (processingCookies) return <div className={classes} />;

    return (
      <div className={classes}>
        <WebBeacon />
        <TopNav>
          <TopNavLink className={!qbView ? 'active' : ''} onClick={this.setQbView(false)}>
            {!qbView && <h1>Log In</h1>}
            {qbView && <span>Log In</span>}
          </TopNavLink>
          {qbEnabled && (
            <TopNavLink className={qbView ? 'active' : ''} onClick={this.setQbView(true)}>
              {qbView && <h1>Quick Balance</h1>}
              {!qbView && <span>Quick Balance</span>}
            </TopNavLink>
          )}
        </TopNav>
        {qbView && <QuickBalanceList suffixes={suffixes} loadSuffix={this.loadSuffix} reload={this.reload} />}
        {!qbView && (
          <LoginUsername
            initialValues={initialValues}
            loginRecoverUrl={forgotUrl}
            onRegisterClick={this.registerClick}
            quickBalanceEnabled={quickBalance}
            onSubmit={this.submit}
            removeQuickBalanceCookie={this.removeQuickBalanceCookie}
            removeRememberMeCookie={this.removeRememberMeCookie}
            startInAuth={startInAuth}
            entMobileOutdated={entMobileOutdated} />
        )}
        <Links />
      </div>
    );
  }
}

LoginUsernameContent.propTypes = {
  // connect
  authenticated: PropTypes.bool.isRequired,
  cookies: PropTypes.object,
  getUser: PropTypes.func.isRequired,
  initCookies: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  qbEnabled: PropTypes.bool,
  removeCookie: PropTypes.func.isRequired,
  returnUrl: PropTypes.string.isRequired,
  startInAuth: PropTypes.func.isRequired,
  forgotUrl: PropTypes.string.isRequired,
  // withApi
  api: PropTypes.func.isRequired,
  // withPageState
  pageState: PropTypes.object.isRequired,
  setPageState: PropTypes.func.isRequired,
  // withMessages
  addMessage: PropTypes.func.isRequired,
  // withRouter
  redirect: PropTypes.func.isRequired,
};

LoginUsernameContent.defaultProps = {
  cookies: undefined,
  qbEnabled: undefined,
};

export default compose(
  connect(...connectConfig),
  withApi(),
  withPageState(pageStateConfig),
  withMessages({ clearOnUnload: true }),
  withRouter(),
)(LoginUsernameContent);
