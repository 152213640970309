import { createSelector } from 'reselect';

import selectWorkflows from './selectWorkflows';
import peekWorkflowAtIndex from './peekWorkflowAtIndex';

export default createSelector(
  selectWorkflows,
  peekWorkflowAtIndex(1),
  (workflows, next) => (next ? next.urls[0] : workflows.finishUrl),
);
