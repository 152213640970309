import { identity } from '@ent/functional';

import { ACTION } from '../constants';

const initialState = {
  personal: {},
  joint: {},
  joints: [],
  accountSetup: {},
};

const actionMap = {
  [ACTION.SET_PERSONAL_INFO]: (state, { payload }) => ({
    ...state,
    personal: {
      ...(state || {}).personal,
      ...payload,
    },
  }),
  [ACTION.SET_JOINT_INFO]: (state, { payload }) => ({
    ...state,
    joint: {
      ...(state || {}).joint,
      ...payload,
    },
  }),
  [ACTION.ADD_JOINT]: (state, payload) => ({
    ...state,
    joints: [
      ...state.joints.filter((_, i) => payload === undefined || payload === null || i !== payload),
      { ...state.joint },
    ],
    joint: {},
  }),
  [ACTION.EDIT_JOINT_INFO]: (state, payload) => ({
    ...state,
    joint: { ...state.joints[payload] || {} },
  }),
  [ACTION.SET_ACCOUNT_SETUP_INFO]: (state, payload) => ({
    ...state,
    accountSetup: {
      ...state.accountSetup || {},
      ...payload,
    },
  }),
};

export default (state = initialState, action) => {
  const handler = actionMap[action.type] || identity;
  return handler(state, action);
};
