import { selectPrimaryAccountNumber } from '~/store/user/selectors';

export default ({ accountNumber, suffix, micr, description, nickname, available } = {}) => {
  const store = window.store || require('~/store').store; // eslint-disable-line global-require
  const primaryAccountNumber = store ? selectPrimaryAccountNumber(store.getState()) : '';

  const match = (suffix || '').match(/([SLM])\d*(\d{2})$/);

  const result = {
    isCrossAccount: !!accountNumber,
    accountNumber: accountNumber || primaryAccountNumber,
    suffix: match ? `${match[1]}${match[2]}` : suffix,
    micr,
    description,
    nickname,
    name: nickname || description,
    available,
  };
  result.maskedAccountNumber = `*${(result.accountNumber || '').slice(-3)}`;
  return result;
};
