import { createSelector } from 'reselect';

import selectAllMessages from './selectAllMessages';

const selectFirstMessageByType = type => createSelector(
  selectAllMessages,
  messages => messages.find(m => m.type === type)
);

export default selectFirstMessageByType;
