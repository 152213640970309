import selectPageByID from '../selectors/selectPageByID';
import selectReturnPage from '../selectors/selectReturnPage';
import PAGE from './page';

export default [
  {
    id: PAGE.ACCESS_DENIED,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Access Denied',
    path: '/Banking/app/access-denied',
  },
  {
    id: PAGE.ACCOUNTS,
    selectParent: () => null,
    title: 'Accounts',
    path: '/Banking/app/accounts',
  },
  {
    id: PAGE.ACCOUNTS_DETAIL,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'History',
    path: '/Banking/app/accounts/detail',
  },
  {
    id: PAGE.MORTGAGE_ESCROW,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Mortgage Escrow',
    path: '/Banking/app/accounts/detail/escrow',
  },
  {
    id: PAGE.ACCOUNTS_TRANSACTION_DETAIL,
    selectParent: selectPageByID(PAGE.ACCOUNTS_DETAIL),
    title: 'Transaction Detail',
    path: '/Banking/app/accounts/transaction',
  },
  {
    id: PAGE.ACCOUNTS_DETAIL_LOAN_PAYOFF,
    selectParent: selectPageByID(PAGE.ACCOUNTS_DETAIL),
    title: 'Loan Calculator',
    path: '/Banking/app/accounts/detail/loan-payoff',
  },
  {
    id: PAGE.ACCOUNTS_DETAIL_LOAN_PAYOFF_PRINT,
    selectParent: () => null,
    title: 'Loan Calculator',
    path: '/Banking/app/accounts/detail/loan-payoff/print',
  },
  {
    id: PAGE.ACCOUNT_FAVORITES,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Account Favorites',
    path: '/Banking/app/accounts/favorites',
  },
  {
    id: PAGE.ACCOUNTS_DETAIL_PRINT,
    selectParent: () => null,
    title: 'Print Account Details',
    path: '/Banking/app/accounts/detail/print',
  },
  {
    id: PAGE.ACCOUNTS_DETAIL_CHECK_PRINT,
    selectParent: () => null,
    title: 'Print Check',
    path: '/Banking/app/accounts/detail/checks/print',
  },
  {
    id: PAGE.ACCOUNTS_DETAIL_TRANSACTION_PRINT,
    selectParent: () => null,
    title: 'Print Transaction Details',
    path: '/Banking/app/accounts/detail/transaction/print',
  },
  {
    id: PAGE.ACCOUNTS_CHECK_PRINT,
    selectParent: () => null,
    title: 'Print Checks',
    path: '/Banking/app/accounts/check/print',
  },
  {
    id: PAGE.ACCOUNTS_TRANSACTION_PRINT,
    selectParent: () => null,
    title: 'Print Transactions',
    path: '/Banking/app/accounts/transaction/print',
  },
  {
    id: PAGE.ACCOUNT_LOCKED,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Account Locked',
    path: '/Banking/app/account-locked',
  },
  {
    id: PAGE.ADDRESS_PHONE,
    selectParent: selectPageByID(PAGE.CONTACT_INFO),
    title: 'Address & Phone',
    path: '/Banking/app/profile/address-phone',
  },
  {
    id: PAGE.BILL_PAY_PAYMENT,
    selectParent: selectReturnPage(PAGE.BILL_PAY),
    title: 'Bill Pay',
    path: '/Banking/app/billpay/payment',
  },
  {
    id: PAGE.BILL_PAY_PAYMENT_HISTORY_UPCOMING,
    selectParent: selectReturnPage(PAGE.BILL_PAY),
    title: 'Upcoming Payments',
    path: '/Banking/app/billpay/history/upcoming',
  },
  {
    id: PAGE.BILL_PAY_PAYMENT_HISTORY_PROCESSED,
    selectParent: selectReturnPage(PAGE.BILL_PAY),
    title: 'Recent Payments',
    path: '/Banking/app/billpay/history/processed',
  },
  {
    id: PAGE.BILL_PAY_PAYMENT_HISTORY_EBILL,
    selectParent: selectReturnPage(PAGE.BILL_PAY),
    title: 'Upcoming Payments',
    path: '/Banking/app/billpay/history/ebill',
  },
  {
    id: PAGE.BILL_PAY_PAYEE_ADD,
    selectParent: selectReturnPage(PAGE.BILL_PAY_PAYEE_MANAGE),
    title: 'Add Payee',
    path: '/Banking/app/billpay/payee/add',
  },
  {
    id: PAGE.BILL_PAY_PAYEE_MANAGE,
    selectParent: selectReturnPage(PAGE.BILL_PAY),
    title: 'Payees',
    path: '/Banking/app/billpay/payee',
  },
  {
    id: PAGE.BILL_PAY_PAYEE_DETAILS,
    selectParent: selectReturnPage(PAGE.BILL_PAY_PAYEE_MANAGE),
    title: 'Manage Payee',
    path: '/Banking/app/billpay/payee/details',
  },
  {
    id: PAGE.BILL_PAY_EBILL_ENROLLMENT,
    selectParent: selectReturnPage(PAGE.BILL_PAY_PAYEE_DETAILS),
    title: 'EBill Enrollment',
    path: '/Banking/app/billpay/electronic-bill-enrollment',
  },
  {
    id: PAGE.BILL_PAY_EBILL_AUTOPAY,
    selectParent: selectReturnPage(PAGE.BILL_PAY_PAYEE_DETAILS),
    title: 'eBill Autopay',
    path: '/Banking/app/billpay/ebill/autopay',
  },
  {
    id: PAGE.BILL_PAY_EBILL_DETAILS,
    selectParent: selectReturnPage(PAGE.BILL_PAY),
    title: 'eBill Details',
    path: '/Banking/app/billpay/ebill/details',
  },
  {
    id: PAGE.BILL_PAY_PAYEE_ACTIVITY,
    selectParent: selectReturnPage(PAGE.BILL_PAY_PAYEE_MANAGE),
    title: 'Payee Activity',
    path: '/Banking/app/billpay/payee/activity',
  },
  {
    id: PAGE.BILL_PAY,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Bill Pay',
    path: '/Banking/app/billpay',
  },
  {
    id: PAGE.BILL_PAY_BLOCKED,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Bill Pay Blocked Enrollment',
    path: '/Banking/app/billpay/blocked',
  },
  {
    id: PAGE.BILL_PAY_ERROR,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Bill Pay Blocked Error',
    path: '/Banking/app/billpay/error',
  },
  {
    id: PAGE.BRONCOS_CONVERSION,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Broncos Conversion',
    path: '/Banking/app/broncos-checking/conversion',
  },
  {
    id: PAGE.CARDS,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Cards',
    path: '/Banking/app/services/cards',
  },
  {
    id: PAGE.CARD_ACTIVATE_AUTHENTICATED,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Activate Card',
    path: '/Banking/app/services/cards/activate',
  },
  {
    id: PAGE.CARD_ACTIVATE_UNAUTHENTICATED,
    selectParent: selectPageByID(PAGE.LOGIN),
    title: 'Activate Card',
    path: '/Banking/app/activate-card',
  },
  {
    id: PAGE.CARD_ALERTS,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Card Alert Preferences',
    path: '/Banking/app/services/cards/alerts',
  },
  {
    id: PAGE.CARD_FREEZE,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Freeze Card',
    path: '/Banking/app/services/cards/freeze',
  },
  {
    id: PAGE.CARD_RESTRICTION,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Restrict Transactions',
    path: '/Banking/app/services/cards/restriction',
  },
  {
    id: PAGE.CARD_SPENDING_LIMIT,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Set Spending Limit',
    path: '/Banking/app/services/cards/spending-limit',
  },
  {
    id: PAGE.CARD_TRAVEL_NOTE_EDIT,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Edit Travel Note',
    path: '/Banking/app/services/cards/travel/edit',
  },
  {
    id: PAGE.CARD_UNFREEZE,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Unfreeze Card',
    path: '/Banking/app/services/cards/unfreeze',
  },
  {
    id: PAGE.CERTIFICATE_DIVIDEND,
    selectParent: selectPageByID(PAGE.ACCOUNTS_DETAIL),
    title: 'Certificate Dividends',
    path: '/Banking/app/certificate/dividend',
  },
  {
    id: PAGE.CERTIFICATE_MATURITY,
    selectParent: selectPageByID(PAGE.ACCOUNTS_DETAIL),
    title: 'Certificate Maturity',
    path: '/Banking/app/certificate/maturity',
  },
  {
    id: PAGE.CARD_PIN_CHANGE,
    selectParent: selectPageByID(PAGE.CARDS),
    title: 'Pin Change',
    path: '/Banking/app/services/cards/pin/change',
  },
  {
    id: PAGE.CALL_CENTER_PASSWORD,
    selectParent: selectPageByID(PAGE.PROFILE),
    title: 'Call Center Password',
    path: '/Banking/app/profile/call-center-password',
  },
  {
    id: PAGE.CONTACT,
    selectParent: selectPageByID(PAGE.LOGIN),
    title: 'Contact Us',
    path: '/Banking/app/contact',
  },
  {
    id: PAGE.CONTACT_INFO,
    selectParent: selectPageByID(PAGE.PROFILE),
    title: 'Contact Info',
    path: '/Banking/app/profile/contact-info',
  },
  {
    id: PAGE.CREDIT_CARD_DETAIL,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Credit Card',
    path: '/Banking/app/credit-card/detail',
  },
  {
    id: PAGE.DESKTOP_DEPOSIT_CHECKS,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Desktop Deposit Checks',
    path: '/Banking/app/services/desktop-deposit-checks',
  },
  {
    id: PAGE.DISCLOSURE_ACCOUNT_AGREEMENT,
    selectParent: () => null,
    title: 'Disclosure',
    path: '/Banking/app/disclosure/AccountAgreement',
  },
  {
    id: PAGE.DOWNLOADS,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Downloads',
    path: '/Banking/app/services/downloads',
  },
  {
    id: PAGE.EMAIL,
    selectParent: selectPageByID(PAGE.CONTACT_INFO),
    title: 'Email',
    path: '/Banking/app/profile/email',
  },
  {
    id: PAGE.ENT_PROTECT_INFORMATION,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Ent Protect Information',
    path: '/Banking/app/ent-protect/information',
  },
  {
    id: PAGE.ENTPROTECT_ENROLL,
    selectParent: selectPageByID(PAGE.ENT_PROTECT_INFORMATION),
    title: 'Ent Protect Enroll',
    path: '/Banking/app/ent-protect/enroll',
  },
  {
    id: PAGE.ENTPROTECT_ENROLL_CONFIRMATION,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Ent Protect Enroll Confirmation',
    path: '/Banking/app/ent-protect/enroll/confirmation',
  },
  {
    id: PAGE.ENTPROTECT_PRODUCT_PAGE,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Ent Protect',
    path: '/Banking/app/services/ent-protect',
  },
  {
    id: PAGE.ENT_PROTECT_ENROLL_CONTACTINFO,
    selectParent: selectPageByID(PAGE.ENTPROTECT_ENROLL),
    title: 'Ent Protect Contact Information',
    path: '/Banking/app/ent-protect/enroll/contact-info',
  },
  {
    id: PAGE.FINANCES,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Money Insight',
    path: '/Banking/app/finances',
  },
  {
    id: PAGE.FINANCES_ABOUT,
    selectParent: selectPageByID(PAGE.FINANCES),
    title: 'About',
    path: '/Banking/app/finances/about',
  },
  {
    id: PAGE.FINANCES_CONFIGURE,
    selectParent: selectPageByID(PAGE.FINANCES),
    title: 'Configure',
    path: '/Banking/app/finances/configure',
  },
  {
    id: PAGE.FORGOT_PASSWORD,
    selectParent: selectPageByID(PAGE.LOGIN),
    title: 'Forgot Password',
    path: '/Banking/app/forgot-password',
  },
  {
    id: PAGE.FORGOT_PASSWORD_COMPLETE,
    selectParent: selectPageByID(PAGE.FORGOT_PASSWORD),
    title: 'Reset Password',
    path: '/Banking/app/forgot-password/complete',
  },
  // NOTE: This endpoint is still relevant because it homogenizes the experience for both
  // logged in and out users.
  {
    id: PAGE.HOME,
    selectParent: () => null,
    title: 'Home',
    path: '/Banking/Home.aspx',
  },
  {
    id: PAGE.LIFE_HAPPENS,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Life Happens Loan',
    path: '/Banking/app/loan-application/life-happens',
  },
  {
    id: PAGE.LOAN_APPLICATION_CONTINUE,
    selectParent: selectPageByID(PAGE.LOAN_APPLICATION_SELECT),
    title: 'Continue Loan Application',
    path: '/Banking/app/loan-application/continue',
  },
  {
    id: PAGE.LOAN_APPLICATION_NEW,
    selectParent: selectPageByID(PAGE.LOAN_APPLICATION_SELECT),
    title: 'New Loan Application',
    path: '/Banking/app/loan-application/new',
  },
  {
    id: PAGE.LOAN_APPLICATION_SELECT,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Loan Application',
    path: '/Banking/app/loan-application/select',
  },
  {
    id: PAGE.LOAN_APPLICATION_CONTACTINFO,
    selectParent: selectPageByID(PAGE.LOAN_APPLICATION_SELECT),
    title: 'Loan Application Verify Contact Information',
    path: '/Banking/app/loan-application/contact-info',
  },
  {
    id: PAGE.LOAN_PAY_UNAUTHENTICATED,
    selectParent: selectPageByID(PAGE.LOGIN),
    title: 'Make a Loan Payment',
    path: '/Banking/app/loan-pay',
  },
  {
    id: PAGE.LOAN_PAY_UNAUTHENTICATED_VERIFY,
    selectParent: selectPageByID(PAGE.LOAN_PAY_UNAUTHENTICATED),
    title: 'Verify Ent Account',
    path: '/Banking/app/loan-pay/verify',
  },
  {
    id: PAGE.LOAN_PAY_UNAUTHENTICATED_PAYMENT,
    selectParent: selectPageByID(PAGE.LOAN_PAY_UNAUTHENTICATED_VERIFY),
    title: 'Confirm Loan Payment',
    path: '/Banking/app/loan-pay/payment',
  },
  {
    id: PAGE.LOAN_PRE_QUAL,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Loan Pre Qualification',
    path: '/Banking/app/prequal/loan',
  },
  {
    id: PAGE.LOAN_SKIP_PAY,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Loan Skip a Pay',
    path: '/Banking/app/promotion/skip-a-pay',
  },
  {
    id: PAGE.LOGIN,
    selectParent: state => null, // eslint-disable-line no-unused-vars
    title: 'Log In',
    path: '/Banking/app/login',
  },
  {
    id: PAGE.LOGIN_PASSWORD,
    selectParent: selectPageByID(PAGE.LOGIN),
    title: 'Password',
    path: '/Banking/app/login/password',
  },
  {
    id: PAGE.LOGIN_SUCCESS,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Success',
    path: '/Banking/app/login/success',
  },
  {
    id: PAGE.MEMBER_AMBASSADOR,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Member Ambassador',
    path: '/Banking/app/member-ambassador',
  },
  {
    id: PAGE.MFA_CALL,
    selectParent: selectPageByID(PAGE.MFA_CHALLENGE),
    title: 'Call',
    path: '/Banking/app/mfa/call',
  },
  {
    id: PAGE.MFA_CHALLENGE,
    selectParent: () => null,
    title: 'Challenge',
    path: '/Banking/app/mfa',
  },
  {
    id: PAGE.MFA_EMAIL,
    selectParent: selectPageByID(PAGE.MFA_CHALLENGE),
    title: 'Email',
    path: '/Banking/app/mfa/email',
  },
  {
    id: PAGE.MFA_FAIL,
    selectParent: selectPageByID(PAGE.MFA_CHALLENGE),
    title: 'Fail',
    path: '/Banking/app/mfa/fail',
  },
  {
    id: PAGE.MFA_PUSH,
    selectParent: selectPageByID(PAGE.MFA_CHALLENGE),
    title: 'Push',
    path: '/Banking/app/mfa/push',
  },
  {
    id: PAGE.MFA_SMS,
    selectParent: selectPageByID(PAGE.MFA_CHALLENGE),
    title: 'SMS',
    path: '/Banking/app/mfa/sms',
  },
  {
    id: PAGE.MFA_SUCCESS,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Success',
    path: '/Banking/app/mfa/success',
  },
  {
    id: PAGE.NOT_FOUND,
    selectParent: selectPageByID(PAGE.HOME),
    title: 'Page Not Found',
    path: null,
  },
  {
    id: PAGE.LOCATIONS,
    selectParent: selectPageByID(PAGE.HOME),
    title: 'Find Locations & ATMs',
    path: '/Banking/app/locations',
  },
  {
    id: PAGE.OPTIN_ADDRESS_PHONE,
    selectParent: selectPageByID(PAGE.OPTIN_PHONE),
    title: 'Address Phone',
    path: '/Banking/app/opt-in/address-phone',
  },
  {
    id: PAGE.OPTIN_PHONE,
    selectParent: () => null,
    title: 'Contact Preferences',
    path: '/Banking/app/opt-in/phone',
  },
  {
    id: PAGE.ORDER_CHECKS,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Order Checks',
    path: '/Banking/app/services/order-checks',
  },
  {
    id: PAGE.OVERDRAFT,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Overdraft',
    path: '/Banking/app/services/overdraft',
  },
  {
    id: PAGE.PASSWORD,
    selectParent: selectPageByID(PAGE.PROFILE),
    title: 'Password',
    path: '/Banking/app/profile/password',
  },
  {
    id: PAGE.SETUP_PASSWORD,
    selectParent: () => null,
    title: 'Password',
    path: '/Banking/app/setup/password',
  },
  {
    id: PAGE.PROFILE,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'My Profile',
    path: '/Banking/app/profile',
  },
  {
    id: PAGE.PROFILE_SECURITY_PROFILE,
    selectParent: selectPageByID(PAGE.PROFILE),
    title: 'Security Profile',
    path: '/Banking/app/profile/security-profile',
  },
  {
    id: PAGE.PROMOTIONS,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Promotions',
    path: '/Banking/app/promotions',
  },
  {
    id: PAGE.QUICK_BALANCE,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Quick Balance',
    path: '/Banking/app/services/quick-balance',
  },
  {
    id: PAGE.REGISTRATION,
    selectParent: selectPageByID(PAGE.LOGIN),
    title: 'Registration',
    path: '/Banking/app/registration',
  },
  {
    id: PAGE.REGISTRATION_COMPLETE,
    selectParent: selectPageByID(PAGE.REGISTRATION),
    title: 'Registration',
    path: '/Banking/app/registration/complete',
  },
  {
    id: PAGE.SETUP_ADDRESS_PHONE,
    selectParent: selectPageByID(PAGE.SETUP_CONTACT_INFO),
    title: 'Address & Phone',
    path: '/Banking/app/setup/address-phone',
  },
  {
    id: PAGE.SETUP_CONTACT_INFO,
    selectParent: () => null,
    title: 'Contact Info',
    path: '/Banking/app/setup/contact-info',
  },
  {
    id: PAGE.SETUP_EMAIL,
    selectParent: selectPageByID(PAGE.SETUP_CONTACT_INFO),
    title: 'Email',
    path: '/Banking/app/setup/email',
  },
  {
    id: PAGE.SETUP_SECURITY_PROFILE,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Security Profile',
    path: '/Banking/app/setup/security-profile',
  },
  {
    id: PAGE.USER_ACTIVITY,
    selectParent: selectPageByID(PAGE.PROFILE),
    title: 'User Activity',
    path: '/Banking/app/profile/user-activity',
  },
  {
    id: PAGE.SERVICES,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Services',
    path: '/Banking/app/services',
  },
  {
    id: PAGE.STATEMENT_PREFERENCES,
    selectParent: selectPageByID(PAGE.STATEMENTS),
    title: 'Preferences',
    path: '/Banking/app/statements/preferences',
  },
  {
    id: PAGE.STATEMENTS,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Statements',
    path: '/Banking/app/statements',
  },
  {
    id: PAGE.STOP_PAYMENT,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Stop Payment',
    path: '/Banking/app/services/stop-payment',
  },
  {
    ID: PAGE.TAX_PAYMENTS,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Tax Payments',
    path: '/Banking/app/services/tax-payments',
  },
  {
    id: PAGE.TEXT_BANKING,
    selectParent: selectPageByID(PAGE.SERVICES),
    title: 'Text Banking',
    path: '/Banking/app/services/text-banking',
  },
  {
    id: PAGE.TEXT_BANKING_ENROLL,
    selectParent: selectPageByID(PAGE.TEXT_BANKING),
    title: 'Enroll',
    path: '/Banking/app/services/text-banking/enroll',
  },
  {
    id: PAGE.TEXT_BANKING_UNENROLL,
    selectParent: selectPageByID(PAGE.TEXT_BANKING),
    title: 'Unenroll',
    path: '/Banking/app/services/text-banking/unenroll',
  },
  {
    id: PAGE.TRANSFER_LOAN,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Pay Loans',
    path: '/Banking/app/transfer/loan',
  },
  {
    id: PAGE.TRANSFER_LOAN_ACCOUNT,
    selectParent: selectPageByID(PAGE.TRANSFER_LOAN),
    title: 'Manage Accounts',
    path: '/Banking/app/transfer/loan/accounts',
  },
  {
    id: PAGE.TRANSFER_LOAN_ACCOUNT_EXTERNAL_EDIT,
    selectParent: selectPageByID(PAGE.TRANSFER_LOAN_ACCOUNT),
    title: 'Edit Outside Account',
    path: '/Banking/app/transfer/loan/accounts/external/edit',
  },
  {
    id: PAGE.TRANSFER_LOAN_ACCOUNT_EXTERNAL_VERIFY,
    selectParent: selectPageByID(PAGE.TRANSFER_LOAN_ACCOUNT),
    title: 'Verify Outside Account',
    path: '/Banking/app/transfer/loan/accounts/external/verify',
  },
  {
    id: PAGE.TRANSFER_LOAN_ACCOUNT_INTERNAL_EDIT,
    selectParent: selectPageByID(PAGE.TRANSFER_LOAN_ACCOUNT),
    title: 'Edit Inside Ent',
    path: '/Banking/app/transfer/loan/accounts/internal/edit',
  },
  {
    id: PAGE.TRANSFER_LOAN_EDIT,
    selectParent: selectPageByID(PAGE.TRANSFER_LOAN),
    title: 'Edit Transfer',
    path: '/Banking/app/transfer/loan/edit',
  },
  {
    id: PAGE.TRANSFER_SHARE,
    selectParent: selectPageByID(PAGE.ACCOUNTS),
    title: 'Transfer',
    path: '/Banking/app/transfer/share',
  },
  {
    id: PAGE.TRANSFER_SHARE_ACCOUNT,
    selectParent: selectPageByID(PAGE.TRANSFER_SHARE),
    title: 'Manage Accounts',
    path: '/Banking/app/transfer/share/accounts',
  },
  {
    id: PAGE.TRANSFER_SHARE_ACCOUNT_EXTERNAL_EDIT,
    selectParent: selectPageByID(PAGE.TRANSFER_SHARE_ACCOUNT),
    title: 'Edit Outside Account',
    path: '/Banking/app/transfer/share/accounts/external/edit',
  },
  {
    id: PAGE.TRANSFER_SHARE_ACCOUNT_EXTERNAL_VERIFY,
    selectParent: selectPageByID(PAGE.TRANSFER_SHARE_ACCOUNT),
    title: 'Verify Outside Account',
    path: '/Banking/app/transfer/share/accounts/external/verify',
  },
  {
    id: PAGE.TRANSFER_SHARE_ACCOUNT_INTERNAL_EDIT,
    selectParent: selectPageByID(PAGE.TRANSFER_SHARE_ACCOUNT),
    title: 'Edit Inside Account',
    path: '/Banking/app/transfer/share/accounts/internal/edit',
  },
  {
    id: PAGE.TRANSFER_SHARE_EDIT,
    selectParent: selectPageByID(PAGE.TRANSFER_SHARE),
    title: 'Edit Transfer',
    path: '/Banking/app/transfer/share/edit',
  },
  {
    id: PAGE.USERNAME,
    selectParent: selectPageByID(PAGE.PROFILE),
    title: 'Username',
    path: '/Banking/app/profile/username',
  },
  {
    id: PAGE.USERS_LIST,
    selectParent: selectPageByID(PAGE.PROFILE),
    title: 'Users',
    path: '/Banking/app/profile/users',
  },
  {
    id: PAGE.USER_EDIT,
    selectParent: selectPageByID(PAGE.USERS_LIST),
    title: 'Edit User',
    path: '/Banking/app/profile/users/edit',
  },
  {
    id: PAGE.MIGRATION_START,
    selectParent: state => null, // eslint-disable-line no-unused-vars
    title: 'Migration Start',
    path: '/Banking/app/migration/start',
  },
];
