import React, { Component } from 'react';

import { Storage } from '~/common';
import MfaChallengeContent from '~/pages/MfaChallenge/MfaChallengeContent';

class ChallengePage extends Component {
  componentDidMount() {
    const { pathname, search } = document.location;
    const returnUrl = `${pathname}${search}`;
    Storage.session.setItem('returnUrl', returnUrl);
  }

  render() {
    return <MfaChallengeContent />;
  }
}

ChallengePage.propTypes = {};

ChallengePage.defaultProps = {};

export default ChallengePage;
