import { and, or, not } from '@ent/boolean';
import { SELECT_USER_ROLE } from './constants';

const {
  AUTHORIZED_SIGNER,
  OWNER,
  XFERS_LIMITED_TO_INTRA_ACCOUNT,
  ACH_TRANSFERS_USER_PROFILE_ACTIVE,
  BUSINESS_ACCOUNT,
  CORPORATE_ACCOUNT,
  SAVINGS_ONLY_ACCOUNT,
  ACH_TRANSFERS_DISABLED,
} = SELECT_USER_ROLE;

export default and(
  or(AUTHORIZED_SIGNER, OWNER),
  ACH_TRANSFERS_USER_PROFILE_ACTIVE,
  not(BUSINESS_ACCOUNT),
  not(CORPORATE_ACCOUNT),
  not(SAVINGS_ONLY_ACCOUNT),
  not(XFERS_LIMITED_TO_INTRA_ACCOUNT),
  not(ACH_TRANSFERS_DISABLED)
);
