import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';

import { FieldFormGroup } from '../FieldFormGroup';
import './styles.scss';

class RichSelect extends Component {
  onChange = isMulti => async option => {
    const { field, form } = this.props;
    if (isMulti) await form.setFieldValue(field.name, option || []);
    // NOTE: When a value is cleared option is null. If setFieldValue is called with null or undefined, the fieldName
    // is removed from values. Set to empty string to avoid this.
    else await form.setFieldValue(field.name, option?.value === 0 ? 0 : option?.value || '');
    // NOTE: Looks like sometimes (initial From account selection on mobile browser) form is not validated on change
    // or at least validation errors are not removed, so we will do it here explicitly
    form.validateForm();
  };

  onBlur = () => {
    const { field, form } = this.props;
    form.setFieldTouched(field.name, true);
  };

  render() {
    const { id, isAsync, form, field, isMulti, isSearchable, isClearable, ...other } = this.props;

    const Select = isAsync ? ReactSelectAsync : ReactSelect;

    const customStyles = {
      option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        backgroundColor: isFocused || isSelected ? '#deebff' : undefined,
        color: isFocused || isSelected ? 'inherit' : undefined,
      }),
    };

    return (
      <Select
        inputId={id}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        className={`Select form-control rich-select ${field.name}`}
        {...other}
        onBlur={this.onBlur}
        onChange={this.onChange(isMulti)}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: 'rgb(30, 144, 255)',
          },
        })} />
    );
  }
}

RichSelect.propTypes = {
  id: PropTypes.string.isRequired,
  isAsync: PropTypes.bool,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
};

RichSelect.defaultProps = {
  isMulti: false,
  isAsync: false,
  isSearchable: false,
  isClearable: true,
};

const RichSelectField = ({ name, ...other }) => <FieldFormGroup name={name} component={RichSelect} {...other} />;

RichSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  async: PropTypes.bool,
  content: PropTypes.func,
};

RichSelectField.defaultProps = {
  content: RichSelect,
  async: false,
};

export default RichSelectField;
