import { initCookies, selectCookies } from '~/store/cookie';
import { startInAuth } from '~/store/inauth';
import { getUser, selectIsAuthenticated } from '~/store/user';

const select = state => ({
  authenticated: selectIsAuthenticated(state),
  cookies: selectCookies(state),
});

const boundActions = {
  getUser,
  initCookies,
  startInAuth,
};

export default [select, boundActions];
