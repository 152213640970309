import { createSelector } from 'reselect';

import selectAllCards from './selectAllCards';
import selectSelectedID from './selectSelectedID';

// prettier-ignore
export default createSelector(
  selectAllCards,
  selectSelectedID,
  (cards, selectedID) => cards.find(({ id }) => id === selectedID)
);
