import * as Yup from 'yup';

export const getInitialValues = initialValues => ({
  password: '',
  recaptcha: '',
  recaptchaRequired: false,
  ...initialValues,
});

export const validationSchema = Yup.object({
  recaptchaRequired: Yup.bool(),
  password: Yup.string()
    .required('Required')
    .min(1, 'Too short')
    .max(50, 'Too long'),
  recaptcha: Yup.string().when('recaptchaRequired', {
    is: true,
    then: Yup.string()
      .required('Required')
      .nullable()
      .notOneOf([null], 'Required'),
    otherwise: Yup.string().notRequired(),
  }),
});
