import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ContentMenuItem extends Component {
  click = event => {
    event.nativeEvent.preventDefault();
    const { id, icon, text, click } = this.props;
    click({
      id,
      icon,
      text,
      event,
    });
  };

  render() {
    const { icon, text } = this.props;
    const rootClass = classNames('content-menu-item', { active: false });
    const iconClass = classNames('fa', icon);

    return (
      <a href="#" onClick={this.click}>
        <div className={rootClass}>
          <i className={iconClass} />
          {text}
        </div>
      </a>
    );
  }
}

ContentMenuItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired, // ({ id, icon, text, event }) => void
};

ContentMenuItem.defaultProps = {};

export default ContentMenuItem;
