import { createSelector } from 'reselect';
import { selectPageState } from '@ent/redux-pagestate';

import { MFA_METHODS } from '~/constants';

import pageStateConfig from './page-state';

const countBooleans = (...bools) => bools.reduce((t, b) => +b + t, 0);

const selectLoading = createSelector(selectPageState(pageStateConfig.key), ({ loading }) => loading);

const selectMethod = mode =>
  // eslint-disable-next-line implicit-arrow-linebreak
  createSelector(selectPageState(pageStateConfig.key), ({ methods }) => methods.find(m => m.type === mode));

const selectMethodLockout = mode => createSelector(selectMethod(mode), method => method && method.lockout);

const selectIsMethodEnabled = mode => createSelector(selectMethod(mode), method => !!method);

const selectListFromPageState = key =>
  // eslint-disable-next-line implicit-arrow-linebreak
  createSelector(selectPageState(pageStateConfig.key), pageState => pageState[key] || []);

const selectIsMethodEnabledWithList = (mode, list) => createSelector(
  selectIsMethodEnabled(mode),
  selectListFromPageState(list),
  (enabled, options) => enabled && !!options.length
);

export const selectPhone = createSelector(
  selectPageState(pageStateConfig.key),
  ({ mode, phones, ids }) => phones.find(p => p.id === ids[mode]) || {}
);

export const selectEmail = createSelector(
  selectPageState(pageStateConfig.key),
  ({ emails, ids, mode }) => emails.find(e => e.id === ids[mode]) || {}
);

export const selectConfig = createSelector(
  selectIsMethodEnabledWithList(MFA_METHODS.CALL, 'phones'),
  selectIsMethodEnabledWithList(MFA_METHODS.SMS, 'phones'),
  selectIsMethodEnabled(MFA_METHODS.PUSH),
  selectIsMethodEnabledWithList(MFA_METHODS.EMAIL, 'emails'),
  selectMethodLockout(MFA_METHODS.CALL),
  selectMethodLockout(MFA_METHODS.SMS),
  selectMethodLockout(MFA_METHODS.PUSH),
  selectMethodLockout(MFA_METHODS.EMAIL),
  selectListFromPageState('phones'),
  selectListFromPageState('emails'),
  (call, sms, push, email, callLockout, smsLockout, pushLockout, emailLockout, phones, emails) => ({
    available: countBooleans(call, sms, push, email),
    call,
    sms,
    push,
    email,
    callLockout,
    smsLockout,
    pushLockout,
    emailLockout,
    phones,
    emails,
  })
);

export const selectShouldFail = createSelector(
  selectLoading,
  selectConfig,
  (loading, { available }) => !loading && !available
);

const selectLastMethodUsed = createSelector(
  selectPageState(pageStateConfig.key),
  ({ lastMethod: { type: mode, details: id } }) => ({ mode, id })
);

const selectDefaultID = ({ enabled, modes, list, lastUsed: { mode, id } }) => {
  if (!enabled) return undefined;
  const first = list[0];
  const lastUsed = modes.includes(mode) && list.find(o => o.id === id);
  return (lastUsed || first).id;
};

export const selectDefaultMethod = createSelector(
  selectConfig,
  selectListFromPageState('phones'),
  selectListFromPageState('emails'),
  selectLastMethodUsed,
  ({ call, sms, push, email }, phones, emails, { mode, id }) => ({
    /* eslint-disable no-nested-ternary */
    mode:
      // prettier-ignore
      mode === MFA_METHODS.CALL && call ? MFA_METHODS.CALL
        : mode === MFA_METHODS.SMS && sms ? MFA_METHODS.SMS
          : mode === MFA_METHODS.PUSH && push ? MFA_METHODS.PUSH
            : mode === MFA_METHODS.EMAIL && email ? MFA_METHODS.EMAIL
              : call && phones.length ? MFA_METHODS.CALL
                : sms ? MFA_METHODS.SMS
                  : push ? MFA_METHODS.PUSH
                    : email ? MFA_METHODS.EMAIL
                      : MFA_METHODS.NONE,
    /* eslint-enable no-nested-ternary */
    ids: {
      [MFA_METHODS.CALL]: selectDefaultID({
        enabled: call,
        modes: [MFA_METHODS.CALL, MFA_METHODS.SMS],
        list: phones,
        lastUsed: { mode, id },
      }),
      [MFA_METHODS.SMS]: selectDefaultID({
        enabled: sms,
        modes: [MFA_METHODS.CALL, MFA_METHODS.SMS],
        list: phones,
        lastUsed: { mode, id },
      }),
      [MFA_METHODS.PUSH]: undefined,
      [MFA_METHODS.EMAIL]: selectDefaultID({
        enabled: email,
        modes: [MFA_METHODS.EMAIL],
        list: emails,
        lastUsed: { mode, id },
      }),
    },
  })
);
