import * as Yup from 'yup';
import { memoize, identity } from '@ent/functional';
import { apiProvider } from '@ent/browser';

export const PASSWORD_VALIDATORS = {
  LENGTH: 'LENGTH',
  NUMBER_REQUIRED: 'NUMBER_REQUIRED',
  LETTER_REQUIRED: 'LETTER_REQUIRED',
  CAPITAL_LETTER_REQUIRED: 'CAPITAL_LETTER_REQUIRED',
  SPECIAL_CHARACTER_REQUIRED: 'SPECIAL_CHARACTER_REQUIRED',
  SECURE_PASSWORD_REQUIRED: 'SECURE_PASSWORD_REQUIRED',
};

const lengthValidator = Yup.string()
  .min(8, 'Too short')
  .max(50, 'Too long');

const numberValidator = Yup.string().test(
  'digit',
  'Must contain a digit',
  value => /\d/.test(value)
);
const letterValidator = Yup.string().test(
  'alpha',
  'Must contain a letter',
  value => /[a-z]/.test(value)
);
const capitalLetterValidator = Yup.string().test(
  'alpha',
  'Must contain a letter',
  value => /[A-Z]/.test(value)
);
const specialCharacterValidator = Yup.string().matches(
  /[^a-zA-Z0-9]/,
  'Must contain a special character'
);

const clientValidators = Yup.string()
  .required('Required')
  .concat(lengthValidator)
  .concat(numberValidator)
  .concat(letterValidator)
  .concat(capitalLetterValidator)
  .concat(specialCharacterValidator);

const getServerValidation = memoize(
  async (password, registrationKey) => {
    const isClientValid = await clientValidators.isValid(password);
    if (!password || !isClientValid) {
      return false;
    }
    try {
      const { valid } = await apiProvider({
        url: '/Banking/api/user/password/validate',
        method: 'POST',
        data: { password, registrationKey },
      });
      return valid;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  },
  { key: identity, TTL: 24 * 60 * 60 * 1000 }
);

// prettier-ignore
export const getPasswordValidatorMap = registrationKey => ({
  [PASSWORD_VALIDATORS.LENGTH]: lengthValidator,
  [PASSWORD_VALIDATORS.NUMBER_REQUIRED]: numberValidator,
  [PASSWORD_VALIDATORS.LETTER_REQUIRED]: letterValidator,
  [PASSWORD_VALIDATORS.CAPITAL_LETTER_REQUIRED]: capitalLetterValidator,
  [PASSWORD_VALIDATORS.SPECIAL_CHARACTER_REQUIRED]: specialCharacterValidator,
  [PASSWORD_VALIDATORS.SECURE_PASSWORD_REQUIRED]: Yup.string().required('Required')
    .test('server', 'Must be a secure password', password => getServerValidation(password, registrationKey)),
});

// prettier-ignore
export const getPasswordSchema = registrationKey => Yup.string()
  .required('Required')
  .concat(Object.values(getPasswordValidatorMap(registrationKey)).reduce((a, r) => a.concat(r)))
  .matches(/^(?=.*\d)(?=.*[a-zA-Z])[\w'~!@#$%^&*()_+\-{}|;:?,./"]*$/, 'Invalid');
