import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withPageState } from '@ent/redux-pagestate';
import classNames from 'classnames';
import { waitFor } from '@ent/functional';
import { apiProvider, device } from '@ent/browser';

import { ENT_MOBILE_ACTION, SUFFIX_TYPE } from '~/constants';
import { INBROWSER_TIMEOUT, INMOBILE_TIMEOUT } from '~/store/inauth/constants';
import {
  entMobile,
  parseWorkflows,
  TopNav,
  TopNavLink,
  Storage,
  updateChatInformation,
} from '~/common';
import { withApi, withMessages, withRouter } from '~/hocs';
import { LoginPassword } from '~/components/LoginPassword';
import { QuickBalanceList } from '~/components/QuickBalanceList';
import { WebBeacon } from '../../components/WebBeacon';
import { OutdatedEntMobileMessage } from '~/components/OutdatedEntMobileMessage';
import { COOKIE } from '~/store/cookie';
import pageStateConfig from '~/pages/LoginUsername/page-state';
import connectConfig from './connect';
import getFingerprints from './fingerprints';
import preload from './preload';
import './styles.scss';

class LoginPasswordContent extends Component {
  state = {
    bioSubmitting: false,
    processingCookies: true,
    qbView: false,
    suffixes: undefined,
    recaptchaRequired: false,
    requestToken: null,
    showPassword: false,
  };

  async componentDidMount() {
    const { getUser, initCookies, pageState, redirect, startInAuth } = this.props;
    const { username: { display, value } = {}, showChatBubble } = pageState;
    const username = value || display;

    this.isEntMobileOutdated();
    preload();
    startInAuth();

    if (!pageState.username || !username || !pageState.image || !pageState.phrase) {
      redirect('/Banking/app/login');
    }

    this.observable = entMobile.observable.subscribe(this.onEntMobileDispatch);
    await Promise.all([
      this.isRecaptchaRequired(),
      getUser({
        force: true,
        redirectOn403: false,
        autoUpdateWorkflows: false,
      }),
      initCookies(),
    ]);
    updateChatInformation(username, showChatBubble);

    const { authenticated } = this.props;
    if (authenticated) {
      redirect('/Banking/app/accounts');
    }

    const { recaptchaRequired } = this.state;
    if (!recaptchaRequired) {
      entMobile.dispatch({
        type: ENT_MOBILE_ACTION.BIOMETRIC_INIT,
        payload: { username },
      });
    }

    this.setState({ processingCookies: false });
    this.initQB();
  }

  componentDidUpdate(prevProps) {
    const { qbEnabled } = this.props;
    if (!prevProps.qbEnabled && qbEnabled) {
      this.initQB();
    }
  }

  componentWillUnmount() {
    if (this.observable) {
      this.observable.unsubscribe();
      this.observable = null;
    }
  }

  onEntMobileDispatch = async ({ detail: { type, payload } }) => {
    if (type === ENT_MOBILE_ACTION.BIOMETRIC_SUBMIT) {
      this.setState({ bioSubmitting: true });
      await waitFor({
        selector: () => {
          const { inAuthLoading } = this.props;
          return !inAuthLoading;
        },
        timeout: INMOBILE_TIMEOUT + INBROWSER_TIMEOUT,
      });
      await this.submit({ password: payload.password, recaptcha: payload.recaptcha });
      this.setState({ bioSubmitting: false });
    }
  };

  isRecaptchaRequired = async () => {
    const { api, pageState } = this.props;
    const { username: { display, value } = {} } = pageState;
    const username = value || display;
    try {
      const { recaptchaRequired, requestToken } = await api({
        url: '/Banking/api/authentication/recaptcha',
        method: 'POST',
        data: { username },
      });
      this.setState({ recaptchaRequired, requestToken });
    } catch (e) {} // eslint-disable-line no-empty
  };

  onRecaptchaSuccess = recaptchaValue => {
    const { pageState } = this.props;
    const { recaptchaRequired } = this.state;
    const { username: { display, value } = {} } = pageState;
    const username = value || display;

    Storage.session.setItem('reCaptchaToken', recaptchaValue);
    if (recaptchaRequired) {
      entMobile.dispatch({
        type: ENT_MOBILE_ACTION.BIOMETRIC_INIT,
        payload: { username, recaptcha: recaptchaValue },
      });
    }
  };

  reload = () => {
    const { suffixes: curSuffixes } = this.state;
    const suffix = (curSuffixes || []).find(s => s.transactions instanceof Array) || {};
    this.setState({ suffixes: undefined });
    this.loadSuffixes(suffix.id);
  };

  disableQB = ({ aborted } = {}) => {
    if (!aborted) {
      const { pageState, setPageState, removeCookie } = this.props;
      removeCookie({ key: COOKIE.QUICK_BALANCE });
      setPageState({
        ...pageState,
        quickBalance: false,
      });
      this.setState({ suffixes: undefined, qbView: false });
    }
  };

  loadSuffixes = async suffixID => {
    const { api } = this.props;
    try {
      const { suffixes } = await api({
        url: '/Banking/api/quickbalance/suffixes',
        method: 'POST',
        data: {},
      });
      this.setState({ suffixes });

      const first = type => suffixes.find(s => s.type === type);
      const suffix = suffixes.find(s => s.id === suffixID) || first(SUFFIX_TYPE.CHECKING) || first(SUFFIX_TYPE.SAVINGS);
      if (suffix) {
        this.loadSuffix(suffix.id);
      }
    } catch (e) {
      this.disableQB(e);
    }
  };

  loadSuffix = async id => {
    const { api } = this.props;
    const { suffixes: curSuffixes } = this.state;
    const skip = !(curSuffixes || []).some(s => s.id === id);
    if (skip) return;

    try {
      this.setState({ suffixes: curSuffixes.map(s => (s.id === id ? { ...s, transactions: 'Loading' } : s)) });
      const { suffix, transactions } = await api({
        url: `/Banking/api/quickbalance/${id}/history`,
        method: 'POST',
        data: {},
      });

      const suffixes = curSuffixes.map(s => (s.id === id ? { ...suffix, transactions } : { ...s, transactions: null }));
      this.setState({ suffixes });
    } catch (e) {
      const suffixes = curSuffixes.filter(s => s.id !== id);
      if (!suffixes.length) {
        this.disableQB(e);
        return;
      }
      this.setState({ suffixes });
    }
  };

  setQbView = qbView => () => this.setState({ qbView });

  getToken = async () => {
    const { api } = this.props;
    const { token } = await api({
      url: '/Banking/api/authentication/token',
      method: 'POST',
    });
    return token;
  };

  fingerprints = async options => {
    const [fps, token] = await Promise.all([getFingerprints(options), this.getToken()]);
    return fps.map(fp => window.btoa(`${token}|${JSON.stringify(fp)}`));
  };

  submit = async (values, formik) => {
    const { clearMessages, pageState, redirect, setWorkflow, setUser, startInAuth } = this.props;
    const { requestToken } = this.state;

    if (this.isEntMobileOutdated()) {
      return false;
    }

    const fps = await this.fingerprints();

    try {
      await startInAuth();

      clearMessages({ all: true });

      const { accessDenied, user, workflows, recaptchaRequired } = await apiProvider({
        url: '/Banking/api/authentication/authenticate',
        method: 'POST',
        requestToken,
        data: {
          username: pageState.username.value || pageState.username.display,
          password: values.password,
          recaptcha: values.recaptcha,
          fps,
        },
        autoUpdateWorkflows: false,
        autoPublishMessages: true,
      });

      this.setState({ recaptchaRequired });

      if (accessDenied) {
        return redirect('/Banking/app/access-denied');
      }

      const queue = parseWorkflows(workflows);
      const finishUrl = user ? '/Banking/app/accounts' : '/Banking/app/account-locked';

      const hasWorkflow = !!queue.length;
      if (hasWorkflow) {
        await setWorkflow({ queue, finishUrl });
      }

      await entMobile.dispatch({
        type: ENT_MOBILE_ACTION.BIOMETRIC_SUBMIT_SUCCESS,
        payload: { password: values.password },
      });
      await setUser(user);
      // updateChatInformationFromStore(store);
      return redirect(hasWorkflow ? queue[0].urls[0] : finishUrl);
    } catch (e) {
      this.setState({ recaptchaRequired: ((e.response || {}).json || {}).recaptchaRequired });
      /* NOTE: Backgrounding the app in iOS aborts the HTTPS request.  The user may have logged on successfully on the
       * server but an exception is thrown on the client.  If we receive this specific error all we can do is refresh
       * the page.
       */

      if (e.aborted) {
        window.location.reload();

        return true;
      }

      if (formik) await formik.setFieldValue('password', '');
      entMobile.dispatch({
        type: ENT_MOBILE_ACTION.BIOMETRIC_SUBMIT_ERROR,
        payload: { error: e.message },
      });
      return false;
    }
  };

  isEntMobileOutdated = () => {
    const { addMessage } = this.props;
    const { entMobileOutdated, isEntMobileIos, isEntMobileAndroid } = device;

    if (entMobileOutdated) {
      addMessage({
        text: <OutdatedEntMobileMessage entMobileIos={isEntMobileIos} entMobileAndroid={isEntMobileAndroid} />,
        autoClose: false,
      });
    }

    return entMobileOutdated;
  };

  showHidePasswordClick = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }


  initQB() {
    const { qbEnabled } = this.props;
    if (qbEnabled) {
      this.loadSuffixes();
    }
  }

  render() {
    const { entMobileOutdated } = device;
    const { inAuthLoading, pageState, qbEnabled, forgotUrl } = this.props;
    const { bioSubmitting, processingCookies, suffixes, qbView, recaptchaRequired, showPassword } = this.state;

    const { display: username } = pageState.username || {};

    const classes = classNames('LoginPasswordContent col-xs-12 col-narrow', { qbEnabled });
    if (processingCookies) return <div className={classes} />;

    return (
      <div className={classes}>
        <WebBeacon />
        <TopNav>
          <TopNavLink className={!qbView ? 'active' : ''} onClick={this.setQbView(false)}>
            {!qbView && <h1>Password</h1>}
            {qbView && <span>Password</span>}
          </TopNavLink>
          {qbEnabled && (
            <TopNavLink className={qbView ? 'active' : ''} onClick={this.setQbView(true)}>
              {qbView && <h1>Quick Balance</h1>}
              {!qbView && <span>Quick Balance</span>}
            </TopNavLink>
          )}
        </TopNav>
        {qbView && <QuickBalanceList suffixes={suffixes} loadSuffix={this.loadSuffix} reload={this.reload} />}
        {!qbView && (
          <LoginPassword
            bioSubmitting={bioSubmitting}
            image={pageState.image}
            initialValues={{ username }}
            inAuthLoading={inAuthLoading}
            loginRecoverUrl={forgotUrl}
            onRecaptchaSuccess={this.onRecaptchaSuccess}
            onSubmit={this.submit}
            entMobileOutdated={entMobileOutdated}
            phrase={pageState.phrase}
            recaptchaRequired={recaptchaRequired}
            showPassword={showPassword}
            showHidePasswordClick={this.showHidePasswordClick} />
        )}
      </div>
    );
  }
}

LoginPasswordContent.propTypes = {
  // connect
  authenticated: PropTypes.bool.isRequired,
  getUser: PropTypes.func.isRequired,
  inAuthLoading: PropTypes.bool.isRequired,
  initCookies: PropTypes.func.isRequired,
  qbEnabled: PropTypes.bool.isRequired,
  removeCookie: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setWorkflow: PropTypes.func.isRequired,
  startInAuth: PropTypes.func.isRequired,
  forgotUrl: PropTypes.string.isRequired,
  // withApi
  api: PropTypes.func.isRequired,
  // withPageState
  pageState: PropTypes.object.isRequired,
  setPageState: PropTypes.func.isRequired,
  // withMessages
  addMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  // withRouter
  redirect: PropTypes.func.isRequired,
};

LoginPasswordContent.defaultProps = { };

export default compose(
  connect(...connectConfig),
  withApi(),
  withPageState(pageStateConfig),
  withMessages({ clearOnUnload: false }),
  withRouter(),
)(LoginPasswordContent);
