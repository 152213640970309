import Cookie from 'universal-cookie';
import { noop } from '@ent/functional';
import { apiProvider } from '@ent/browser';

import { MESSAGE_TYPE } from '~/constants';
import { guid } from '~/common/guid';
import { addMessage } from '~/store/messages';

import * as CONST from '../constants';
import setCookie from './setCookie';
import removeCookie from './removeCookie';

const defaultCookie = new Cookie();

// prettier-ignore
export default ({
  showDisabledMessage = true,
  mockCookie,
  mockProvider,
} = {}) => (dispatch, getState) => new Promise((resolve, reject) => {
  if (!navigator.cookieEnabled) {
    return reject(CONST.COOKIE.DISABLED);
  }

  const { data, loading } = getState().cookie || {};
  if (!data && !loading) {
    dispatch({ type: CONST.COOKIE_INIT_BEGIN });

    // TODO: Eventually (365 days after go-live all old cookies will be expired -- then safe to delete)
    // NOTE: Migrate the ent cookie to rememberMe cookie
    const cookie = mockCookie || defaultCookie;
    const ent = cookie.get(CONST.COOKIE.ENT);
    if (ent) {
      if (ent && ent.rememberMe && ent.rememberMe.on && ent.rememberMe.username) {
        dispatch(
          setCookie({
            key: CONST.COOKIE.REMEMBER_ME,
            value: ent.rememberMe.username,
            mockCookie,
          })
        );
      }
      dispatch(
        removeCookie({
          key: CONST.COOKIE.ENT,
          mockCookie,
        })
      );
    }

    // NOTE: Yo, future self, do NOT delete this part 365 days in the future
    [CONST.COOKIE.HASH, CONST.COOKIE.REMEMBER_ME, CONST.COOKIE.QUICK_BALANCE]
      .map(key => {
        const value = cookie.get(key) || (key === CONST.COOKIE.HASH ? guid().replace(/-/g, '') : undefined);
        return setCookie({ key, value, mockCookie });
      })
      .forEach(action => dispatch(action));

    // NOTE: Migrate the UnauthenticatedToken to quickBalance cookie
    const UnauthenticatedToken = cookie.get(CONST.COOKIE.UNAUTHENTICATED_TOKEN);
    if (UnauthenticatedToken) {
      const request = {
        url: '/Banking/api/quickbalance/migrate',
        method: 'POST',
        data: { token: UnauthenticatedToken },
      };

      const success = response => {
        dispatch(
          setCookie({
            key: CONST.COOKIE.QUICK_BALANCE,
            value: response.token,
            mockCookie,
          })
        );
      };

      const failure = noop;

      const cleanup = () => {
        dispatch(
          removeCookie({
            key: CONST.COOKIE.UNAUTHENTICATED_TOKEN,
            mockCookie,
          })
        );
        dispatch({ type: CONST.COOKIE_INIT_END });
      };

      const provider = mockProvider || apiProvider;
      const result = provider(request)
        .then(success)
        .catch(failure)
        .then(cleanup);
      return resolve(result);
    }

    dispatch({ type: CONST.COOKIE_INIT_END });
  }

  return resolve();
}).catch(e => {
  if (e === CONST.COOKIE.DISABLED) {
    const message = {
      text: 'Your cookies are disabled.  Some features may be unavailable.',
      type: MESSAGE_TYPE.WARNING,
    };
    return showDisabledMessage ? dispatch(addMessage(message)) : undefined;
  }
  return e;
});
