import { DEPOSIT_LAYOUT } from '../constants';
import { selectDepositLayout } from '../selectors';
import setUserProfile from './setUserProfile';

const LAYOUTS = Object.values(DEPOSIT_LAYOUT);

const toggleDepositLayout = () => (dispatch, getState) => {
  const current = selectDepositLayout(getState());
  const index = LAYOUTS.findIndex(layout => layout === current);
  const next = LAYOUTS[(index + 1) % LAYOUTS.length];

  return setUserProfile({ depositLayout: next })(dispatch, getState);
};

export default toggleDepositLayout;
