import { createValet } from '~/common/redux/valet';
import * as CONST from '../constants';

import history from '~/store/history';

import initialState from './cardnavDestinationsInitialState';

const cardnavDestinationsValet = createValet({
  defaultReducer: 'cardnavDestinations',
  history,
  initialState,
  msCacheDuration: 20 * 60 * 1000, // 20 minutes
  GET: CONST.CARDNAV_DESTINATIONS_GET,
  ERROR: CONST.CARDNAV_DESTINATIONS_GET_ERROR,
  SUCCESS: CONST.CARDNAV_DESTINATIONS_GET_SUCCESS,
  RESET: CONST.CARDNAV_DESTINATIONS_RESET,
  EXPIRE: CONST.CARDNAV_DESTINATIONS_EXPIRE,
});

export default cardnavDestinationsValet;
