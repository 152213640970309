import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { format } from '~/common';

const Transaction = ({ id, date, description, amount }) => (
  <div key={id} className="Transaction col-xs-12">
    <div className="date small">{moment(date).format('M/D')}</div>
    <div className="desc small">{format.title(description)}</div>
    <div className="amount small">{format.currency(amount)}</div>
  </div>
);

Transaction.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

Transaction.defaultProps = {};

export default Transaction;
