import { initCookies, removeCookie } from '~/store/cookie';
import { selectIsInAuthLoading, startInAuth } from '~/store/inauth';
import { getUser, selectIsAuthenticated, setUser } from '~/store/user';
import { selectForgotUsernameOrPasswordUrl } from '~/store/env';
import { setWorkflow } from '~/store/workflow';

import { selectQBEnabled } from './selectors';

const select = state => ({
  authenticated: selectIsAuthenticated(state),
  inAuthLoading: selectIsInAuthLoading(state),
  qbEnabled: selectQBEnabled(state),
  forgotUrl: selectForgotUsernameOrPasswordUrl(state),
});

const boundActions = {
  getUser,
  initCookies,
  removeCookie,
  setUser,
  setWorkflow,
  startInAuth,
};

export default [select, boundActions];
