import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormikEffect as Effect, Button, CheckboxField, ScrollToFirstError, TextField } from '@ent/fields';
import { Module } from '@ent/component';

import { NoteLink } from '~/common/components/NoteLink';

import { getInitialValues, validationSchema, normalizer } from './form';
import QuickBalanceLink from './QuickBalanceLink';
import './styles.scss';

class LoginUsername extends Component {
  componentDidMount() {
    const { startInAuth } = this.props;
    startInAuth();
  }

  onFormikChange = async (current, next) => {
    const { removeRememberMeCookie, removeQuickBalanceCookie } = this.props;
    const { setFieldValue } = this.formik;

    if (current.values.rememberMe && !next.values.rememberMe) {
      removeRememberMeCookie();
      await setFieldValue('quickBalance', false);
      await setFieldValue('username.value', '');
      if ((next.values.username.display || '').includes('*')) {
        await setFieldValue('username.display', '');
      }
    }
    if (current.values.quickBalance && !next.values.quickBalance) {
      removeQuickBalanceCookie();
    }
    if (current.values.username.display !== next.values.username.display) {
      await setFieldValue('username.value', '');
    }
  };

  render() {
    const {
      initialValues,
      onRegisterClick,
      loginRecoverUrl,
      onSubmit,
      entMobileOutdated,
    } = this.props;
    const loginNote = (
      <NoteLink
        note="Forgot Password/Username"
        data-autoid="forgot-password-username"
        onClick={() => { window.location = loginRecoverUrl; }} />
    );

    return (
      <Module className="default LoginUsername no-margin login-recovery">
        <Module.Body>
          <Formik
            initialValues={getInitialValues(initialValues)}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {formik => {
              const { handleSubmit, isSubmitting, values } = formik;
              this.formik = formik;
              return (
                <form onSubmit={handleSubmit}>
                  <ScrollToFirstError {...formik} />
                  <Effect onChange={this.onFormikChange} />
                  <TextField
                    name="username.display"
                    label="Username:"
                    autoCorrect="off"
                    autoCapitalize="none"
                    value={values.username.display || ''}
                    maxLength="34"
                    autoFocus
                    normalize={normalizer.username}
                    note={loginNote} />
                  <div className="form-group">
                    <CheckboxField name="rememberMe" text="Remember Me" disabled={!navigator.cookieEnabled} />
                    {values.rememberMe && <CheckboxField name="quickBalance" text={<QuickBalanceLink />} />}
                  </div>
                  <div className="clearfix">
                    <Button
                      type="submit"
                      className="col-xs-12"
                      disabled={entMobileOutdated || isSubmitting}>
                      Continue
                    </Button>
                  </div>
                  <div className="clearfix">
                    <div className="or">
                      <div>or</div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link register-button"
                      onClick={onRegisterClick}>
                      Register for Online Banking
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Module.Body>
      </Module>
    );
  }
}

LoginUsername.propTypes = {
  entMobileOutdated: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  loginRecoverUrl: PropTypes.string.isRequired,
  onRegisterClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  quickBalanceEnabled: PropTypes.bool,
  removeQuickBalanceCookie: PropTypes.func.isRequired,
  removeRememberMeCookie: PropTypes.func.isRequired,
  startInAuth: PropTypes.func.isRequired,
};

LoginUsername.defaultProps = { quickBalanceEnabled: undefined };

export default LoginUsername;
