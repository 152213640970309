import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withPageState } from '@ent/redux-pagestate';
import { device } from '@ent/browser';
import qs from 'querystring';

import { MESSAGE_TYPE, ENT_MOBILE_ACTION } from '~/constants';
import { encrypt, decrypt, log, updateChatInformation, entMobile } from '~/common';
import { withApi, withRouter, withMessages } from '~/hocs';
import pageStateConfig from '~/pages/LoginUsername/page-state';

import connectConfig from './connect';
import { formatter } from './parsers';

const RECOVERY_MESSAGE = {
  username: u => `You have successfully recovered your username.  Your username is ${u}.`,
  password: () => 'You have successfully reset your password.',
};

class LoginTetherContent extends Component {
  async componentDidMount() {
    const { startInAuth, initCookies } = this.props;
    startInAuth();
    this.logit();
    await initCookies();
    this.initPageState();
  }

  componentDidUpdate(prevProps) {
    const { authenticated, cookies, history } = this.props;
    if (!prevProps.authenticated && authenticated) {
      history.replace('/Banking/app/accounts');
    }
    if (!prevProps.cookies && cookies) {
      this.initPageState();
    }
  }

  query() {
    const { location } = this.props;
    return qs.parse((location.search || '').slice(1) || '');
  }

  options() {
    const query = this.query();
    const on = k => query[k] === 'on';

    return {
      remChecked: on('remember'),
      qbChecked: on('remember') && on('qb'),
    };
  }

  logit() {
    const { remChecked, qbChecked } = this.options();

    log({
      category: 7, // audit
      message: 'Tethered Login',
      context: true,
      details: `RememberMe: ${remChecked}, QuickBalance: ${qbChecked}`,
    });
  }

  async initPageState() {
    const { api, cookies, history, setPageState, addMessage, redirect } = this.props;
    const { hash, rememberMe } = cookies;
    const query = this.query();

    const { remChecked, qbChecked } = this.options();

    const remembered = remChecked && (query.username || '').includes('*');
    const user = remembered ? decrypt(hash, rememberMe) : query.username;
    const { recovery } = query;

    if (recovery) {
      addMessage({
        text: RECOVERY_MESSAGE[recovery](user),
        type: MESSAGE_TYPE.SUCCESS,
        autoCloseDuration: 30000,
        owner: null,
      });
    }

    if (!formatter.user(user)) {
      history.replace('/Banking/app/login');
      return undefined;
    }

    const username = {
      display: remembered ? formatter.mask(user) : user,
      value: remembered ? formatter.user(user) : '',
    };

    try {
      const { image, phrase, showChatBubble, isMigrated, redirectUrl } = await api({
        url: '/Banking/api/authentication/reverse',
        method: 'POST',
        data: { username: user },
      });
      if (isMigrated) {
        const { isEntMobile } = device;
        if (isEntMobile) {
          entMobile.dispatch({
            type: ENT_MOBILE_ACTION.BACKBASE_REDIRECT,
            payload: { },
          });
          return undefined;
        }
        redirect(redirectUrl);
      }
      setPageState({
        ...pageStateConfig.value,
        username,
        rememberMe: remChecked ? encrypt(hash, user) : false,
        quickBalance: qbChecked,
        image,
        phrase,
        showChatBubble,
      });
      updateChatInformation(user, showChatBubble);
      history.replace('/Banking/app/login/password');
    } catch (e) {
      history.replace('/Banking/app/login');
    }

    return undefined;
  }

  render() {
    return <div className="col-xs-12 col-md-6">Loading...</div>;
  }
}

LoginTetherContent.propTypes = {
  // connect
  authenticated: PropTypes.bool.isRequired,
  cookies: PropTypes.object.isRequired,
  initCookies: PropTypes.func.isRequired,
  startInAuth: PropTypes.func.isRequired,
  // withApi
  api: PropTypes.func.isRequired,
  // withPageState
  setPageState: PropTypes.func.isRequired,
  // withRouter
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  // withMessages
  addMessage: PropTypes.func.isRequired,
};

LoginTetherContent.defaultProps = {};

export default compose(
  connect(...connectConfig),
  withApi(),
  withPageState(pageStateConfig),
  withRouter(),
  withMessages(),
)(LoginTetherContent);
