import * as CONST from '~/store/ui/constants';

const setMigrationStart = migrationStart => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.UI_MIGRATION_START,
    payload: { migrationStart },
  })
);

export default setMigrationStart;
