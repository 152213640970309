import * as CONST from '../constants';

const dequeueWorkflow = () => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.WORKFLOW_DEQUEUE,
    payload: undefined,
  })
);

export default dequeueWorkflow;
