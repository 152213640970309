import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

/* eslint-disable react/jsx-one-expression-per-line */
const LinkItem = ({ text, dataAutoId, icon, url }) => (
  <NavLink className="link-item" data-autoid={dataAutoId} activeClassName="selected" to={url}>
    <span>
      <i className={`fa fa-fw ${icon}`} aria-hidden="true" />
      &nbsp; <span>{text}</span>
    </span>
  </NavLink>
);

LinkItem.propTypes = {
  text: PropTypes.string.isRequired,
  dataAutoId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

LinkItem.defaultProps = {};

export default LinkItem;
