export const SMS_GET = 'SMS: Get';
export const SMS_GET_ERROR = 'SMS: Get: Error';
export const SMS_GET_SUCCESS = 'SMS: Get: Success';
export const SMS_RESET = 'SMS: Reset';
export const SMS_EXPIRE = 'SMS: Expire';

export const MOBILE_PROVIDER = {
  Alltel: 2,
  'AT&T': 3,
  'Boost Mobile': 4,
  Sprint: 21,
  'T-Mobile': 23,
  Verizon: 25,
  'Virgin Mobile': 27,
  TracFone: 28,
};

export const UNENROLL_REASON = {
  'I switched carriers': 2,
  'I have a new mobile number': 3,
  'My phone was lost or stolen': 4,
  'I no longer want or need this service': 5,
  'This service is not user friendly': 6,
  Other: 7,
};
