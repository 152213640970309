// prettier-ignore
export default ({
  severity = 0,
  category = 0,
  message,
  details,
  url = document.location.href,
  context,
}) => {
  const data = { severity, category, message, details, url, context };
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
  };
  return fetch('/Banking/api/user/log', options);
};
