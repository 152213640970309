export const CARDNAV_AUTH_ENABLED = {
  ON_OFF: 2 ** 1,
  MY_LOCATION: 2 ** 2,
  TRANSACTION_TYPES: 2 ** 3,
  MERCHANT_TYPES: 2 ** 4,
  MY_REGIONS: 2 ** 5,
  TRANSACTION_THRESHOLD: 2 ** 6,
  BLOCK_INTERNATIONAL_MY_COUNTRY: 2 ** 7,
  MONTHLY_SPEND_THRESHOLD: 2 ** 9,
};

export const CARDNAV_MESSAGE_ID = {
  NO_DESTINATION: 'noDestination',
  NO_ACTIVE_DESTINATION: 'noActiveDestination',
};
