import { createSelector } from 'reselect';

export const selectTableState = state => state.table;

// prettier-ignore
const selectTable = createSelector(
  selectTableState,
  (_, { id }) => id,
  (tables, id) => (id ? (tables || {})[id] : {})
);

// prettier-ignore
export const selectPage = createSelector(
  selectTable,
  ({ page } = {}) => page
);

// prettier-ignore
export const selectPageSize = createSelector(
  selectTable,
  ({ pageSize } = {}) => pageSize
);
