import { createSelector } from 'reselect';

import { TEST_STATUS } from '../constants';

export const selectTestState = state => state.test || {};

// prettier-ignore
export const selectTest = test => createSelector(
  selectTestState,
  testing => testing[test] || {}
);

// prettier-ignore
export const selectTestVariant = test => createSelector(
  selectTest(test),
  ({ variant }) => variant
);

// prettier-ignore
export const selectTestSubVariant = test => createSelector(
  selectTest(test),
  ({ subVariant }) => subVariant
);

// prettier-ignore
export const selectTestStatus = test => createSelector(
  selectTest(test),
  ({ status }) => status
);

// prettier-ignore
export const selectTestIsRunning = test => createSelector(
  selectTestStatus(test),
  status => status === TEST_STATUS.RUNNING
);
