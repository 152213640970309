import { SyncPromise, Parse } from '@ent/data-tada';
import moment from 'moment';

const dateStrict = ({
  model = dateStrict.defaults.model,
  required = dateStrict.defaults.required,
  min = dateStrict.defaults.min,
  max = dateStrict.defaults.max,
  validate = dateStrict.defaults.validate,
  messages = dateStrict.defaults.messages,
  parse = dateStrict.defaults.parse,
  formats = dateStrict.defaults.formats,
  args = dateStrict.defaults.args,
}) => Parse.date({
  model,
  required,
  min,
  max,
  validate,
  messages,
  parse,
  formats,
  args,
});

dateStrict.defaults = {
  model: SyncPromise,
  required: false,
  min: undefined,
  max: undefined,
  validate: undefined,
  messages: {
    required: 'Required',
    invalid: 'Invalid',
    min: 'Too Early',
    max: 'Too Late',
    validate: 'Invalid',
  },
  parse: parseMoment,
  formats: ['MM/YY', 'MM/YYYY'],
  args: undefined,
};

export default dateStrict;

/* HACK: moment('0_/__', 'MM/YY') should just return moment.invalid(), but it writes a console.warn
   This is a workaround provided here:
   https://github.com/moment/moment/issues/2469#issuecomment-140925573
 */
function parseMoment(value, formats) {
  if (typeof value === 'string') {
    const fallback = moment.createFromInputFallback;
    moment.createFromInputFallback = config => {
      config._d = new Date(NaN); // eslint-disable-line no-underscore-dangle, no-param-reassign
    };
    const result = moment(value, formats);
    moment.createFromInputFallback = fallback;
    return result;
  }
  return value instanceof moment ? value : moment.invalid();
}
