import { and, or, not } from '@ent/boolean';

import { SELECT_USER_ROLE } from './constants';

const { AUTHORIZED_SIGNER, OWNER, XFERS_LIMITED_TO_INTRA_ACCOUNT } = SELECT_USER_ROLE;

// prettier-ignore
export default and(
  or(AUTHORIZED_SIGNER, OWNER),
  not(XFERS_LIMITED_TO_INTRA_ACCOUNT),
);
