import React from 'react';

import { App } from '~/pages/App';
import ErrorBoundary from '~/components/ErrorBoundary';

export default (options = {}) => BaseComponent => {
  // eslint-disable-next-line react/prefer-stateless-function
  class AppComponent extends React.Component {
    render() {
      return (
        <App {...options} {...this.props}>
          <ErrorBoundary>
            <BaseComponent />
          </ErrorBoundary>
        </App>
      );
    }
  }

  AppComponent.displayName = `AppComponent(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return AppComponent;
};
