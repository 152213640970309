import { createSelector } from 'reselect';

import selectTransactionTypesRaw from './selectTransactionTypesRaw';
import selectSelectedCard from './selectSelectedCard';

const sortTransactionTypes = ({ text: textA }, { text: textB }) => {
  if (textA === 'Card Present' && textB === 'Card Not Present') {
    return -1;
  }

  if (textB === 'Card Present' && textA === 'Card Not Present') {
    return 1;
  }

  return textA.localeCompare(textB);
};

const TEXT_REWRITE_MAP = {
  'Auto Pay': 'Recurring Transactions',
  'In-store': 'Card Present',
};

const VISA_TEXT_MAP = {
  ...TEXT_REWRITE_MAP,
  eCommerce: 'Card Not Present / eCommerce',
};

const getVisaTransactionTypes = transactionTypes => transactionTypes
  .filter(t => t.bitPosition !== 7 && t.bitPosition !== 2)
  .reduce((acc, { displayLabel, bitPosition }) => {
    const text = VISA_TEXT_MAP[displayLabel] || displayLabel;

    return [
      ...acc,
      {
        text,
        value: 2 ** bitPosition,
        bitPosition,
      },
    ];
  }, [])
  .sort(sortTransactionTypes);

// prettier-ignore
export default createSelector(
  selectTransactionTypesRaw,
  selectSelectedCard,
  // eslint-disable-next-line no-confusing-arrow
  (transactionTypes = []) => getVisaTransactionTypes(transactionTypes)
);
