import React from 'react';
import PropTypes from 'prop-types';

import { withSmartyStreets } from '~/hocs/withSmartyStreets';

import { FieldFormGroup } from '../FieldFormGroup';
import Street from './Street';

export const StreetField = ({ name, ...other }) => <FieldFormGroup name={name} component={Street} {...other} />;

StreetField.propTypes = {
  name: PropTypes.string.isRequired,
  selectHandler: PropTypes.func.isRequired,
};

StreetField.defaultProps = {};

export default withSmartyStreets()(StreetField);
