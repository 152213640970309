import React from 'react';
import PropTypes from 'prop-types';

const PreviewRow = ({ clickHandler }) => (
  <div className="text-center">
    <button type="button" className="btn btn-default" onClick={clickHandler}>
      More
      <i className="fa fa-fw fa-angle-double-down" />
    </button>
  </div>
);

PreviewRow.propTypes = { clickHandler: PropTypes.func.isRequired };

PreviewRow.defaultProps = {};

export default PreviewRow;
