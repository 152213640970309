import * as CONST from '~/store/ui/constants';

const resizeUI = ({ bootstrapSize }) => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.UI_RESIZE,
    payload: { bootstrapSize },
  })
);

export default resizeUI;
