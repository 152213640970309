import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ReloadButton = ({ loading, reload }) => (
  <button type="button" className="ReloadButton btn btn-default btn-xs" onClick={reload} disabled={loading}>
    <i className={classNames('fa fa-fw fa-refresh', { 'fa-spin': loading })} />
    Refresh Balances
  </button>
);

ReloadButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  reload: PropTypes.func.isRequired,
};

ReloadButton.defaultProps = {};

export default ReloadButton;
