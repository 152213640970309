import { createSelector } from 'reselect';

import { TRANSFER_ACCOUNT_TYPE } from '~/constants';

import selectTransfer from './selectTransfer';

export const setAccountType = transferAccountType => account => ({ ...account, transferAccountType });

// prettier-ignore
export default createSelector(
  selectTransfer,
  transfer => ((transfer || {}).internalAccounts || [])
    .map(setAccountType(TRANSFER_ACCOUNT_TYPE.INTERNAL))
);
