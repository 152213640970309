import { Parse } from '@ent/data-tada';

import dateStrict from './dateStrict';
import password from './password';
import username from './username';

export default {
  ...Parse,
  dateStrict,
  password,
  username,
};
