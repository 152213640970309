import React, { Component } from 'react';
import shortid from 'shortid';

import { MessageQueueContext } from '~/common/components/MessageQueue';

export default ({ clearOnUnload = false, clearDelay = 0, owner = null } = {}) => BaseComponent => {
  const ownerName = owner || `${BaseComponent.name || 'anon'}-${shortid.generate()}`;

  // NOTE: We want to pass in the ownerName by default to addMessage/clearMessage, but we want to allow the caller
  // to explicitly override the value.
  const wrap = fn => arg => fn({
    owner: ownerName,
    ...arg,
  });

  class WithMessages extends Component {
    componentWillUnmount() {
      const { clearMessages } = this.context;
      if (clearOnUnload) {
        const clear = () => clearMessages({ owner: ownerName });
        clearDelay ? setTimeout(clear, clearDelay) : clear(); // eslint-disable-line no-unused-expressions
      }
    }

    render() {
      return (
        <MessageQueueContext.Consumer>
          {context => {
            this.context = context;
            const { addMessage, clearMessages } = context;
            return (
              <BaseComponent
                {...this.props}
                messageOwner={ownerName}
                addMessage={wrap(addMessage)}
                clearMessages={wrap(clearMessages)} />
            );
          }}
        </MessageQueueContext.Consumer>
      );
    }
  }

  WithMessages.displayName = `WithMessages(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return WithMessages;
};
