import { SyncPromise } from '@ent/data-tada';

// prettier-ignore
const promise = fn => (...args) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new SyncPromise((resolve, reject) => (fn instanceof Error ? reject(fn) : resolve(fn(...args))));

class Storage {
  constructor(type) {
    try {
      const storage = typeof type === 'string' ? window[`${type}Storage`] : type;
      this.setItem = promise(storage.setItem.bind(storage));
      this.getItem = promise(storage.getItem.bind(storage));
      this.removeItem = promise(storage.removeItem.bind(storage));
      this.clear = promise(storage.clear.bind(storage));
    } catch (e) {
      this.setItem = promise(e);
      this.getItem = promise(e);
      this.removeItem = promise(e);
      this.clear = promise(e);
    }
  }
}

Storage.local = new Storage('local');
Storage.session = new Storage('session');

export default Storage;
