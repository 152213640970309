import { ascii } from './ascii';
import { currency } from './currency';
import { date } from './date';
import { int } from './int';
import { maskMiddle, createMaskMiddle } from './maskMiddle';
import { phone } from './phone';
import { rate } from './rate';
import { suffix } from './suffix';
import { title, createTitle } from './title';
import { zip } from './zip';

export const format = {
  ascii,
  currency,
  date,
  int,
  maskMiddle,
  createMaskMiddle,
  phone,
  rate,
  suffix,
  title,
  createTitle,
  zip,
};
