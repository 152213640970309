import { log } from '~/common/log';
import { selectUser } from '~/store/user';

import { ACTIONS, TEST_STATUS } from '../constants';
import { selectTest } from '../selectors';

const completeTest = (name, goal) => (dispatch, getState) => {
  const state = getState();
  const user = selectUser(state);
  const { accountNumber, userId } = user || {};
  const { variant, status } = selectTest(name)(state) || {};

  if (status === TEST_STATUS.RUNNING) {
    log({
      category: 7, // audit
      message: 'ABTEST COMPLETE',
      details: JSON.stringify({ name, variant, goal, account: accountNumber, userId }),
    });

    dispatch({
      type: ACTIONS.COMPLETE_TEST,
      payload: {
        name,
        goal,
      },
    });
  }
};

export default completeTest;
