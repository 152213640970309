export default (reducer, initialState) => {
  let state = initialState;

  const getState = () => state;

  const dispatch = async action => {
    if (action instanceof Function) {
      const result = await action(dispatch, getState);
      if (result) {
        await dispatch(result);
      }
      return result;
    }
    state = reducer(state, action);
    return undefined;
  };

  return {
    getState,
    dispatch,
  };
};
