import { createSelector } from 'reselect';

import { selectAllCards } from '~/store/cards/selectors';
import selectCardnavCardMap from './selectCardnavCardMap';
import selectRestrictionCountMap from './selectRestrictionCountMap';
import selectSpendingLimitCountMap from './selectSpendingLimitCountMap';

// prettier-ignore
export default createSelector(
  selectAllCards,
  selectCardnavCardMap,
  selectRestrictionCountMap,
  selectSpendingLimitCountMap,
  (cards, cardnavMap, restrictionCountMap, spendingLimitRestrictionCountMap) => cards.map(card => ({
    ...card,
    cardnav: cardnavMap[card.id],
    transactionRestrictionCount: restrictionCountMap[card.id],
    spendingLimitCount: spendingLimitRestrictionCountMap[card.id],
  }))
);
