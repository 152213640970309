import { ACTIONS } from '../constants';

import initialState from './tableInitialState';

const sessionReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.REGISTER_TABLE:
      // eslint-disable-next-line no-case-declarations
      const existingState = (state || {})[payload.id] || {};
      return {
        ...state,
        [payload.id]: {
          ...payload,
          page: (payload || {}).page || existingState.page || 0,
          pageSize: (payload || {}).pageSize || existingState.pageSize || 15,
        },
      };
    case ACTIONS.NEXT_PAGE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          page: (state[payload.id] || {}).page + 1,
        },
      };
    case ACTIONS.PREVIOUS_PAGE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          page: (state[payload.id] || {}).page - 1,
        },
      };
    case ACTIONS.SET_PAGE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          page: payload.page,
        },
      };
    case ACTIONS.SET_PAGE_SIZE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          pageSize: payload.pageSize,
          page: 0,
        },
      };
    default:
      return state;
  }
};

export default sessionReducer;
