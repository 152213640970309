import Fingerprint from 'fingerprintjs2';
import UAParser from 'ua-parser-js';
import { identity, noop } from '@ent/functional';

import { hashcode } from '~/common/hashcode';

const base64Hash = s => {
  const index = s.indexOf('base64,');
  return index < 0 ? s : `${s.slice(0, index + 7)}hash:${hashcode(s.slice(index + 7))}`;
};

const splitHashJoin = s => s
  .split('~')
  .map(base64Hash)
  .join('~');

// eslint-disable-next-line camelcase
const preprocess = ({ available_resolution } = {}) => (key, value) => {
  switch (key) {
    case 'available_resolution':
      return available_resolution(value);
    case 'hardware_concurrency':
    case 'pixel_ratio':
      return typeof value === 'number' ? value : -1;
    case 'user_agent': {
      const result = new UAParser(value).getResult();
      return [
        result.device.vendor,
        result.device.model,
        result.device.type,
        result.os.name,
        ((result.os.version || '').match(/^\d+/) || [])[0],
        result.cpu.architecture,
        result.browser.name,
        result.engine.name,
        /EntMobile/i.test(result.ua) ? 'EntMobile' : undefined,
      ]
        .filter(s => !!s)
        .join(' ');
    }
    case 'canvas':
    case 'webgl':
      return splitHashJoin(value);
    default:
      return value;
  }
};

export default options => {
  const start = Date.now();

  const getFingerprint = config => {
    const preprocessor = preprocess(config);
    const fp = new Fingerprint({ preprocessor, ...options });
    return new Promise(resolve => {
      fp.get((result, components) => resolve({
        result,
        ms: Date.now() - start,
        components: components.reduce((o, { key, value }) => ({ ...o, [key]: value }), {}),
      }));
    });
  };

  return Promise.all([
    // NOTE: With 'available_resolution'
    // This was causing unnecessary fingerprint churn for some users.  We will continue to post this fingerprint for
    // a few months in order to not impact MFA.  Once people have had a few months to establish their new fingerprints
    // we can delete the old one.
    // TODO: Delete this fingerprint (maybe after July 2019) - JRW: March 2019
    getFingerprint({ available_resolution: identity }),
    // NOTE: Without 'available_resolution'
    getFingerprint({ available_resolution: noop }),
  ]);
};
