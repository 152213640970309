import { createSelector } from 'reselect';

import selectCardnavCards from './selectCardnavCards';
import selectTransactionTypesRaw from './selectTransactionTypesRaw';


// prettier-ignore
const selectCardnavCardMap = createSelector(
  selectCardnavCards,
  selectTransactionTypesRaw,
  (cardnavCards, transactionTypes) => (cardnavCards || []).reduce(
    (acc, { id, controlPolicy: { authTransactionTypeBitmap } }) => ({
      ...acc,
      [id]: transactionTypes
        .reduce((val, { bitPosition }) =>
          // eslint-disable-next-line
          ((authTransactionTypeBitmap & (2 ** bitPosition)) === 0 && bitPosition !== 7 && bitPosition !== 2
            ? val + 1
            : val), 0),
    }),
    {}
  )
);

export default selectCardnavCardMap;
