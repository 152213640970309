import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SmartyStreetsContext } from '~/common/components/SmartyStreets';

export default () => BaseComponent => {
  class WithSmartyStreets extends Component {
    componentWillUnmount() {
      const { fetchAddress, fetchAddressSuggest } = this.context;
      fetchAddress.logUsage();
      fetchAddressSuggest.logUsage();
    }

    render() {
      return (
        <SmartyStreetsContext.Consumer>
          {context => {
            this.context = context;
            return <BaseComponent {...context} {...this.props} />;
          }}
        </SmartyStreetsContext.Consumer>
      );
    }
  }

  WithSmartyStreets.contextTypes = {
    fetchAddress: PropTypes.func,
    fetchAddressSuggest: PropTypes.func,
    selectAddress: PropTypes.func,
    selectAddressIssues: PropTypes.func,
  };

  return WithSmartyStreets;
};
