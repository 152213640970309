import { createSelector } from 'reselect';
import { identity } from '@ent/functional';

const selectResult = result => result || {};

const selectResultComponents = createSelector(selectResult, result => result.components || {});

const selectStreet = createSelector(selectResult, result => result.delivery_line_1 || '');

const selectCity = createSelector(
  selectResultComponents,
  components => components.default_city_name || components.city_name || ''
);

const selectState = createSelector(selectResultComponents, components => components.state_abbreviation || '');

const selectZip = createSelector(selectResultComponents, components => components.zipcode || '');

const selectPlus4 = createSelector(selectResultComponents, components => components.plus4_code || '');

const selectZipPlus4 = createSelector(selectZip, selectPlus4, (zip, plus4) => zip + (plus4 ? `-${plus4}` : ''));

const selectAddress = createSelector(
  identity,
  selectStreet,
  selectCity,
  selectState,
  selectZip,
  selectZipPlus4,
  (result, street, city, state, zip, zipPlus4) => (!result ? null : { street, city, state, zip, zipPlus4 })
);

export default selectAddress;
