import { apiProvider } from '@ent/browser';

import * as CONST from '~/store/credit-cards/constants';
import valet from '~/store/credit-cards/valets/creditCardsValet';

const getCreditCards = ({
  force,
  state,
  reducer = 'creditCardData',
  mockProvider = null,
  redirectOn403 = true,
  autoUpdateWorkflows = true,
} = {}) => (dispatch, getState) => new Promise(resolve => {
  const should = valet.shouldGet({ force, state, reducer, getState, autoUpdateWorkflows });
  if (!should) {
    return resolve();
  }
  dispatch({ type: CONST.CREDIT_CARDS_GET });

  const request = { url: '/Banking/api/credit-cards/list', redirectOn403, autoUpdateWorkflows };
  const success = ({ creditCards }) => dispatch({
    type: CONST.CREDIT_CARDS_GET_SUCCESS,
    payload: { creditCards },
  });

  const failure = error => dispatch({ type: CONST.CREDIT_CARDS_GET_ERROR, payload: error.message });

  const provider = mockProvider || apiProvider;
  const result = provider(request)
    .then(success)
    .catch(failure);
  return resolve(result);
});

export default getCreditCards;
