import { compose } from 'redux';
import { connect } from 'react-redux';

import { withApi } from '~/hocs/withApi';
import { withMessages } from '~/hocs/withMessages';
import { withRouter } from '~/hocs/withRouter';
import { getCards } from '~/store/cards';
import {
  getCardnav,
  selectSelectedCard,
  selectIsCardnavAvailable,
  selectSelectedCardRestrictionBitmap,
} from '~/store/cardnav';
import {
  selectHasCardnavDestination,
  selectHasActiveCardnavDestination,
  getCardnavDestinations,
} from '~/store/cardnav-destinations';
import { getUser } from '~/store/user';

const select = state => ({
  hasDestination: selectHasCardnavDestination(state),
  hasActiveDestination: selectHasActiveCardnavDestination(state),
  isCardnavAvailable: selectIsCardnavAvailable(state),
  selectedCard: selectSelectedCard(state),
  restrictionBitmap: selectSelectedCardRestrictionBitmap(state),
});

// prettier-ignore
const boundActions = {
  getCards,
  getCardnav,
  getCardnavDestinations,
  getUser,
};

export default compose(
  connect(
    select,
    boundActions
  ),
  withApi(),
  withRouter(),
  withMessages({ clearOnUnload: true })
);
