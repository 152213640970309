import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';
import { compose } from 'redux';
import { or, and } from '@ent/boolean';
import { identity, isNumber, isFunction, isString, isArray } from '@ent/functional';

import { FormGroup } from '~/common/components/Fields';

import columnShape from './columnShape';
import ColumnHeader from './ColumnHeader';
import DataRow from './DataRow';
import InputText from './InputText';
import PreviewRow from './PreviewRow';
import EmptyTableRow from './EmptyTableRow';
import PageRow from './PageRow';
import './styles.scss';

const getRatio = t => w => (isNumber(w) ? `${(w * 100) / t}%` : w); // eslint-disable-line no-mixed-operators
const formatColumnWidth = w => ({ width: w, minWidth: w });
const matchId = id => c => c.id === id;

const getColumnWidths = columns => {
  const toValue = c => (isFunction(c.width) ? c.width(c, columns) : c.width);
  const widths = columns.map(toValue);
  const totalWidth = widths.reduce((t, n) => t + (isNumber(n) ? n : 0), 0);
  return widths.map(compose(formatColumnWidth, getRatio(totalWidth)));
};

const parseFilter = expr => {
  // input -> output
  // 'a "b c d" e' -> ['b c d', 'a', 'e']
  const values = [];
  expr
    .replace(/"([^"]*?)("|$)/g, (m, g) => {
      values.push(g);
      return '';
    })
    .split(' ')
    .filter(v => v !== '')
    .forEach(v => values.push(v));
  return values;
};

const isSelected = or(
  and(or(isNumber, isString), (selected, item) => item.id === selected),
  and(isArray, (selected, item) => selected.some(v => item.id === v)),
  and(isFunction, (selected, item, data) => selected(item, data))
);

const paginate = (rows, page, pageSize) => (!pageSize ? rows : rows.slice(page * pageSize, (page + 1) * pageSize));

class Table extends Component {
  state = {
    filter: '',
    sort: {
      column: null,
      ascending: true,
    },
    preview: false,
    pageIndex: 0,
  };

  filterId = shortid.generate();

  componentDidMount() {
    const { defaultSort, data, previewSize } = this.props;
    this.setState({
      sort: defaultSort || { column: null, ascending: true },
      preview: !!previewSize && previewSize < (data || []).length,
    });
  }

  get composedData() {
    const { data, columns, filterable } = this.props;
    const {
      filter,
      sort: { column, ascending },
    } = this.state;

    if (!data) return [];

    const filters = parseFilter(filter);
    const filterableColumns = columns.filter(c => !!c.filter);
    const sortColumn = columns.find(matchId(column));

    const matchFilter = o => filters.every(f => filterableColumns.some(c => c.filter(o, f)));
    const composedData = filterable && filters.length ? data.filter(matchFilter) : data;

    if (column && sortColumn && sortColumn.sort) {
      composedData.sort(sortColumn.sort);
      if (!ascending) {
        composedData.reverse();
      }
    }

    return composedData;
  }

  get filterColumns() {
    const { columns, visibleColumns } = this.props;
    return (visibleColumns || []).length ? visibleColumns.map(c => columns.find(matchId(c))).filter(identity) : columns;
  }

  get maxPageIndex() {
    const { pageSize } = this.props;
    const count = (this.composedData || []).length;
    const page = pageSize;
    return Math.floor(count / page) - (count % page ? 0 : 1);
  }

  setPage = i => {
    this.setState({ pageIndex: Math.min(Math.max(i, 0), this.maxPageIndex) });
  };

  filterChange = event => {
    this.setState({ filter: event.nativeEvent.target.value });
  };

  sort = ({ id: column }) => {
    const { sort: { column: currentColumn, ascending } } = this.state;
    this.setState({
      sort: {
        column,
        ascending: column === currentColumn ? !ascending : true,
      },
    });
  };

  endPreview = () => {
    this.setState({ preview: false });
  };

  render() {
    const {
      width,
      className,
      visibleColumns,
      data,
      dataClickHandler,
      sortable,
      previewSize,
      pageSize,
      nullMessage,
      emptyMessage,
      filterable,
      contentBeforeFilter,
      selected,
      columns,
      defaultSort,
      ...other
    } = this.props;
    const { sort, filter, pageIndex, preview } = this.state;
    const { composedData, maxPageIndex, filterColumns } = this;

    const columnWidths = getColumnWidths(filterColumns);
    const curPageIndex = Math.max(0, Math.min(pageIndex, maxPageIndex));
    const paginatedData = preview ? composedData.slice(0, previewSize) : paginate(composedData, curPageIndex, pageSize);

    const tableIsNull = !data;
    const tableIsEmpty = !tableIsNull && paginatedData.length === 0;

    return (
      <div>
        {filterable && (
          <div className="form-inline">
            {contentBeforeFilter}
            <FormGroup
              id={this.filterId}
              inline
              label="Filter:"
              component={InputText}
              value={filter}
              onChange={this.filterChange} />
          </div>
        )}
        <table className={classNames('ent-table', 'table', className)} style={{ width }} {...other}>
          <thead>
            <tr>
              {filterColumns.map((c, i) => (
                <ColumnHeader
                  key={c.id}
                  column={c}
                  sortable={sortable && !!c.sort}
                  active={sort.column === c.id}
                  ascending={sort.column !== c.id || !sort.ascending}
                  sort={this.sort}
                  style={columnWidths[i]} />
              ))}
            </tr>
          </thead>
          <tbody>
            {!tableIsNull
              && !tableIsEmpty
              && paginatedData.map((d, i) => (
                <DataRow
                  key={d.id}
                  data={d}
                  dataAutoId={i}
                  columns={filterColumns}
                  selected={isSelected(selected, d, paginatedData)}
                  clickHandler={dataClickHandler} />
              ))}
            {tableIsNull && <EmptyTableRow colSpan={filterColumns.length} message={nullMessage} />}
            {tableIsEmpty && <EmptyTableRow colSpan={filterColumns.length} message={emptyMessage} />}
            {preview && <PreviewRow colSpan={filterColumns.length} close={this.endPreview} />}
          </tbody>
          {!tableIsNull && !tableIsEmpty && !preview && !!pageSize && paginatedData.length < composedData.length && (
            <tfoot>
              <PageRow
                colSpan={filterColumns.length}
                setPage={this.setPage}
                curPage={curPageIndex}
                maxPage={maxPageIndex} />
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  width: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  columns: PropTypes.arrayOf(columnShape).isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  data: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired })
  ),
  dataClickHandler: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.func,
  ]),
  sortable: PropTypes.bool,
  filterable: PropTypes.bool,
  contentBeforeFilter: PropTypes.node,
  defaultSort: PropTypes.shape({
    column: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    ascending: PropTypes.bool.isRequired,
  }),
  previewSize: PropTypes.number,
  pageSize: PropTypes.number,
  nullMessage: PropTypes.node,
  emptyMessage: PropTypes.node,
};

Table.defaultProps = {
  width: '100%',
  visibleColumns: null,
  defaultSort: null,
  data: null,
  dataClickHandler: null,
  className: '',
  sortable: true,
  selected: data => false, // eslint-disable-line no-unused-vars
  previewSize: 0,
  pageSize: 0,
  nullMessage: 'Loading...',
  emptyMessage: 'Nothing to display',
  filterable: false,
  contentBeforeFilter: null,
};

export default Table;
