import React from 'react';
import PropTypes from 'prop-types';

import columnShape from './columnShape';

const DataCell = ({ data, column }) => <td className={column.classes}>{column.render(data)}</td>;

DataCell.propTypes = {
  data: PropTypes.object.isRequired,
  column: columnShape.isRequired, // eslint-disable-line react/no-typos
};

DataCell.defaultProps = {};

export default DataCell;
