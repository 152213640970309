import { select, all, put, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { ACTIONS } from '../constants';
import { selectTableState } from '../selectors';

function* clearTableState(action) {
  try {
    if (
      action.payload.location.pathname !== '/Banking/app/accounts/transaction'
      && action.payload.location.pathname !== '/Banking/app/accounts/detail'
    ) {
      const state = yield select() || {};
      const ids = Object.keys(selectTableState(state));
      yield all(
        ids.map(id => put({
          type: ACTIONS.SET_PAGE,
          payload: {
            id,
            page: 0,
          },
        }))
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* resetTablePaging() {
  yield takeLatest(LOCATION_CHANGE, clearTableState);
}

export default resetTablePaging;
