import * as CONST from '../constants';

import initialState from './transferInitialState';

const setKey = (state, key, action) => ({
  ...state,
  [key]: action.payload,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.GET_INTERNAL_ACCOUNTS:
      return setKey(state, 'internalAccounts', action);
    case CONST.GET_EXTERNAL_ACCOUNTS:
      return setKey(state, 'externalAccounts', action);
    case CONST.GET_TRANSFER_CONFIG:
      return setKey(state, 'config', action);
    case CONST.GET_HOST_TRANSFERS:
      return setKey(state, 'hostTransfers', action);
    case CONST.GET_RETAIL_TRANSFERS:
      return setKey(state, 'retailTransfers', action);
    case CONST.GET_PROCESSED_TRANSFERS:
      return setKey(state, 'processedTransfers', action);
    case CONST.SET_SELECTED_ACCOUNT:
      return setKey(state, 'selected', action);
    default:
      return state;
  }
};

export default reducer;
