export default value => {
  const num = parseFloat((value || 0).toString().replace(/[^\d.-]/g, ''));
  // eslint-disable-next-line no-restricted-globals
  return isNaN(num)
    ? ''
    : num
      .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      .replace(/^\((.*)\)$/, '-$1') // NOTE: IE uses parenthesis for negative numbers.
      .replace(/(\.\d*)?$/, n => (n || '.00').padEnd(3, 0)); // NOTE: S5 does not always return 2 decimals
};
