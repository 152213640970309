import React from 'react';
import PropTypes from 'prop-types';

import { MFA_METHODS } from '~/constants';

import MfaGridButton from './MfaGridButton';

const MfaGrid = ({
  config: { sms, smsLockout, call, callLockout, email, emails, emailLockout, push, pushLockout, phones },
  field,
  form,
}) => (
  <div className="row">
    <div className="MfaGrid col-xs-12">
      {call && (
        <MfaGridButton
          field={field}
          form={form}
          mode={MFA_METHODS.CALL}
          lockout={callLockout}
          text="Receive a Call"
          phones={phones} />
      )}
      {sms && (
        <MfaGridButton
          field={field}
          form={form}
          mode={MFA_METHODS.SMS}
          lockout={smsLockout}
          text="SMS/Text"
          phones={phones} />
      )}
      {push && (
        <MfaGridButton
          field={field}
          form={form}
          mode={MFA_METHODS.PUSH}
          lockout={pushLockout}
          text="Push Notification" />
      )}
      {email && (
        <MfaGridButton
          field={field}
          form={form}
          mode={MFA_METHODS.EMAIL}
          lockout={emailLockout}
          text="Email"
          emails={emails} />
      )}
    </div>
  </div>
);

MfaGrid.propTypes = {
  config: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

MfaGrid.defaultProps = {};

export default MfaGrid;
