import { createSelector } from 'reselect';

import selectUser from './selectUser';

const selectUsername = createSelector(
  selectUser,
  user => user.username || '',
);

export default selectUsername;
