import * as CONST from '../constants';

export default ({ key, mockCookie }) => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.COOKIE_REMOVE,
    payload: {
      key,
      mockCookie,
    },
  })
);
