import { and, not } from '@ent/boolean';

import { SELECT_USER_ENTITLEMENT, SELECT_USER_ROLE } from './constants';

const { TRANSFER } = SELECT_USER_ENTITLEMENT;
const { CONSERVATOR } = SELECT_USER_ROLE;

// prettier-ignore
export default and(
  TRANSFER,
  not(CONSERVATOR),
);
