import * as CONST from '~/store/user/constants';

const setUser = user => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.USER_GET_SUCCESS,
    payload: user,
  })
);

export default setUser;
