import { apiProvider } from '@ent/browser';

import { selectRequestTokens, selectCsrfToken } from '~/store/user';

import { GET_HOST_TRANSFERS } from '../constants';

const getHostTransfers = ({ autoPublishMessages } = {}) => async (dispatch, getState) => {
  const state = getState();
  const csrfToken = selectCsrfToken(state);
  const requestTokens = selectRequestTokens(state);

  const { transfers: hostTransfers } = await apiProvider({
    method: 'GET',
    url: '/Banking/api/transfers',
    csrfToken,
    requestTokens,
    autoPublishMessages,
  });

  dispatch({ type: GET_HOST_TRANSFERS, payload: hostTransfers });
};

export default getHostTransfers;
