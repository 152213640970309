import React from 'react';
import PropTypes from 'prop-types';

import { createFetchAddress, createFetchAddressSuggest, selectAddress, selectAddressIssues } from '../../smartyStreets';

import Context from './SmartyStreetsContext';

const SmartyStreetsProvider = ({ children, smartyStreetsKey }) => (
  <Context.Provider
    value={{
      fetchAddress: createFetchAddress(smartyStreetsKey),
      fetchAddressSuggest: createFetchAddressSuggest(smartyStreetsKey),
      selectAddress,
      selectAddressIssues,
    }}>
    {children}
  </Context.Provider>
);

SmartyStreetsProvider.propTypes = {
  children: PropTypes.any.isRequired,
  smartyStreetsKey: PropTypes.string.isRequired,
};

SmartyStreetsProvider.defaultProps = {};

export default SmartyStreetsProvider;
