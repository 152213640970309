import { compose } from 'recompose';
import { connect } from 'react-redux';

import { selectIsProduction } from '~/store/env';

import { withRouter } from '~/hocs/withRouter';
import { withMessages } from '~/hocs/withMessages';

export default selectPermission => {
  const select = state => ({
    permission: selectPermission(state),
    production: selectIsProduction(state),
  });

  const boundActions = {};

  return compose(
    connect(
      select,
      boundActions
    ),
    withRouter(),
    withMessages({ clearOnUnload: true })
  );
};
