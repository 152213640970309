import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getAppInsightsRef } from '~/common';

import './styles.scss';

class ErrorBoundry extends Component {
  state = { error: undefined };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    const appInsights = getAppInsightsRef();
    if (appInsights) {
      appInsights.trackException({ exception: error });
      appInsights.flush();
    }
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <div className="ErrorBoundary">
          <h1>Oops..!</h1>
          <p>Something went wrong...</p>
          <div className="button">
            <button className="refresh" type="button" onClick={() => window.location.reload()}>
              Refresh Page
            </button>
          </div>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundry.propTypes = { children: PropTypes.node };

ErrorBoundry.defaultProps = { children: null };

export default ErrorBoundry;
