import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { FormGroup } from '../FormGroup';

class NormalizingFormGroup extends Component {
  onChange = event => {
    const { normalize, field } = this.props;
    if (normalize) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = normalize(event.target.value, field.value);
    }
    field.onChange(event);
  };

  render() {
    const { content, normalize, ...other } = this.props;
    return <FormGroup component={content} {...other} onChange={this.onChange} />;
  }
}

NormalizingFormGroup.propTypes = {
  field: PropTypes.object.isRequired,
  normalize: PropTypes.func,
  content: PropTypes.func.isRequired,
};

NormalizingFormGroup.defaultProps = { normalize: undefined };

const FieldFormGroup = ({ name, component, componentOverride, ...other }) => (
  <Field name={name} component={NormalizingFormGroup} content={componentOverride || component} {...other} />
);

FieldFormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  componentOverride: PropTypes.func,
};

FieldFormGroup.defaultProps = { componentOverride: undefined };

export default FieldFormGroup;
