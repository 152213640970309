export const ACTIONS = {
  CREATE_TEST: 'Test: Create Test',
  SET_GOAL: 'Test: Set Goal',
  SET_VARIANT: 'Test: Set Variant',
  SET_SUB_VARIANT: 'Test: Set Sub Variant',
  SET_STATUS: 'Test: Set Status',
  COMPLETE_TEST: 'Test: Complete Test',
};

export const TEST_STATUS = {
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  PAUSED: 'PAUSED',
};
