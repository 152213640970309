export const USER_ROLE = {
  ACH_TRANSFERS_DISABLED: 1,
  ACH_TRANSFERS_USER_PROFILE_ACTIVE: 2,
  ACH_TRANSFERS_USER_PROFILE_DISABLED: 3,
  ACH_TRANSFERS_USER_PROFILE_LOCKED: 4,
  ADDRESS_TYPE_IS_DOMESTIC: 5,
  ADDRESS_TYPE_IS_FOREIGN: 6,
  ADDRESS_TYPE_IS_UNKNOWN: 7,
  AOP_CAN_SETUP_PROFILE: 8,
  AOP_PROFILE_ESTABLISHED: 9,
  AUTHENTICATED_USERS: 10,
  AUTHORIZED_SIGNER: 11,
  BETA_TEST_ACCOUNT: 12,
  BILLPAY_ENROLLED: 13,
  BILLPAY_NO_ACCOUNTS: 14,
  BILLPAY_NO_PAYEES: 15,
  BILLPAY_NOT_ENROLLED: 16,
  BUSINESS_ACCOUNT: 17,
  BUSINESS_AND_CORPORATE_ACCOUNT_TYPES: 18,
  BUSINESS_PRINCIPAL: 19,
  BUSINESS_RDC: 20,
  CONSERVATOR: 21,
  CORPORATE_ACCOUNT: 22,
  DISCLOSURE_ACCOUNT_AGREEMENT_OPEN: 23,
  DISCLOSURE_REGISTRATION_DISCLOSURE_OPEN: 24,
  DISCLOSURE_MANAGER_ALL_ACCEPTED: 25,
  DISCLOSURES: 26,
  EMPLOYEE_ACCOUNT: 27,
  ESTATE_MEMORIAL_ACCOUNT: 28,
  FINANCES_PREFERENCES_CONFIGURED: 29,
  FINANCES_PREFERENCES_NOT_CONFIGURED: 30,
  GOLDLEAF: 31,
  HAS_QUICK_LOGINS: 32,
  IMPERSONATED: 33,
  INBRANCH: 34,
  IS_IN_MEMO_POST_MODE: 35,
  MANAGE_USER: 36,
  NEW_MEMBER_WARNING: 37,
  NEW_USER: 38,
  OWNER: 39,
  PUSH_DEVICE: 40,
  QUICK_LOGIN_AUTHENTICATED: 41,
  REQUIRED_TO_CHANGE_EMAIL: 42,
  REQUIRED_TO_CHANGE_PASSWORD: 43,
  RESTRICT_BILL_PAY: 44,
  SERVERMODE_DEVELOPMENT: 45,
  SERVERMODE_NOT_PRODUCTION: 46,
  SERVERMODE_PRODUCTION: 47,
  SERVERMODE_TEST: 48,
  SERVERMODE_TRAINING: 49,
  TRUST_ACCOUNT: 50,
  USER_CAN_CHANGE_LOGINNAME: 51,
  XFERS_LIMITED_TO_INTRA_ACCOUNT: 52,
  BAD_ADDRESS: 53,
  BAD_EMAIL: 54,
  NO_PHONE: 55,
  OLD_VERIFY_DATE: 56,
  MFA: 57,
  ACCOUNTS_BETA: 58,
  CARDNAV: 59,
  SAVINGS_ONLY_ACCOUNT: 60,
  EMPLOYEE_ACCOUNT_TYPE_1: 61,
  NO_LOANS: 63,
  MINOR_NO_JOINT: 64,
  CU_OFFICIAL_ACCOUNT: 65,
  OFX_MASKED_ACCOUNTS: 69,
  OAO_FUNDING: 70,
  OAO_FUNDED: 74,
  OAO_JUST_FUNDED: 75,
  CREDIT_CARDS_BETA: 78,
  MINOR: 79,
};
