import Cookie from 'universal-cookie';

import * as CONST from '../constants';
import initialState from './cookieInitialState';

const defaultCookie = new Cookie();

export default (state = initialState, action) => {
  switch (action.type) {
    case CONST.COOKIE_INIT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONST.COOKIE_INIT_END:
      return {
        ...state,
        loading: false,
      };
    case CONST.COOKIE_REMOVE: {
      const { key, mockCookie } = action.payload;
      const cookie = mockCookie || defaultCookie;
      cookie.remove(key, CONST.COOKIE.OPTIONS);
      return (state.data || {})[key] === undefined
        ? state
        : {
          ...state,
          data: {
            ...state.data,
            [key]: undefined,
          },
        };
    }
    case CONST.COOKIE_SET: {
      const { key, value, mockCookie } = action.payload;
      const cookie = mockCookie || defaultCookie;
      cookie.set(key, value, CONST.COOKIE.OPTIONS);
      return (state.data || {})[key] === value
        ? state
        : {
          ...state,
          data: {
            ...state.data,
            [key]: value,
          },
        };
    }
    default:
      return state;
  }
};
