import { createSelector } from 'reselect';

import { CARDNAV_AUTH_ENABLED } from '~/constants/cardnav';

import selectSelectedCard from './selectSelectedCard';
import selectSelectedCardRestrictionBitmap from './selectSelectedCardRestrictionBitmap';

// prettier-ignore
export default createSelector(
  selectSelectedCard,
  selectSelectedCardRestrictionBitmap,
  (card, restrictions) => ({
    // eslint-disable-next-line no-bitwise
    enabled: !!(restrictions & CARDNAV_AUTH_ENABLED.MONTHLY_SPEND_THRESHOLD),
    value: CARDNAV_AUTH_ENABLED.MONTHLY_SPEND_THRESHOLD,
    amount: card && card.cardnav ? card.cardnav.controlPolicy.authMonthlySpendThreshold : 0,
    current: card && card.cardnav ? card.cardnav.monthlySpend : 0,
  })
);
