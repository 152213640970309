import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PreviewRow extends Component {
  click = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const { colSpan } = this.props;

    return (
      <tr>
        <td className="text-center" colSpan={colSpan}>
          <button type="button" className="btn btn-default" onClick={this.click}>
            More
            <i className="fa fa-fw fa-angle-double-down" />
          </button>
        </td>
      </tr>
    );
  }
}

PreviewRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

PreviewRow.defaultProps = {};

export default PreviewRow;
