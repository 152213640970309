import { createValet } from '~/common/redux/valet';
import * as CONST from '~/store/env/constants';
import history from '~/store/history';
import initialState from '~/store/env/valets/envInitialState';

const envValet = createValet({
  defaultReducer: 'env',
  history,
  initialState,
  msCacheDuration: 60 * 1000, // 1 minute
  GET: CONST.ENV_GET,
  ERROR: CONST.ENV_GET_ERROR,
  SUCCESS: CONST.ENV_GET_SUCCESS,
  RESET: CONST.ENV_RESET,
  EXPIRE: CONST.ENV_EXPIRE,
});

export default envValet;
