import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button, CheckboxField, ScrollToFirstError } from '@ent/fields';
import { Module } from '@ent/component';

import { getInitialValues, validationSchema } from './form';
import './styles.scss';

const Disclosure = ({ title, onSubmit, children }) => (
  <Module>
    <Module.Head>
      <h1>{title}</h1>
    </Module.Head>
    <Module.Body>
      <Formik initialValues={getInitialValues()} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            <ScrollToFirstError isSubmitting={isSubmitting} isValid={isValid} />
            <div className="div-scrollable">{children}</div>
            <CheckboxField
              name="accepted"
              label="Terms and Conditions"
              autoFocus
              text="I acknowledge I have reviewed the terms of the agreement." />
            <div>
              <Button
                className="col-xs-12 col-sm-6 col-lg-4 col-sm-offset-6 col-lg-offset-8"
                type="submit"
                disabled={isSubmitting}>
                Accept
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Module.Body>
  </Module>
);

Disclosure.propTypes = {
  title: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

Disclosure.defaultProps = {};

export default Disclosure;
