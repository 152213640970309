import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/jsx-one-expression-per-line */
const Address = ({ street, city, state, zip }) => (
  <div>
    {street}
    <br />
    {city} {state}, {zip}
  </div>
);

Address.propTypes = {
  street: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
};

export default Address;
