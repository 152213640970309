import { take, put } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import shortid from 'shortid';
import { EVENT_MESSAGE_TYPE, scroll } from '@ent/browser';

import { MESSAGES_ADD, MESSAGES_CLEAR } from '../constants';

const channelInstance = channel();

// prettier-ignore
Object.values(EVENT_MESSAGE_TYPE)
  .forEach(emt => window.addEventListener(emt, message => channelInstance.put(message)));

function* addMessage(message) {
  const { text, type, autoScroll } = message.detail;
  yield put({
    type: MESSAGES_ADD,
    payload: {
      id: shortid.generate(),
      owner: null,
      type,
      text,
    },
  });
  if (autoScroll) {
    // NOTE: initiate scroll after next paint
    requestAnimationFrame(() => scroll('.message-queue'));
  }
}

function* clearMessages(message) {
  const { all = false, owner = null, id = null } = message.detail;
  yield put({
    type: MESSAGES_CLEAR,
    payload: { all, owner, id },
  });
}

function* windowEvent() {
  while (true) {
    const message = yield take(channelInstance);
    if ((message || {}).type === EVENT_MESSAGE_TYPE.ADD_MESSAGE) {
      yield addMessage(message);
    }
    if ((message || {}).type === EVENT_MESSAGE_TYPE.CLEAR_MESSAGES) {
      yield clearMessages(message);
    }
  }
}

export default windowEvent;
