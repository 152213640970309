import { apiProvider } from '@ent/browser';

import { selectRequestTokens, selectCsrfToken } from '~/store/user';

import { GET_TRANSFER_CONFIG } from '../constants';

const getTransferConfig = () => async (dispatch, getState) => {
  const state = getState();
  const csrfToken = selectCsrfToken(state);
  const requestTokens = selectRequestTokens(state);

  const config = await apiProvider({
    method: 'GET',
    url: '/Banking/api/transfer/retail/new',
    csrfToken,
    requestTokens,
  });

  dispatch({ type: GET_TRANSFER_CONFIG, payload: config });
};

export default getTransferConfig;
