import * as CONST from '../constants';

const clearWorkflow = () => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch({
    type: CONST.WORKFLOW_CLEAR,
    payload: undefined,
  })
);

export default clearWorkflow;
