import { addMessage } from '~/store/messages';

export default ({ dispatch }) => {
  const queue = window.messageQueue || [];

  while (queue && queue.length > 0) {
    const message = queue.pop();
    if (message) {
      dispatch(addMessage(message));
    }
  }
};
