import { Parse, Format } from '~/common';

const init = { string: config => Parse.string({ required: true, ...config }) };

export const formatter = {
  account: init
    .string()
    .then(Format.string.right(3))
    .then(Format.string.padStart(1, '*'))
    .catch(Format.string.empty)
    .value.resolved(),
  switch: a => `Switch Accounts ${formatter.account(a)}`,
};
