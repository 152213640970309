import { connect } from 'react-redux';

import { addMessage, clearMessages, selectAllMessages } from '~/store/messages';

const select = state => ({ messages: selectAllMessages(state) });

const boundActions = {
  addMessage,
  clearMessages,
};

export default connect(
  select,
  boundActions
);

export const minimalDecorator = connect(
  () => ({}),
  boundActions
);
