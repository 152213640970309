import { createSelector } from 'reselect';

import selectUser from './selectUser';

const selectPrimaryAccountNumber = createSelector(
  selectUser,
  user => user.accountNumber
);

export default selectPrimaryAccountNumber;
