import React from 'react';
import loadable from 'react-loadable';

import { page } from '~/hocs';

import LoginTether from './LoginTether';
import LoginUsername from './LoginUsername';
import LoginPassword from './LoginPassword';

const LoadingPage = page()(() => <div />);

const shim = loader => loadable({ loader, loading: LoadingPage });

export default {
  // Preloaded pages
  LoginPassword,
  LoginTether,
  LoginUsername,
  // Loadable pages
  AccountOpening: shim(() => import('./AccountOpening')),
  AccessDenied: shim(() => import('./AccessDenied')),
  AccountList: shim(() => import('./AccountList')),
  AccountDetails: shim(() => import('./AccountDetails')),
  AccountDetailsLoanPayoff: shim(() => import('./AccountDetailsLoanPayoff')),
  AccountDetailsLoanPayoffPrint: shim(() => import('./AccountDetailsLoanPayoffPrint')),
  AccountDetailsPrint: shim(() => import('./AccountDetailsPrint')),
  AccountDetailsTransactionsPrint: shim(() => import('./AccountDetailsTransactionsPrint')),
  AccountDetailsCheckPrint: shim(() => import('./AccountDetailsCheckPrint')),
  AccountTransactionDetails: shim(() => import('./AccountTransactionDetails')),
  AccountTransactionDetailsPrint: shim(() => import('./AccountTransactionDetailsPrint')),
  AccountTransactionCheckPrint: shim(() => import('./AccountTransactionCheckPrint')),
  AccountFavorites: shim(() => import('./AccountFavorites')),
  AddressPhone: shim(() => import('./AddressPhone')),
  BackbaseMigrationVerify: shim(() => import('./BackbaseMigrationVerify')),
  BillPay: shim(() => import('./BillPay')),
  BillPayIERedirect: shim(() => import('./BillPayIERedirect')),
  BroncosCheckingConversion: shim(() => import('./BroncosCheckingConversion')),
  CallCenterPassword: shim(() => import('./CallCenterPassword')),
  Cards: shim(() => import('./Cards')),
  CardActivateAuthenticated: shim(() => import('./CardActivateAuthenticated')),
  CardActivateUnauthenticated: shim(() => import('./CardActivateUnauthenticated')),
  CardActivateUnauthenticatedSuccess: shim(() => import('./CardActivateUnauthenticatedSuccess')),
  CardAlerts: shim(() => import('./CardAlerts')),
  CardFreeze: shim(() => import('./CardFreeze')),
  CardPinChange: shim(() => import('./CardPinChange')),
  CardRestriction: shim(() => import('./CardRestriction')),
  CardSpendingLimit: shim(() => import('./CardSpendingLimit')),
  CardTravelNoteEdit: shim(() => import('./CardTravelNoteEdit')),
  CardUnfreeze: shim(() => import('./CardUnfreeze')),
  CertificateDividendOptions: shim(() => import('./CertificateDividendOptions')),
  CertificateMaturityOptions: shim(() => import('./CertificateMaturityOptions')),
  ContactInfo: shim(() => import('./ContactInfo')),
  ContactUs: shim(() => import('./ContactUs')),
  CreditCardDetail: shim(() => import('./CreditCardDetail')),
  DesktopDepositChecks: shim(() => import('./DesktopDepositChecks')),
  Disclosure: shim(() => import('./Disclosure')),
  Downloads: shim(() => import('./Downloads')),
  Email: shim(() => import('./Email')),
  EntProtectEnroll: shim(() => import('./EntProtectEnroll')),
  EntProtectEnrollConfirmation: shim(() => import('./EntProtectEnrollConfirmation')),
  EntProtectProductPage: shim(() => import('./EntProtectProductPage')),
  EntProtectInformation: shim(() => import('./EntProtectInformation')),
  Finances: shim(() => import('./Finances')),
  FinancesAbout: shim(() => import('./FinancesAbout')),
  FinancesConfigure: shim(() => import('./FinancesConfigure')),
  ForgotPassword: shim(() => import('./ForgotPassword')),
  ForgotPasswordComplete: shim(() => import('./ForgotPasswordComplete')),
  LifeHappens: shim(() => import('./LifeHappens')),
  LoanApplicationContinue: shim(() => import('./LoanApplicationContinue')),
  LoanApplicationNew: shim(() => import('./LoanApplicationNew')),
  LoanApplicationSelect: shim(() => import('./LoanApplicationSelect')),
  LoanPayUnauthSplash: shim(() => import('./LoanPayUnauthSplash')),
  LoanPayUnauthPayment: shim(() => import('./LoanPayUnauthPayment')),
  LoanPayUnauthSuccess: shim(() => import('./LoanPayUnauthSuccess')),
  LoanPayUnauthVerify: shim(() => import('./LoanPayUnauthVerify')),
  LoanPreQual: shim(() => import('./LoanPreQual')),
  LoanSkipPay: shim(() => import('./LoanSkipPay')),
  Locations: shim(() => import('./Locations')),
  LoginSuccess: shim(() => import('./LoginSuccess')),
  MemberAmbassador: shim(() => import('./MemberAmbassador')),
  MfaCall: shim(() => import('./MfaCall')),
  MfaChallenge: shim(() => import('./MfaChallenge')),
  MfaEmail: shim(() => import('./MfaEmail')),
  MfaFail: shim(() => import('./MfaFail')),
  MfaPush: shim(() => import('./MfaPush')),
  MfaSms: shim(() => import('./MfaSms')),
  MfaSuccess: shim(() => import('./MfaSuccess')),
  MortgageEscrowVideo: shim(() => import('./MortgageEscrow')),
  OptInPhoneInterstitial: shim(() => import('./OptInPhoneInterstitial')),
  OrderChecks: shim(() => import('./OrderChecks')),
  Overdraft: shim(() => import('./Overdraft')),
  Password: shim(() => import('./Password')),
  Page: shim(() => import('./Page')),
  Profile: shim(() => import('./Profile')),
  Promotions: shim(() => import('./Promotions')),
  QuickBalance: shim(() => import('./QuickBalance')),
  SecurityProfile: shim(() => import('./SecurityProfile')),
  Services: shim(() => import('./Services')),
  StatementPreferences: shim(() => import('./StatementPreferences')),
  Statements: shim(() => import('./Statements')),
  StopPayment: shim(() => import('./StopPayment')),
  TaxPayments: shim(() => import('./TaxPayments')),
  TextBanking: shim(() => import('./TextBanking')),
  TextBankingEnroll: shim(() => import('./TextBankingEnroll')),
  TextBankingUnenroll: shim(() => import('./TextBankingUnenroll')),
  TransferLoan: shim(() => import('./Transfer')),
  TransferLoanAccount: shim(() => import('./TransferAccount')),
  TransferLoanAccountExternalEdit: shim(() => import('./TransferAccountExternalEdit')),
  TransferLoanAccountExternalVerify: shim(() => import('./TransferAccountExternalVerify')),
  TransferLoanAccountInternalEdit: shim(() => import('./TransferAccountInternalEdit')),
  TransferLoanEdit: shim(() => import('./TransferEdit')),
  TransferShare: shim(() => import('./Transfer')),
  TransferShareAccount: shim(() => import('./TransferAccount')),
  TransferShareAccountExternalEdit: shim(() => import('./TransferAccountExternalEdit')),
  TransferShareAccountExternalVerify: shim(() => import('./TransferAccountExternalVerify')),
  TransferShareAccountInternalEdit: shim(() => import('./TransferAccountInternalEdit')),
  TransferShareEdit: shim(() => import('./TransferEdit')),
  Registration: shim(() => import('./Registration')),
  RegistrationComplete: shim(() => import('./RegistrationComplete')),
  UserActivity: shim(() => import('./UserActivity')),
  UserEdit: shim(() => import('./UserEdit')),
  Username: shim(() => import('./Username')),
  UsersList: shim(() => import('./UsersList')),
};
