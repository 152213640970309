import { apiProvider } from '@ent/browser';

import { selectRequestTokens, selectCsrfToken } from '~/store/user';

import { GET_PROCESSED_TRANSFERS } from '../constants';

const getProcessedTransfers = () => async (dispatch, getState) => {
  const state = getState();
  const csrfToken = selectCsrfToken(state);
  const requestTokens = selectRequestTokens(state);

  const processedTransfers = await apiProvider({
    method: 'GET',
    url: '/Banking/api/transfer/retail/processed?maxCount=50',
    csrfToken,
    requestTokens,
  });

  dispatch({ type: GET_PROCESSED_TRANSFERS, payload: processedTransfers });
};

export default getProcessedTransfers;
