import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button, ScrollToFirstError } from '@ent/fields';
import { Module } from '@ent/component';

import { FieldFormGroup } from '~/common';

import { getInitialValues, validationSchema } from './form';
import Help, { helpText } from './Help';
import MfaGrid from './MfaGrid';
import './styles.scss';

/* eslint-disable react/jsx-one-expression-per-line */
const MfaChallenge = ({ config, initialValues, xs, onSubmit }) => (
  <Module data-autoid="MfaChallenge">
    <Module.Head>
      <h1>Authenticate</h1>
      {xs && <Help />}
    </Module.Head>
    <Module.Body>
      <Formik initialValues={getInitialValues(initialValues)} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            <ScrollToFirstError isSubmitting={isSubmitting} isValid={isValid} />
            {!xs && <div className="small">{helpText}</div>}
            <FieldFormGroup name="mode" label="Choose a method:" component={MfaGrid} config={config} />
            <div className="buttons row">
              <Button
                type="submit"
                className="col-xs-12 col-sm-6 col-lg-4 col-sm-offset-6 col-lg-offset-8"
                disabled={isSubmitting}>
                Continue
              </Button>
            </div>
            <p className="small text-muted">
              Don't recognize this contact information? Contact the account's owner to update the information on this
              account or call us at{' '}
              <a className="text-nowrap" href="tel:7195741100">
                (719) 574-1100
              </a>{' '}
              or{' '}
              <a className="text-nowrap" href="tel:18005259623">
                800-525-9623
              </a>
              .
            </p>
            <p className="small text-muted">
              There is no charge for account authentication; however charges from your mobile service provider may
              apply.
            </p>
          </form>
        )}
      </Formik>
    </Module.Body>
  </Module>
);

MfaChallenge.propTypes = {
  initialValues: PropTypes.object.isRequired,
  xs: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

MfaChallenge.defaultProps = {};

export default MfaChallenge;
