import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import columnShape from './columnShape';

const label = (active, ascending) => classNames({
  '': !active,
  'Sorted descending': active && ascending,
  'Sorted ascending': active && !ascending,
});

const classes = (active, ascending) => classNames({
  fa: true,
  'fa-fw': true,
  'fa-caret-down': active && ascending,
  'fa-caret-up': active && !ascending,
});

class ColumnHeader extends Component {
  // eslint-disable-next-line no-unused-vars
  click = event => {
    const { column, sort } = this.props;
    sort(column);
  };

  render() {
    const { sortable, column, active, ascending, style } = this.props;

    return (
      <th className={column.classes} scope="col" style={style}>
        {!sortable && column.title}
        {sortable && (
          <a href="#" onClick={this.click}>
            {column.title}
            <i aria-label={label(active, ascending)} className={classes(active, ascending)} />
          </a>
        )}
      </th>
    );
  }
}

ColumnHeader.propTypes = {
  sortable: PropTypes.bool.isRequired,
  column: columnShape.isRequired, // eslint-disable-line react/no-typos
  active: PropTypes.bool.isRequired,
  ascending: PropTypes.bool.isRequired,
  sort: PropTypes.func.isRequired,
  style: PropTypes.object,
};

ColumnHeader.defaultProps = { style: {} };

export default ColumnHeader;
