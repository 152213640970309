import { createSelector } from 'reselect';

import selectIsProduction from './selectIsProduction';

const selectStripeKey = createSelector(
  selectIsProduction,
  prod => (prod ? 'NO ACCESS YET' : 'pk_test_NVWgklf7PYK3DXC78C3aZVu4')
);

export default selectStripeKey;
