import qs from 'querystring';
import { memoize } from '@ent/functional';

const memoizeKey = args => JSON.stringify(args).toLowerCase();

const createFetchAddress = memoize(
  key => {
    let callCount = 0;

    const fetchAddress = memoize(
      ({ street, city, state, zip, zipPlus4 }) => {
        // eslint-disable-line arrow-body-style
        const promise = new Promise((resolve, reject) => {
          const url = `https://us-street.api.smartystreets.com/street-address?${qs.encode({
            'auth-id': key,
            candidates: 1,
            street,
            city,
            state,
            zipcode: zipPlus4 || zip,
          })}`;

          fetch(url, { 'content-type': 'application/json' }).then(resp => {
            const { status, statusText } = resp;

            if (status === 200) {
              return resp.json().then(json => {
                callCount++;

                const result = json[0];
                if (!zip && result && result.components && result.components.zipcode) {
                  // NOTE: If we look up an address without a zip and get a result back,
                  // then we should memoize the result with the zip to avoid further fetches.
                  const keyWithZip = memoizeKey([
                    {
                      street,
                      city,
                      state,
                      zip: result.components.zipcode,
                      zipPlus4: result.components.zipcode,
                    },
                  ]);
                  fetchAddress.cache.add(keyWithZip, promise);
                }
                return resolve(result);
              });
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({ status, statusText });
          });

          // eslint-disable-next-line prefer-promise-reject-errors
          setTimeout(() => reject({ timeout: true }), 1500);
        });
        return promise;
      },
      { key: memoizeKey }
    );

    fetchAddress.logUsage = () => {
      if (callCount) {
        window.dataLayer.push({
          event: 'Custom Event',
          action: 'SmartyStreets: Validate: Usage',
          value: callCount,
        });
        callCount = 0;
      }
    };

    return fetchAddress;
  },
  { ttl: 0 }
);

export default createFetchAddress;
