import { ACTIONS } from '../constants';

const setStatus = (name, status) => ({
  type: ACTIONS.SET_STATUS,
  payload: {
    name,
    status,
  },
});

export default setStatus;
