import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withApi } from '~/hocs/withApi';
import { withMessages } from '~/hocs/withMessages';
import { getUser as doGetUser, selectUser, selectIsAuthenticated } from '~/store/user';

// prettier-ignore
const selectUserAccepted = disclosure => createSelector(
  selectUser,
  user => !!user.disclosures && user.disclosures.some(d => d === disclosure)
);

export default disclosure => {
  const select = state => ({
    authenticated: selectIsAuthenticated(state),
    userAccepted: selectUserAccepted(disclosure)(state),
  });

  const boundActions = { getUser: doGetUser };

  return compose(
    connect(
      select,
      boundActions
    ),
    withApi(),
    withMessages({ clearOnUnload: true })
  );
};
