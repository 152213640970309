import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PreviewRow from './PreviewRow';
import PageRow from './PageRow';

class Repeater extends Component {
  constructor(props) {
    super(props);

    const { previewSize, data } = props;

    this.state = {
      preview: !!previewSize && previewSize < data.length,
      pageIndex: 0,
    };
  }

  setPage = i => {
    const { pageIndex: curPageIndex } = this.state;
    const normalize = n => Math.min(Math.max(n, 0), this.maxPageIndex());
    const pageIndex = normalize(i);

    if (curPageIndex !== pageIndex) {
      this.setState({ pageIndex });
    }
  };

  endPreview = () => {
    this.setState({ preview: false });
  };

  maxPageIndex() {
    const {
      data: { length },
      pageSize,
    } = this.props;
    const max = Math.floor(length / pageSize) - (length % pageSize ? 0 : 1);
    return max || 0;
  }

  render() {
    const { data, template: Template, previewSize, pageSize, className, emptyMessage, ...other } = this.props;
    const { preview, pageIndex: defaultPageIndex } = this.state;

    const pageIndex = Math.min(defaultPageIndex, this.maxPageIndex());

    const paginate = d => (!pageSize ? d : d.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize));
    const filterData = d => (preview ? d.slice(0, previewSize) : paginate(d));

    const filteredData = filterData(data);

    if (!filteredData.length) {
      return <div className={className}>{emptyMessage}</div>;
    }

    return (
      <div className={className}>
        {filteredData.map((d, i) => (
          <Template
            key={d.id || i}
            {...other}
            index={i}
            current={d}
            previous={filteredData[i - 1]}
            next={filteredData[i + 1]} />
        ))}
        {preview && <PreviewRow clickHandler={this.endPreview} />}
        {!preview
          && !!pageSize
          && filteredData.length < data.length && (
            <PageRow setPage={this.setPage} curPage={pageIndex} maxPage={this.maxPageIndex()} />
        )}
      </div>
    );
  }
}

Repeater.propTypes = {
  data: PropTypes.array.isRequired,
  template: PropTypes.func.isRequired,
  previewSize: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};

Repeater.defaultProps = {
  previewSize: 0,
  pageSize: 0,
  className: '',
  emptyMessage: 'Nothing to display',
};

export default Repeater;
