import { Format } from '@ent/data-tada';

Format.string.title.defaults.replacements = {
  ...Format.string.title.defaults.replacements,
  esa: 'ESA',
  heloc: 'HELOC',
  hsa: 'HSA',
  loc: 'LOC',
  rloc: 'RLOC',
  valueplus: 'ValuePLUS',
};

Format.string.maskPhone = s => (s || '').substr(-4).padStart(14, '(***) ***-????');

export default Format.string;
