import { selectWalkthroughs } from '../selectors';
import setUserProfile from './setUserProfile';

const setWalkthroughComplete = key => (dispatch, getState) => {
  const current = selectWalkthroughs(getState()).filter(v => v !== key);

  return setUserProfile({ walkthroughs: [...current, key] })(dispatch, getState);
};

export default setWalkthroughComplete;
