require('whatwg-fetch');
require('custom-event-polyfill');

require('core-js/stable/array-buffer');
require('core-js/stable/array');
require('core-js/stable/data-view');
require('core-js/stable/date');
require('core-js/stable/function');
require('core-js/stable/json');
require('core-js/stable/map');
require('core-js/stable/math');
require('core-js/stable/number');
require('core-js/stable/object');
require('core-js/stable/promise');
require('core-js/stable/reflect');
require('core-js/stable/regexp');
require('core-js/stable/set');
require('core-js/stable/string');
require('core-js/stable/symbol');
require('core-js/stable/typed-array');
require('core-js/stable/weak-map');
require('core-js/stable/weak-set');
require('core-js/stable/global-this');
require('core-js/stable/parse-float');
require('core-js/stable/parse-int');

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global); // eslint-disable-line global-require
}
