import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ShowHideLink = ({ onClick, show, fieldName, fieldDescription }) => (
  <div className="clearfix ShowHideLink">
    <button
      type="button"
      className="clearfix btn btn-link pull-right"
      data-autoid={`show-hide-${fieldName}`}
      onClick={onClick}
      aria-expanded={show}
      aria-controls={fieldName}>
      {show
        ? (
        <>
          <i className="fa fa-eye-slash" aria-hidden="true" />
          { ` Hide ${fieldDescription}`}
        </>
        )
        : (
        <>
          <i className="fa fa-eye" aria-hidden="true" />
          {` Show ${fieldDescription}`}
        </>
        )}
    </button>
  </div>
);

ShowHideLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldDescription: PropTypes.string.isRequired,
};

ShowHideLink.defaultProps = {};

export default ShowHideLink;
