import React, { Component } from 'react';
import PropTypes from 'prop-types';

import decorator from './decorators';

const withFormik = ({ allowImpersonated = false } = {}) => BaseComponent => {
  class WithFormik extends Component {
    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    onSubmit = async (values, formik) => {
      try {
        const { denyImpersonation, onSubmit } = this.props;
        const onSubmitFn = allowImpersonated ? onSubmit : denyImpersonation(onSubmit);
        await onSubmitFn(values, formik);
      } finally {
        if (this.mounted) {
          formik.setSubmitting(false);
          if (values.recaptcha) {
            await formik.setFieldValue('recaptcha', undefined);
          }
        }
      }
    };

    render() {
      return <BaseComponent {...this.props} onSubmit={this.onSubmit} mounted={this.mounted} />;
    }
  }

  WithFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    // withImpersonation
    denyImpersonation: PropTypes.func.isRequired,
  };

  WithFormik.defaultProps = {};

  WithFormik.displayName = `WithFormik(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return decorator(WithFormik);
};

export default withFormik;
