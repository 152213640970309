import { createSelector } from 'reselect';
import { BOOTSTRAP_SIZE } from '@ent/browser';

import { selectIsAuthenticated } from '~/store/user/selectors';

import selectUI from './selectUI';
import selectBootstrapSize from './selectBootstrapSize';

const selectShowSideNav = createSelector(
  selectIsAuthenticated,
  selectUI,
  selectBootstrapSize,
  (auth, ui, bsSize) => auth && (ui.showSideNav || bsSize === BOOTSTRAP_SIZE.XL)
);

export default selectShowSideNav;
