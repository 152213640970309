import { memoize } from '@ent/functional';

import { Format } from '../data-tada';
import aes from './aes';

export default memoize(
  (key, value) => {
    if (key && value) {
      const hash = Format.string.charCodeArray(key);
      const block = Format.string.charCodeArray(value);
      aes().encrypt(block, hash);
      return Format.array.toHex(block);
    }
    return undefined;
  },
  { ttl: 0 }
);
