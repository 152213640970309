import { BOOTSTRAP_SIZE } from '@ent/browser';

import { selectIsBootstrapSize } from '~/store/ui';

import { selectConfig, selectDefaultMethod, selectShouldFail } from './selectors';

const select = state => ({
  config: selectConfig(state),
  defaultMethod: selectDefaultMethod(state),
  fail: selectShouldFail(state),
  xs: selectIsBootstrapSize(BOOTSTRAP_SIZE.XS)(state),
});

const boundActions = {};

export default [select, boundActions];
