import { createSelector } from 'reselect';

import selectUnexpiredCards from './selectUnexpiredCards';

const selectActiveCards = createSelector(selectUnexpiredCards, cards => {
  const filter = s => s.activationDate && !s.closedDate;
  return cards.filter(filter);
});

export default selectActiveCards;
