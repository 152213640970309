import { apiProvider } from '@ent/browser';

import * as CONST from '~/store/suffixes/constants';
import valet from '~/store/suffixes/valets/suffixesValet';

const getSuffixes = ({ force, state, reducer = 'suffixes', mockProvider = null, autoUpdateWorkflows = true } = {}) => (
  dispatch,
  getState,
) => new Promise(resolve => {
  const should = valet.shouldGet({ force, state, reducer, getState, autoUpdateWorkflows });
  if (!should) {
    // NOTE: Don't force the suffix integrity check if has cached result.
    dispatch({ type: CONST.SUFFIXES_CHECK });
    return resolve();
  }
  dispatch({ type: CONST.SUFFIXES_GET });

  const request = { url: `/Banking/api/accounts/suffixes?reload=${!!force}`, autoUpdateWorkflows };
  const success = data => {
    dispatch({ type: CONST.SUFFIXES_GET_SUCCESS, payload: data });
    // NOTE: Force suffix integrity check on data load.
    dispatch({ type: CONST.SUFFIXES_CHECK });
  };
  const failure = error => dispatch({ type: CONST.SUFFIXES_GET_ERROR, payload: error.message });
  const provider = mockProvider || apiProvider;
  const result = provider(request)
    .then(success)
    .catch(failure);

  return resolve(result);
});

export default getSuffixes;
