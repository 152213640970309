import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { log } from '~/common';

import Suggestion from './Suggestion';

const MIN_LENGTH = 6;
const TYPEAHEAD_DELAY = 500; // 25wpm

const logSelected = zip => log({
  category: 7, // Audit
  message: `SmartyStreets: Suggest: Select: ${zip ? 'Zip' : 'NoZip'}`,
  context: 'true',
});

class Street extends Component {
  state = { loading: false, options: [] };

  currentValue = '';

  onSearch = async prefix => {
    const { fetchAddressSuggest, form, field } = this.props;
    if (prefix) {
      await this.updateState({ loading: true });
      const response = await fetchAddressSuggest({ prefix });
      await this.updateState({
        loading: false,
        options: (response || []).map(s => ({ ...s, label: s.street_line })),
      });
    }
    await form.setFieldValue(field.name, this.currentValue);
  };

  onChange = async selectedItems => {
    const { fetchAddress, selectAddress, selectHandler } = this.props;
    const { street_line: street, city, state } = selectedItems[0] || {};

    if (street) {
      const partialAddress = { street, city, state, zip: '', zipPlus4: '' };

      try {
        const resp = await fetchAddress(partialAddress);
        const address = selectAddress(resp) || partialAddress;
        logSelected(!!address.zip);
        selectHandler(address);
      } catch (e) {
        logSelected(false);
        selectHandler(partialAddress);
      }
    }
  };

  onBlur = () => {
    const { field, form } = this.props;
    form.setFieldTouched(field.name);
  };

  onInputChange = async text => {
    const { form, field } = this.props;
    if (text.length < MIN_LENGTH) {
      await form.setFieldValue(field.name, text);
    }
    this.currentValue = text;
  };

  updateState = state => new Promise(resolve => this.setState(state, resolve));

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, autoFocus, field, form, selectHandler, maxLength, ...other } = this.props;
    const { loading, options } = this.state;

    return (
      <div>
        <AsyncTypeahead
          inputProps={{ id, autoComplete: 'off', maxLength }}
          isLoading={loading}
          autoFocus={autoFocus}
          onSearch={this.onSearch}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onInputChange={this.onInputChange}
          delay={TYPEAHEAD_DELAY}
          minLength={MIN_LENGTH}
          selected={field.value ? [field.value] : []}
          options={options}
          placeholder={other.placeholder}
          renderMenuItemChildren={Suggestion} />
      </div>
    );
  }
}

Street.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  selectHandler: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // withSmartyStreets
  fetchAddress: PropTypes.func.isRequired,
  fetchAddressSuggest: PropTypes.func.isRequired,
  selectAddress: PropTypes.func.isRequired,
};

Street.defaultProps = {
  autoFocus: false,
  maxLength: undefined,
};

export default Street;
