import * as CONST from '../constants';
import initialState from './initialState';

const EXPIRATION = 19 * 60 * 1000;

export default (state = initialState, action) => {
  switch (action.type) {
    case CONST.INAUTH_START:
      return {
        ...state,
        data: action.payload,
        loading: true,
        expires: 0,
      };
    case CONST.INAUTH_INBROWSER:
    case CONST.INAUTH_INMOBILE:
      return {
        ...state,
        data: action.payload,
      };
    case CONST.INAUTH_COMPLETE_FAILURE:
    case CONST.INAUTH_COMPLETE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        expires: Date.now() + EXPIRATION,
      };
    case CONST.INAUTH_EXPIRE:
      return {
        ...state,
        expires: 0,
      };
    case CONST.INAUTH_RESET:
      return initialState;
    default:
      return state;
  }
};
