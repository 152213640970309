import { Format } from '@ent/data-tada';

import number from './number';
import string from './string';

export default {
  ...Format,
  number,
  string,
};
