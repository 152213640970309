import React from 'react';

import { Help } from '~/common';

/* eslint-disable react/jsx-one-expression-per-line */
export const helpText = (
  <div>
    For your protection, we need you to complete some extra steps to verify it's you.{' '}
    <a href="https://www.ent.com/multifactor-authentication" target="_blank" rel="noopener noreferrer">
      Learn more
    </a>{' '}
    about how this multifactor authentication process helps protect your account.
  </div>
);

export default () => <Help>{helpText}</Help>;
