import { createSelector } from 'reselect';

import selectAllUserEntitlements from './selectAllUserEntitlements';

const selectUserEntitlement = entitlement => createSelector(
  selectAllUserEntitlements,
  entitlements => entitlements[entitlement]
);

export default selectUserEntitlement;
