export const USER_ENTITLEMENT = {
  ORDER_CHECKS: 1,
  STOP_PAYMENTS: 2,
  DEPOSIT_CHECKS: 3,
  WIRES: 4,
  STATEMENTS: 5,
  SPENDING: 6,
  INITIATE_WIRES: 7,
  MANAGE_WIRE_BENEFICIARIES: 8,
  TRANSFER_TRANSACTION_LIMIT: 9,
  BILL_PAY: 10,
  TRANSFER: 11,
};
