import { WORKFLOW_FLAGS } from '~/constants';
import WORKFLOWS from '~/constants/workflows';

const isLoginWorkflow = wf => WORKFLOWS[wf] && WORKFLOWS[wf].flags === WORKFLOW_FLAGS.LOGIN;

export default {
  key: 'login',
  value: {
    rememberMe: undefined,
    quickBalance: false,
  },
  paths: [
    '/Banking/app/login',
    '/Banking/app/login/password',
    '/Banking/app/login/tether',
    '/Banking/app/account-locked',
    ...[].concat(
      ...Object.keys(WORKFLOWS)
        .filter(isLoginWorkflow)
        .map(k => WORKFLOWS[k].urls)
    ),
  ],
};
