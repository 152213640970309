import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { identity } from '@ent/functional';

import { Storage } from '~/common/Storage';
import { sessionStore } from '~/common/redux/sessionStore';
import sagas from './sagas';

import history from './history';
import createRootReducer from './rootReducer';

export default initialState => {
  let state = initialState;
  try {
    state = initialState || JSON.parse(Storage.session.getItem('store').value.resolved()) || undefined;

    if (window.location.pathname.toLowerCase() === '/banking/app/login/tether') {
      state = undefined;
    }

    const version = typeof VERSION === 'undefined' ? undefined : VERSION; // eslint-disable-line no-undef
    if (state && (!version || !state.env || state.env.version !== version)) {
      state = undefined;
    }
  } catch (e) {
    // eslint-disable-line no-empty
  }

  // NOTE: This map function will strip out unwanted data from the store before persisting it into storage.
  const map = ({ cookie, cardnavDestinations, messages, selection, session, ui, router, ...other }) => ({ ...other });
  const session = sessionStore({ map });
  const sagaMiddleware = createSagaMiddleware();

  const middleware = applyMiddleware(routerMiddleware(history), thunk, session, sagaMiddleware);
  const extension = fn => options => (fn ? fn(options) : identity);
  // eslint-disable-next-line no-underscore-dangle
  const devTools = extension(window.__REDUX_DEVTOOLS_EXTENSION__)({ name: 'Online Banking' });

  const store = createStore(createRootReducer(history), state, compose(middleware, devTools));

  sagas.forEach(saga => {
    if (Array.isArray(saga)) {
      sagaMiddleware.run(...saga);
    } else {
      sagaMiddleware.run(saga);
    }
  });
  return store;
};
