import { createSelector } from 'reselect';

import { format } from '~/common';

import selectCardnavDestinations from './selectCardnavDestinations';

export default createSelector(selectCardnavDestinations, destinations => destinations.map(({ value, ...rest }) => ({
  ...rest,
  value: format.phone(value) || value,
})));
