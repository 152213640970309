import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { observeBootstrapSize } from '@ent/browser';

import {
  createRedirector,
  createWorkflowEnforcer,
  importMessages,
  importWorkflows,
  includeAppInsights,
  includeGoogleTagManager,
  includeChatBot,
} from '~/common';
import { store, history } from '~/store';
import { updateWorkflowsObserver } from '~/store/workflow';
import { setBootstrapSize } from '~/store/ui';
import routes from '~/routes';
import { SELECT_USER_ROLE } from '~/store/user';

observeBootstrapSize(setBootstrapSize(store));
importMessages(store);
importWorkflows(store, history);
updateWorkflowsObserver(store);

function establishDarkMode() {
  const state = store.getState();
  const { BETA_TEST_ACCOUNT } = SELECT_USER_ROLE;
  const isInBeta = BETA_TEST_ACCOUNT(state);
  if (isInBeta) {
    const className = document.body.className.replace(' dark-mode', '');
    document.body.className = `${className} dark-mode`;
  }
}

(function setupDarkModeBeta() {
  establishDarkMode();
  history.listen(() => {
    establishDarkMode();
  });
}());

includeGoogleTagManager({
  containerId: 'GTM-MBFQ2W',
  isProd: window.location.origin === 'https://online.ent.com' || window.location.origin === 'https://m.ent.com',
});
includeAppInsights(store, history, window.APP_INSIGHTS_TOKEN);
includeChatBot(store);

// eslint-disable-next-line no-unused-vars
const onRouteUpdate = (location, action) => {
  const workflowEnforcer = createWorkflowEnforcer(store.getState, () => location.pathname);
  const enforceUrl = workflowEnforcer();
  if (enforceUrl) {
    // NOTE: There is a state inconsistency issue here that can cause an infinite redirects due to
    // the state living in two locations. Better to bypass the redirect with a timeout instead of
    // digging into the internals of why the consistency functions as it does
    window.setTimeout(() => createRedirector(history)(enforceUrl), 0);
  }
};
history.listen(onRouteUpdate);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>{routes}</ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
