import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PageLink from './PageLink';

class PageRow extends Component {
  first = () => {
    const { setPage } = this.props;
    setPage(0);
  };

  // eslint-disable-next-line no-unused-vars
  prev = event => {
    const { curPage, setPage } = this.props;
    setPage(curPage - 1);
  };

  // eslint-disable-next-line no-unused-vars
  next = event => {
    const { curPage, setPage } = this.props;
    setPage(curPage + 1);
  };

  // eslint-disable-next-line no-unused-vars
  last = event => {
    const { maxPage, setPage } = this.props;
    setPage(maxPage);
  };

  render() {
    const { colSpan, curPage, maxPage } = this.props;

    /* eslint-disable react/jsx-one-expression-per-line */
    return (
      <tr>
        <td className="text-center" colSpan={colSpan}>
          <nav>
            <ul className="pagination">
              <PageLink text="First" icon="fast-backward" onClick={this.first} />
              <PageLink text="Previous" icon="step-backward" onClick={this.prev} />
              <li className="disabled">
                <span>
                  <span className="hidden-xs">page&nbsp;</span>
                  {curPage + 1} of {maxPage + 1}
                </span>
              </li>
              <PageLink text="Next" icon="step-forward" onClick={this.next} />
              <PageLink text="Last" icon="fast-forward" onClick={this.last} />
            </ul>
          </nav>
        </td>
      </tr>
    );
  }
}

PageRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  curPage: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
};

PageRow.defaultProps = {};

export default PageRow;
