import React from 'react';
import PropTypes from 'prop-types';

const PageLink = ({ text, icon, onClick }) => (
  <li>
    <a href="#" title={text} onClick={onClick} data-autoid={`${text}Page`}>
      <i className={`fa fa-fw fa-${icon}`} />
    </a>
  </li>
);

PageLink.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

PageLink.defaultProps = {};

export default PageLink;
