import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MESSAGE_TYPE } from '~/constants';
import { CARDNAV_MESSAGE_ID } from '~/constants/cardnav';

import decorator from './decorators';

export default ({ missingDestinationMessage, missingActiveDestinationMessage }) => BaseComponent => {
  class WithCardnav extends Component {
    state = { loading: true };

    componentDidMount() {
      this.load();
    }

    load = async () => {
      const [destinations] = await this.getCard();
      this.setState({ loading: false });

      const { addMessage, selectedCard, redirect } = this.props;

      if (!selectedCard) {
        redirect('/Banking/app/services/cards');
      }

      const hasDestination = destinations.length > 0;
      const hasActiveDestination = destinations.some(({ enabled }) => enabled);

      if (!hasDestination) {
        addMessage({
          type: MESSAGE_TYPE.WARNING,
          autoClose: false,
          id: CARDNAV_MESSAGE_ID.NO_DESTINATION,
          text: missingDestinationMessage,
        });
      }
      if (hasDestination && !hasActiveDestination) {
        addMessage({
          type: MESSAGE_TYPE.WARNING,
          id: CARDNAV_MESSAGE_ID.NO_ACTIVE_DESTINATION,
          autoClose: false,
          text: missingActiveDestinationMessage,
        });
      }
    };

    getCard = actionOptions => {
      const { getCards, getUser, getCardnav, getCardnavDestinations } = this.props;
      // prettier-ignore
      return Promise.all([
        getCardnavDestinations(actionOptions),
        getCards(actionOptions),
        getUser(actionOptions),
        getCardnav(actionOptions),
      ]);
    };

    getCardnav = actionOptions => {
      const { getCardnav, getCardnavDestinations } = this.props;
      // prettier-ignore
      return Promise.all([
        getCardnav(actionOptions),
        getCardnavDestinations(actionOptions),
      ]);
    };

    render() {
      const { loading } = this.state;
      const { getCardnav } = this;
      return <BaseComponent {...this.props} loading={loading} getCardnav={getCardnav} />;
    }
  }

  WithCardnav.propTypes = {
    // connect
    getCardnav: PropTypes.func.isRequired,
    getCardnavDestinations: PropTypes.func.isRequired,
    getCards: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    hasActiveDestination: PropTypes.bool.isRequired,
    hasDestination: PropTypes.bool.isRequired,
    isCardnavAvailable: PropTypes.bool.isRequired,
    selectedCard: PropTypes.object.isRequired,
    // withApi
    api: PropTypes.func.isRequired,
    // withRouter
    redirect: PropTypes.func.isRequired,
    // withMessages
    addMessage: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
  };

  WithCardnav.defaultProps = {};

  WithCardnav.displayName = `WithCardnav(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  return decorator(WithCardnav);
};
