import { MFA_METHODS } from '~/constants';

export default {
  key: 'mfa',
  value: {
    loading: true,
    phones: [],
    emails: [],
    methods: [],
    lastMethod: {
      type: MFA_METHODS.NONE,
      details: '',
    },
    mode: '',
    ids: {
      [MFA_METHODS.SMS]: '',
      [MFA_METHODS.CALL]: '',
      [MFA_METHODS.EMAIL]: '',
    },
  },
  paths: [
    '/Banking/app/mfa',
    '/Banking/app/mfa/sms',
    '/Banking/app/mfa/push',
    '/Banking/app/mfa/call',
    '/Banking/app/mfa/email',
    '/Banking/app/mfa/fail',
    '/Banking/app/mfa/success',
  ],
};
