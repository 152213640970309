import { createSelector } from 'reselect';

import selectEnv from './selectEnv';

const selectIsMemoPost = createSelector(
  selectEnv,
  env => env.memoPost === true
);

export default selectIsMemoPost;
