import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Context from './ProgressContext';

class Progress extends Component {
  state = { percent: undefined };

  set = ({ percent } = {}) => {
    const value = Number.isNaN(parseFloat(percent)) ? undefined : Math.max(0, Math.min(100, percent));
    this.setState({ percent: value === 100 ? undefined : value });
  };

  render() {
    const { children } = this.props;
    const { percent } = this.state;
    return <Context.Provider value={{ percent, set: this.set }}>{children}</Context.Provider>;
  }
}

Progress.propTypes = { children: PropTypes.any.isRequired };

Progress.defaultProps = {};

export default Progress;
