import defaultValetState from '~/common/redux/valet/defaultValetState';

const version = typeof VERSION === 'undefined' ? undefined : VERSION; // eslint-disable-line no-undef

const envInitialState = Object.assign({}, defaultValetState, {
  version,
  mode: process.env.NODE_ENV,
  data: {
    memoPost: null,
    serverMode: null,
    forgotUserNameOrPasswordUrl: null,
  },
});

export default envInitialState;
