import { createSelector } from 'reselect';

import selectUser from './selectUser';

const selectAllUserRoles = createSelector(
  selectUser,
  user => user.roles || []
);

export default selectAllUserRoles;
