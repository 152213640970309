import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ent/fields';

import { withRouter } from '~/hocs';

const isProd = document.location.hostname.toLowerCase().endsWith('ent.com');

class Links extends Component {
  locationsClick = () => {
    const { redirect } = this.props;
    redirect('/Banking/app/locations');
  };

  contactUsClick = () => {
    const { redirect } = this.props;
    redirect('/Banking/app/contact');
  };

  wwwClick = () => {
    const { redirect } = this.props;
    // TODO: update to new www test site
    redirect(isProd ? 'https://www.ent.com' : 'https://ent-uat.banno.com');
  };

  render() {
    return (
      <div className="Links row clearfix">
        <div className="col-xs-12 col-sm-4">
          <Button kind="default" onClick={this.locationsClick}>
            <span>
              <i className="fa fa-fw fa-lg fa-map-marker" aria-hidden="true" />
              Locations
            </span>
            <i className="fa fa-fw fa-lg fa-caret-right" aria-hidden="true" />
          </Button>
        </div>
        <div className="col-xs-12 col-sm-4">
          <Button kind="default" onClick={this.contactUsClick}>
            <span>
              <i className="fa fa-fw fa-lg fa-envelope" aria-hidden="true" />
              Contact Us
            </span>
            <i className="fa fa-fw fa-lg fa-caret-right" aria-hidden="true" />
          </Button>
        </div>
        <div className="col-xs-12 col-sm-4">
          <Button kind="default" onClick={this.wwwClick}>
            <span>
              <i className="fa fa-fw fa-lg fa-home" aria-hidden="true" />
              Ent.com
            </span>
            <i className="fa fa-fw fa-lg fa-caret-right" aria-hidden="true" />
          </Button>
        </div>
      </div>
    );
  }
}

Links.propTypes = {
  // withRouter
  redirect: PropTypes.func.isRequired,
};

Links.defaultProps = {};

export default withRouter()(Links);
