import { INMOBILE_TIMEOUT } from '~/store/inauth/constants';
import inAuth from '../inAuth';

import defaultGetPayload from './getPayload';
import defaultSubmitLogs from './submitLogs';

export default ({
  name = 'InMobile',
  timeout = INMOBILE_TIMEOUT,
  getPayload = defaultGetPayload,
  submitLogs = defaultSubmitLogs,
} = {}) => inAuth({ name, timeout, getPayload, submitLogs });
