import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Module } from '@ent/component';

import { log } from '~/common';

import ReloadButton from './ReloadButton';
import Suffixes from './Suffixes';
import './styles.scss';

const logit = () => log({
  category: 7, // Audit
  message: 'Quick Balance: View',
  context: 'true',
  details: window.location.pathname,
});

class QuickBalanceList extends Component {
  componentDidMount() {
    logit();
  }

  reload = () => {
    const { reload } = this.props;
    logit();
    reload();
  };

  render() {
    const { suffixes, loadSuffix } = this.props;
    return (
      <Module className="default QuickBalanceList no-margin">
        <Module.Body>
          <div>
            <div className="row">
              <div className="col-xs-8">
                <ReloadButton loading={!suffixes} reload={this.reload} />
              </div>
              <div className="col-xs-4 text-right text-muted small">Available</div>
            </div>
            {!suffixes && 'Loading...'}
            {suffixes && !suffixes.length && 'There are no accounts configured to display.'}
            {suffixes && !!suffixes.length && <Suffixes suffixes={suffixes} loadSuffix={loadSuffix} />}
          </div>
        </Module.Body>
      </Module>
    );
  }
}

QuickBalanceList.propTypes = {
  suffixes: PropTypes.array,
  loadSuffix: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
};

QuickBalanceList.defaultProps = { suffixes: undefined };

export default QuickBalanceList;
