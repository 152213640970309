import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { device } from '@ent/browser';

import { format } from '~/common/format';

import { FieldFormGroup } from '../FieldFormGroup';

// TODO: Update currency to normalize using this function
const normalizeFloat = value => {
  const match = (value || '').match(/\d*\.?\d*/);
  return match ? match[0] : '';
};

const formatCurrency = (value, placeholder) => {
  const currency = parseFloat(value);
  if (Number.isNaN(currency) && !placeholder) {
    return '0.00';
  }
  if (Number.isNaN(currency) && placeholder) {
    return '';
  }
  return format.currency(value).replace(/[^\d.]/g, '');
};

const allowedCharacterRegEx = /\d|\./;

class Currency extends Component {
  state = { isFocused: false };

  ref = createRef();

  get value() {
    const { field, placeholder } = this.props;
    const { isFocused } = this.state;
    const { current } = this.ref;

    return isFocused ? current.value : formatCurrency(field.value, placeholder);
  }

  blur = async event => {
    const { field, form, placeholder } = this.props;
    const { current } = this.ref;
    const newValue = formatCurrency(current.value, placeholder);

    field.onBlur(event);
    if (current.value !== newValue) {
      await form.setFieldValue(field.name, parseFloat(newValue));
    }

    this.setState({ isFocused: false });
  };

  focus = () => {
    const { current } = this.ref;

    if (current.value === '0.00') {
      current.value = '';
    }

    this.setState({ isFocused: true });
  };

  keyPress = event => {
    const { key } = event;
    // HACK: IE sends over one of the "." as "Delete"
    if (!(device.isIE && key === 'Delete') && !allowedCharacterRegEx.test(key)) {
      event.preventDefault();
    }
  };

  render() {
    const { id, left, right, mask, form, field, normalize, ...other } = this.props;
    const hasAddon = !!left || !!right;

    // HACK: Firefox number inputs do not allow event.target.value modifications
    // HACK: IE11 number inputs report errors for floating point numbers
    const type = device.isIE || device.isFirefox ? 'text' : 'number';

    const content = (
      <input
        ref={this.ref}
        id={id}
        type={type}
        className="form-control"
        inputMode="decimal"
        {...field}
        {...other}
        onBlur={this.blur}
        onFocus={this.focus}
        onKeyPress={this.keyPress}
        value={this.value} />
    );

    return !hasAddon ? (
      content
    ) : (
      <div className="input-group">
        {left && <div className="input-group-addon">{left}</div>}
        {content}
        {right && <div className="input-group-addon">{right}</div>}
      </div>
    );
  }
}

Currency.propTypes = {
  id: PropTypes.string.isRequired,
  left: PropTypes.node,
  right: PropTypes.node,
  mask: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  normalize: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
};

Currency.defaultProps = {
  left: '$',
  right: undefined,
  mask: undefined,
  placeholder: undefined,
  normalize: normalizeFloat,
  step: 0.01,
};

const CurrencyField = ({ name, ...other }) => <FieldFormGroup name={name} component={Currency} {...other} />;

CurrencyField.propTypes = { name: PropTypes.string.isRequired };

CurrencyField.defaultProps = {};

export default CurrencyField;
