import { createSelector } from 'reselect';

export default (...args) => {
  const selector = createSelector(...args);

  let cache;
  const selectorWithCache = (...state) => {
    cache = selector(...state);
    return cache;
  };

  Object.defineProperty(selectorWithCache, 'current', {
    get() {
      return cache;
    },
  });

  return selectorWithCache;
};
