import { ACTIONS, TEST_STATUS } from '../constants';

const initialState = {};

const createTest = (state, { payload }) => {
  const { name, goal = false, status = TEST_STATUS.RUNNING, variant = null, subVariant = null } = payload || {};
  return {
    ...state,
    [name]: {
      status,
      goal,
      variant,
      subVariant,
    },
  };
};

const setKey = key => (state, { payload }) => {
  const { name } = payload || {};
  return {
    ...state,
    [name]: {
      ...state[name],
      [key]: (payload || {})[key],
    },
  };
};

const testReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_TEST:
      return createTest(state, action);
    case ACTIONS.SET_VARIANT:
      return setKey('variant')(state, action);
    case ACTIONS.SET_SUB_VARIANT:
      return setKey('subVariant')(state, action);
    case ACTIONS.SET_STATUS:
      return setKey('status')(state, action);
    case ACTIONS.SET_GOAL:
      return setKey('goal')(state, action);
    case ACTIONS.COMPLETE_TEST:
      // eslint-disable-next-line no-case-declarations
      const { name, goal = false } = (action || {}).payload || {};
      return {
        ...state,
        [name]: {
          ...state[name],
          goal,
          status: TEST_STATUS.STOPPED,
        },
      };
    default:
      return state;
  }
};

export default testReducer;
