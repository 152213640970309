import { entMobile as defaultEntMobile } from '~/common/entMobile';
import { log as defaultLog } from '~/common/log';

import * as CONST from '../../constants';
import { selectShouldInAuthStart } from '../../selectors';

import { inMobile as defaultInMobile } from './inMobile';
import { inBrowser as defaultInBrowser } from './inBrowser';

export default ({
  shouldStart = selectShouldInAuthStart,
  entMobile = undefined,
  log = defaultLog,
  inMobile = defaultInMobile,
  inBrowser = defaultInBrowser,
} = {}) => async (dispatch, getState) => {
  try {
    entMobile = entMobile || defaultEntMobile; // eslint-disable-line no-param-reassign

    const should = shouldStart(getState());
    if (!should) return;

    await dispatch({ type: CONST.INAUTH_START });

    let complete;

    if (entMobile.config.dispatchable) {
      // InMobile
      await dispatch({ type: CONST.INAUTH_INMOBILE });
      complete = await inMobile();
      await dispatch({ type: CONST.INAUTH_INMOBILE, payload: complete });
    } else {
      // InBrowser
      await dispatch({ type: CONST.INAUTH_INBROWSER });
      complete = await inBrowser();
      await dispatch({ type: CONST.INAUTH_INBROWSER, payload: complete });
    }

    const type = complete ? CONST.INAUTH_COMPLETE_SUCCESS : CONST.INAUTH_COMPLETE_FAILURE;
    await dispatch({ type });
  } catch (error) {
    log({
      category: 7, // audit
      severity: 300, // warning
      message: 'InAuth: failed',
      context: false,
      details: error.stack || error.message,
    });
    await dispatch({ type: CONST.INAUTH_COMPLETE_FAILURE });
  }
};
