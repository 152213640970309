import { createSelector } from 'reselect';

export default createSelector(
  state => state.config,
  config => ({ type, payload } = {}) => new Promise((resolve, reject) => {
    if (!config.device) {
      return resolve();
    }

    if (config.dispatchable) {
      if (config.ios) {
        window.webkit.messageHandlers.dispatch.postMessage({ type, payload });
        return resolve();
      }

      if (config.android) {
        // eslint-disable-next-line no-undef
        (window.android || android).dispatch(JSON.stringify({ type, payload }));
        return resolve();
      }
    }

    return reject(Error(`EntMobile ${config.device} ${config.version} is not dispatchable.`));
  })
);
