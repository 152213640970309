import { debounce } from '@ent/functional';
import { apiProvider } from '@ent/browser';

import { addMessage } from '../../messages';
import { ACTIONS } from '../constants';
import { selectUserProfile } from '../selectors';
import { parse, serialize } from '../helpers';

const callApi = debounce(async (dispatch, getState, data) => {
  try {
    const response = await apiProvider({
      method: 'PUT',
      url: '/Banking/api/userprofile/Ent',
      data: serialize(data),
    });
    dispatch({ type: ACTIONS.SET_USER_PROFILE, payload: parse(response) });
  } catch (e) {
    addMessage({ text: e.message })(dispatch, getState);
  }
}, 200);

export default changes => (dispatch, getState) => {
  const current = selectUserProfile(getState());
  const patched = { ...current, ...changes };
  dispatch({ type: ACTIONS.SET_USER_PROFILE, payload: patched });
  callApi(dispatch, getState, patched);
};
