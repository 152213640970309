import React from 'react';
import PropTypes from 'prop-types';

import { withProgress } from '~/hocs/withProgress';

const gradient = percent =>
  // eslint-disable-next-line implicit-arrow-linebreak
  `linear-gradient(to right, #b30f1a, #b30f1a ${percent - 2}%, #dfe3e8 ${percent + 2}%, #dfe3e8 100%) 1`;

const WrapperContent = ({ progress, children }) => {
  // prettier-ignore
  const progressStyle = progress.percent === undefined ? undefined : {
    borderWidth: '0 0 7px 0',
    borderStyle: 'solid',
    borderImage: gradient(progress.percent),
  };

  return (
    <div className="wrapper-content" style={progressStyle}>
      {children}
    </div>
  );
};

WrapperContent.propTypes = {
  children: PropTypes.any.isRequired,
  // withProgress
  progress: PropTypes.object.isRequired,
};

WrapperContent.defaultProps = {};

export default withProgress()(WrapperContent);
