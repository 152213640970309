import { ACTION } from './constants';

export const setPersonalInfo = payload => ({
  type: ACTION.SET_PERSONAL_INFO,
  payload,
});

export const setJointInfo = payload => ({
  type: ACTION.SET_JOINT_INFO,
  payload,
});

export const editJointInfo = payload => ({
  type: ACTION.EDIT_JOINT_INFO,
  payload,
});

export const addJoint = index => ({ type: ACTION.ADD_JOINT, payload: index });

export const setAccountSetupInfo = payload => ({ type: ACTION.SET_ACCOUNT_SETUP_INFO, payload });
