import { apiProvider } from '@ent/browser';

import { selectRequestTokens, selectCsrfToken } from '~/store/user';

import { GET_RETAIL_TRANSFERS } from '../constants';

const getRetailTransfers = ({ autoPublishMessages } = {}) => async (dispatch, getState) => {
  const state = getState();
  const csrfToken = selectCsrfToken(state);
  const requestTokens = selectRequestTokens(state);

  const retailTransfers = await apiProvider({
    method: 'GET',
    url: '/Banking/api/transfer/retail/scheduled?maxCount=50',
    csrfToken,
    requestTokens,
    autoPublishMessages,
  });

  dispatch({ type: GET_RETAIL_TRANSFERS, payload: retailTransfers });
};

export default getRetailTransfers;
