import { createSelector } from 'reselect';

import selectUI from './selectUI';

const selectBootstrapSize = createSelector(
  selectUI,
  ui => ui.bootstrapSize
);

export default selectBootstrapSize;
