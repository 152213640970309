import * as CONST from '../constants';

import removeCookie from './removeCookie';

export default ({ key, value, mockCookie }) => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => Promise.resolve(
  dispatch(
    !value
      ? removeCookie({ key, mockCookie })
      : {
        type: CONST.COOKIE_SET,
        payload: {
          key,
          value,
          mockCookie,
        },
      }
  )
);
