import shortid from 'shortid';
import { scroll } from '@ent/browser';

import { MESSAGE_TYPE } from '~/constants';
import * as CONST from '~/store/messages/constants';
import { selectIsProduction } from '~/store/env';

import clearMessages from './clearMessages';

const addMessage = ({
  text,
  type = MESSAGE_TYPE.ERROR,
  autoClose = true,
  autoCloseDuration = 10000,
  owner = null,
  autoScroll = true,
  id = shortid.generate(),
  production,
}) => (
  dispatch,
  getState // eslint-disable-line no-unused-vars
) => new Promise(resolve => {
  if (production === undefined || production === selectIsProduction(getState())) {
    dispatch({
      type: CONST.MESSAGES_ADD,
      payload: { id, owner, type, text },
    });

    if (autoClose) {
      setTimeout(() => {
        dispatch(clearMessages({ id }));
      }, autoCloseDuration);
    }

    if (autoScroll) {
      // NOTE: initiate scroll after next paint
      requestAnimationFrame(() => scroll('.message-queue'));
    }
  }

  return resolve();
});

export default addMessage;
