import selectEntMobile from './selectEntMobile';

import config from './config';
import dispatch from './dispatch';
import observable from './observable';

export default selectEntMobile({
  development: process.env.NODE_ENV === 'development',
  config,
  dispatch,
  observable,
});
