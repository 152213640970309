import React from 'react';

const BiometricPass = () => (
  <div className="form-control" disabled>
    <i className="fa fa-fw fa-spin fa-spinner" />
  </div>
);

BiometricPass.propTypes = {};

BiometricPass.defaultProps = {};

export default BiometricPass;
