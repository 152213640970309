import React from 'react';

/* eslint-disable react/jsx-one-expression-per-line */
const QuickBalanceLink = () => (
  <span>
    Quick Balance{' '}
    <a className="small" href="https://www.ent.com/quick-balance" target="_blank" rel="noopener noreferrer">
      (learn more)
    </a>
  </span>
);

QuickBalanceLink.propTypes = {};

QuickBalanceLink.defaultProps = {};

export default QuickBalanceLink;
