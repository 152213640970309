import React from 'react';
import Image from 'react-image';
import { identity } from '@ent/functional';
import { nextAnimationFrame, clearStackFrame } from '@ent/browser';

import logoImage from './ent.png';
import Context from './PrintContext';
import './styles.scss';

const print = async promise => {
  await promise;
  window.print();
};

const printHOC = ({ autoPrint } = { autoPrint: true }) => Component => {
  const printImages = new Map();

  const PrintImage = ({ id, container: ImageContainer = identity, ...props }) => {
    if (!printImages.has(id)) {
      let resolve = null;
      let reject = null;
      const promise = new Promise((r, rj) => {
        resolve = r;
        reject = rj;
      });
      printImages.set(id, { promise, resolve, reject });
    }

    const container = children => {
      if (children && children.props && children.props.src) {
        // Give react a chance to flush to dom before calling print
        nextAnimationFrame()
          .then(clearStackFrame)
          .then(nextAnimationFrame)
          .then(clearStackFrame)
          .then(printImages.get(id).resolve);
      }
      return typeof ImageContainer === 'function' ? (
        ImageContainer(children)
      ) : (
        <ImageContainer>{children}</ImageContainer>
      );
    };

    const unloader = () => {
      if (printImages.has(id)) {
        printImages.get(id).reject();
      }
      return <span />;
    };

    return <Image {...props} unloader={unloader} container={container} />;
  };

  class PrintComponent extends React.Component {
    print = otherPromise => {
      const allPromises = Promise.all([otherPromise, ...[...printImages.values()].map(({ promise }) => promise)])
        .then(nextAnimationFrame)
        .then(clearStackFrame);
      return print(allPromises);
    };

    render() {
      const container = children => {
        if (children && children.props && children.props.src && autoPrint) {
          nextAnimationFrame()
            .then(clearStackFrame)
            .then(() => this.print());
        }
        return children;
      };
      return (
        <Context.Provider value={{ Image: PrintImage }}>
          <table className="Print">
            <thead>
              <tr>
                <th className="clearfix">
                  <div className="contact pull-right">
                    <div className="strong">Mail:</div>
                    <div>PO Box 15819</div>
                    <div>Colorado Springs, CO 80935-5819</div>
                  </div>
                  <div className="contact pull-right">
                    <div className="strong">Phone:</div>
                    <div>+1 (800) 525-9623</div>
                  </div>
                  <div className="logo">
                    <PrintImage id="logo" container={container} src={logoImage} width="130" alt="Ent Logo" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Component {...this.props} print={this.print} PrintImage={PrintImage} />
                </td>
              </tr>
            </tbody>
          </table>
        </Context.Provider>
      );
    }
  }

  return PrintComponent;
};

export default printHOC;
