import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useFormikContext, Field } from 'formik';

import './styles.scss';

// const API_KEY = '6Lcv1HYUAAAAAPQpPBUz4gUnY12Tb1QcBaKPjmdx';
const API_KEY = '6LcEXDshAAAAAIoon6-T7ApN4KmdGGEWpQLenyb9';

const hidden = { display: 'none' };

export const onRecaptchaVerify = (token, form, onRecaptchaSuccess) => {
  form.setFieldValue('recaptcha', token);
    if (onRecaptchaSuccess) {
      onRecaptchaSuccess(token);
    }
};

const RecaptchaField = ({ onRecaptchaSuccess }) => {
  const form = useFormikContext();
  const [resetCount, setResetCount] = useState(() => 0);
  const onVerify = useCallback(token => {
    onRecaptchaVerify(token, form, onRecaptchaSuccess);
  }, []);
  useEffect(() => {
    if (!form.values.recaptcha || form.values.recaptcha.length === 0) {
      setResetCount(resetCount + 1);
    }
  }, [form.values]);
  return (
    <GoogleReCaptchaProvider reCaptchaKey={API_KEY}>
      <Field name="recaptcha" style={hidden} value={form.values.recaptcha} />
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={resetCount} />
    </GoogleReCaptchaProvider>
  );
};

RecaptchaField.propTypes = { onRecaptchaSuccess: PropTypes.func };

RecaptchaField.defaultProps = { onRecaptchaSuccess: undefined };

export default RecaptchaField;
